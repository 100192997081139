import React from 'react';
import './FormInput.scss';

interface PropTypes {
  label?: string;
  placeholder?: string;
  id?: string;
  type?: string;
  value?: string;
  isDate?: boolean;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FormInput = React.forwardRef<HTMLInputElement, PropTypes>(
  (props, ref) => {
    const { label, placeholder, type, isDate, error, ...rest } = props;
    const inputType = isDate ? 'date' : type || 'text';
    return (
      <div className="form-control">
        <label className="form-label" htmlFor="form-input">
          {label}
        </label>

        <input
          className="form-input"
          ref={ref}
          id={inputType}
          type={inputType}
          placeholder={placeholder}
          {...rest}
        />

        {error && <div className="error-message">{error}</div>}
      </div>
    );
  },
);

FormInput.displayName = 'form-input';

export default FormInput;
