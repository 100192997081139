export const productOptions = [
  'All Products',
  'Chef',
  'Chef Assistant',
  'Waiter',
  'Barista',
  'Receptionist',
  'Private Flight Attendant',
];

export const tagList = [
  {
    id: 1,
    name: 'Chinese',
  },
  {
    id: 2,
    name: 'Indian',
  },
  {
    id: 3,
    name: 'Turkish',
  },
  {
    id: 4,
    name: 'Egyptian',
  },
  {
    id: 5,
    name: 'Lebanese',
  },
  {
    id: 6,
    name: 'Syrian',
  },
  {
    id: 7,
    name: 'Yemeni',
  },
  {
    id: 8,
    name: 'Saudi',
  },
  {
    id: 9,
    name: 'Asian',
  },
  {
    id: 10,
    name: 'Moroccan',
  },
  {
    id: 11,
    name: 'Grill',
  },
  {
    id: 12,
    name: 'Smoking',
  },
  {
    id: 13,
    name: 'Salad',
  },
  {
    id: 14,
    name: 'Pastry',
  },
  {
    id: 15,
    name: 'Bakery',
  },
  {
    id: 16,
    name: 'Hot',
  },
  {
    id: 17,
    name: 'Cold',
  },
];
