import React, { useCallback } from 'react';
import './NextOrderButton.scss';
import arrowRight from '../../../../../assets/icons/orderPage/arrow-right.svg';
import classNames from 'classnames';
import { CircularProgress } from '@mui/material';

interface PropTypes {
  label: string;
  isDisabled?: boolean;
  onClick?: () => void;
  type?: 'button' | 'submit' | undefined;
}

const NextOrderButton = (props: PropTypes) => {
  const { label, onClick, type } = props;
  const handleClick = useCallback(
    () => !props.isDisabled && onClick && onClick(),
    [props.isDisabled, onClick],
  );
  return (
    <button
      className={classNames('next-order-button-container')}
      onClick={handleClick}
      type={type || 'button'}>
      <div
        className={classNames(
          'next-order-button',
          props.isDisabled && 'disabled',
        )}>
        {label}

        {props.isDisabled ? (
          <CircularProgress
            className="next-order-button-icon loader"
            size={'2rem'}
          />
        ) : (
          <img
            className="next-order-button-icon"
            src={arrowRight}
            alt="arrow"
          />
        )}
      </div>
    </button>
  );
};

export default NextOrderButton;
