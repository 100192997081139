import React from 'react';
import './BasicOrderButton.scss';
import classNames from 'classnames';

interface PropTypes {
  label: string;
  title?: string;
  isFilled?: boolean;
  isPink?: boolean;
  type?: 'button' | 'submit';
  onClick?: () => void;
}

const BasicOrderButton = (props: PropTypes) => {
  const { label, title, isFilled, isPink, type, onClick } = props;
  return (
    <div className="basic-order-button-container">
      {title && <div className="basic-order-button-title">{title}</div>}
      <button
        className={classNames(
          'basic-order-button',
          { filled: isFilled },
          { pink: isPink },
        )}
        onClick={onClick}
        type={type}>
        {label}
      </button>
    </div>
  );
};

export default BasicOrderButton;
