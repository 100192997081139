import React, { useMemo, useState } from 'react';
import './ContractDetails.scss';
import contract from '../../../assets/icons/orderPage/contract.svg';
import money from '../../../assets/icons/orderPage/money-gray.svg';
import house from '../../../assets/icons/orderPage/house.svg';
import user from '../../../assets/icons/orderPage/user-blue.svg';
import OrderSelect from '../../../shared/components/Select/OrderSelect/OrderSelect';
import OrderInput from '../../../shared/components/Input/OrderInput/OrderInput';
import OrderRadioInput from '../../../shared/components/Input/OrderRadioInput/OrderRadioInput';
import PercentageControl from '../../../shared/components/Input/PercentageControl/PercentageControl';
import BasicOrderButton from '../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IOrderContract } from '../../../entities/Order/order.models';

interface PropTypes {
  handleCloseTab: () => void;
  handleData: (
    type: string,
    salary: number,
    housing: boolean,
    females: number,
    locals: number,
  ) => void;
  orderListContract?: IOrderContract;
  ordInv?: number;
  femalesPerc?: number;
  localsPerc?: number;
  prevContractData?: IOrderContract;
}

const yupSchema = yup.object({
  contractType: yup.string().required('Select a contract type'),
  maxSalary: yup
    .number()
    .typeError('Number required')
    .required('Specify the maximum salary for the contract'),
  housing: yup.boolean().required('Indicate whether housing is provided'),
});

type YupSchemaType = yup.InferType<typeof yupSchema>;

const ContractDetails = (props: PropTypes) => {
  const {
    handleCloseTab,
    handleData,
    orderListContract,
    femalesPerc: femalesPercDefault,
    localsPerc: localsPercDefault,
    ordInv,
    prevContractData,
  } = props;
  const { contractType: contractTypeOrderList, maxSalary } =
    orderListContract || {};

  const {
    contractType: prevContractType,
    femalesPercentage: prevFemales,
    housing: prevHousing,
    localsPercentage: prevLocals,
    maxSalary: prevMaxSalary,
  } = prevContractData || {};

  const maxSalaryOrderList = maxSalary ? Number(maxSalary) : undefined;

  const [femalesPerc, setFemalesPerc] = useState(
    prevFemales || femalesPercDefault || 50,
  );
  const [localsPerc, setLocalsPerc] = useState(
    prevLocals || localsPercDefault || 80,
  );

  const [contractType, setContractType] = useState(
    prevContractType || contractTypeOrderList || '',
  );
  const [salaryMax, setSalaryMax] = useState(
    Number(prevMaxSalary || maxSalaryOrderList) || undefined,
  );
  const [housing, setIsHousing] = useState(prevHousing || false);

  const handleIncrement =
    (
      setter: React.Dispatch<React.SetStateAction<number>>,
      maxPercent: number,
    ) =>
    () => {
      setter((prev: number) => (prev < maxPercent ? prev + 2 : prev));
    };

  const handleDecrement =
    (
      setter: React.Dispatch<React.SetStateAction<number>>,
      minPercent: number,
    ) =>
    () => {
      setter((prev: number) => (prev > minPercent ? prev - 2 : prev));
    };

  const onSubmitHandler: SubmitHandler<YupSchemaType> = async () => {
    try {
      handleData(
        contractType,
        Number(salaryMax),
        housing,
        femalesPerc,
        localsPerc,
      );
      handleCloseTab && handleCloseTab();
    } catch (e) {
      console.log(e);
    }
  };

  const handleRadioChange = (value: string) => {
    setIsHousing(value === 'Yes' ? true : false);
    // TODO: fix
    setValue('housing', value === 'Yes' ? true : false);
  };

  const femalesMinPerc = useMemo(() => {
    return ordInv
      ? Math.ceil(((femalesPercDefault || femalesPerc) / ordInv) * 100)
      : 1;
  }, [femalesPercDefault, femalesPerc, ordInv]);

  const localsMinPerc = useMemo(() => {
    return ordInv
      ? Math.ceil(((localsPercDefault || localsPerc) / ordInv) * 100)
      : 1;
  }, [localsPercDefault, localsPerc, ordInv]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<YupSchemaType>({
    resolver: yupResolver(yupSchema),
    defaultValues: {
      contractType,
      maxSalary: salaryMax,
      housing,
    },
  });

  return (
    <div className="contract-details-container">
      <form
        className="contract-details"
        onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="contract-details-heading">
          <img src={contract} alt="icon" />
          <div className="contract-title">Contract</div>
        </div>
        <OrderSelect
          icon={contract}
          options={['Permanent', 'Temporary']}
          placeholder="Contract type"
          handleCollectValue={(value) => {
            setValue('contractType', value);
            setContractType(value);
          }}
          error={errors.contractType?.message}
          {...register('contractType')}
        />
        <OrderInput
          innerIcon={money}
          placeholder="Salary Maximum (SAR)"
          handleCollectValue={(value) => {
            const salaryValue = Number(value);
            if (!isNaN(salaryValue)) {
              setValue('maxSalary', salaryValue);
              setSalaryMax(salaryValue);
            }
          }}
          error={errors.maxSalary?.message}
          {...register('maxSalary')}
        />
        <div className="contract-details-note">
          Crew will work to bring the best workers at the lowest cost
        </div>
        <div className="contract-housing">
          <div className="housing-label">
            <img src={house} alt="House" />
            Housing
          </div>
          <div className="housing-options">
            <OrderRadioInput
              value="Yes"
              name="housing"
              handleCollectValue={handleRadioChange}
              isChecked={housing}
            />
            <OrderRadioInput
              value="No"
              name="housing"
              handleCollectValue={handleRadioChange}
              isChecked={housing}
            />
          </div>
        </div>
        {errors.housing?.message && (
          <div className="error-message">{errors.housing?.message}</div>
        )}
        <div className="hr"></div>
        <div className="contract-diversity">
          <div className="diversity-title">
            <img src={user} alt="user" />
            Diversity target
          </div>
          <PercentageControl
            label="Females"
            value={femalesPerc}
            onIncrement={handleIncrement(
              setFemalesPerc,
              femalesPercDefault || 100,
            )}
            onDecrement={handleDecrement(setFemalesPerc, femalesMinPerc)}
          />
          <PercentageControl
            label="Locals"
            value={localsPerc}
            onIncrement={handleIncrement(
              setLocalsPerc,
              localsPercDefault || 100,
            )}
            onDecrement={handleDecrement(setLocalsPerc, localsMinPerc)}
          />
          <div className="hr"></div>
          <BasicOrderButton label="Confirm" isFilled={true} />
        </div>
      </form>
      <div className="hr"></div>
    </div>
  );
};

export default ContractDetails;
