import React from 'react';
import SummaryOrderCard from '../../../../entities/Order/SummaryOrderCard/SummaryOrderCard';
import { IOrderWithSKUResponse } from '../../../../entities/Order/order.models';
import { qualityIdToQualityMap } from '../../../../widgets/Order/SelectProductCards/productCards';

export type OrderCardsType = {
  skuRequests: IOrderWithSKUResponse[];
};

export const OrderCards = ({ skuRequests }: OrderCardsType) => {
  return (
    <div className="summary-order-cards">
      {skuRequests?.map((skuReq) => {
        const currentSku = skuReq.sku;
        const qualityGrade = qualityIdToQualityMap[currentSku!.qualityId!];
        const currentSkuReqContact = skuReq.contact;
        const contactName = `${currentSkuReqContact.firstName} ${currentSkuReqContact.lastName}`;
        return (
          <SummaryOrderCard
            id={skuReq.id || 0}
            key={skuReq.id}
            position={currentSku!.productName}
            grade={qualityGrade}
            price={currentSku!.pricePerUnit! * currentSku!.orderableInventory}
            priceSingle={currentSku?.pricePerUnit}
            finalQuantity={skuReq.crewsNumber}
            contract={skuReq.contract}
            schedules={skuReq.schedules}
            contact={contactName}
          />
        );
      })}
    </div>
  );
};
