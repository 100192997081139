import React, { useState } from 'react';
import './ContactCards.scss';
import ContactCard from '../../entities/Customer/ContactCard/ContactCard';
import { useGetContactsQuery } from '../../entities/Contact/api/contactApi';
import { IContact } from '../../entities/Contact/contact.models';
import { Pagination } from '@mui/material';

const TAKE_CONTACTS = 10;

const ContactCards = () => {
  const [page, setPage] = useState(1);
  const {
    data: contactsData,
    isLoading,
    isSuccess,
  } = useGetContactsQuery({
    relations: ['branch.organization'],
    take: TAKE_CONTACTS,
    page,
  });

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) =>
    setPage(page);

  return (
    <div className="contact-cards">
      {isLoading && <div>Loading</div>}
      {isSuccess && (
        <>
          {contactsData.data.map((card: IContact, index: number) => (
            <ContactCard
              key={card.id}
              className={index % 2 === 0 ? 'blue-card' : 'white-card'}
              {...card}
            />
          ))}
          <Pagination
            hidden={contactsData.meta.pageCount <= 1}
            page={page}
            count={contactsData.meta.pageCount}
            onChange={handlePageChange}
          />
        </>
      )}
    </div>
  );
};

export default ContactCards;
