import React, { useCallback, useMemo, useState } from 'react';
import BlockTitle from '../../../../shared/components/Title/BlockTitle/BlockTitle';
import Graph from '../../../../shared/components/Charts/Graph/Graph';
import './CustomerGraphContainer.scss';
import { useGetOrderChartRatingQuery } from '../../../Order/api/orderApi';
import GraphFilters from '../../../../features/Filters/GraphFilters/GraphFilters';
import moment from 'moment';
import { rotate } from '../../../Contract/components/ContractGraphContainer/ContractGraphContainer';

const filters = ['7 Days', '1 Month', '1 Year'];

const endDate = new Date().toISOString();

const CustomerGraphContainer = () => {
  const [selected, setSelected] = useState(filters[0]);
  const getOrderRequest = useMemo<{
    startDate: string;
    endDate: string;
    timeUnit: string;
  }>(() => {
    if (selected === '7 Days') {
      return {
        endDate,
        startDate: moment().subtract(6, 'd').toISOString(),
        timeUnit: 'day',
      };
    } else if (selected === '1 Month') {
      return {
        endDate,
        startDate: moment().subtract(1, 'month').toISOString(),
        timeUnit: 'day',
      };
    } else
      return {
        endDate,
        startDate: moment().subtract(11, 'months').toISOString(),
        timeUnit: 'month',
      };
  }, [selected]);

  const { data, isSuccess, isFetching } =
    useGetOrderChartRatingQuery(getOrderRequest);

  const handleSelectFilter = useCallback(
    (filter: string) => {
      setSelected(filter);
    },
    [setSelected],
  );

  const { yData, xData } = useMemo<{
    xData: Array<string>;
    yData: Array<number>;
  }>(() => {
    if (!data)
      return {
        xData: [],
        yData: [],
      };
    const yData = data.map((e) => e.value) || [];
    let xData: string[] = [];
    if (selected === '7 Days') {
      const weekday = moment().weekday();
      xData = rotate(moment.weekdaysShort(), weekday + 1);
    } else if (selected === '1 Month') {
      xData = data.map((el) => moment(el.key, 'YYYY-MM-DD').format('MM/DD'));
    }
    if (selected === '1 Year') {
      const month = moment().month();
      xData = rotate(moment.monthsShort(), month + 1);
    }

    return { xData, yData };
  }, [selected, data]);

  return (
    <section className="customer-graph-container">
      <div className="customer-header">
        <div className="customer-left">
          <BlockTitle>Customer Satisfaction</BlockTitle>
          <GraphFilters
            filters={filters}
            selected={selected}
            onSetSelected={handleSelectFilter}
          />
        </div>
      </div>

      {isSuccess && !isFetching && <Graph xData={xData} yData={yData} />}
    </section>
  );
};

export default CustomerGraphContainer;
