import React, { useEffect, useRef, useState } from 'react';
import order from '../../../../assets/icons/orderPage/order.svg';
import './SelectProduct.scss';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  IFiltersTypes,
  IOrderCard,
  IOrderWithSKUResponse,
} from '../../../../entities/Order/order.models';
import { ISkuResponse, ITag } from '../../../../entities/SKU/SKUModel.models';
import TemplatePage from '../../../TemplatePage/TemplatePage';
import OrderTitle from '../../../../shared/components/Title/OrderTitle/OrderTitle';
import BasicOrderButton from '../../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import NextOrderButton from '../../../../shared/components/Button/OrderButtons/NextOrderButton/NextOrderButton';
import OrderStagesPanel from '../../../../widgets/Order/OrderStagesPanel/OrderStagesPanel';
import SelectProductFilters from '../../../../features/Filters/SelectProductFilters/SelectProductFilters';
import { useGetSkuQuery } from '../../../../entities/SKU/api/SKUApi';
import ProductCard from './components/ProductCard/ProductCard';

const SelectProduct = () => {
  const location = useLocation();
  const { contactData, orderProductCards, currOrderSkuToEdit, id } =
    location.state || {};
  const [stagesData, setStagesData] = useState([
    { label: 'Crew', active: true, bold: false, secondLine: '', state: { id } },
    { label: 'Contract & Schedule', active: false, state: { id } },
    { label: 'Review Summary Order', active: false, state: { id } },
  ]);
  const [selectedFilters, setSelectedFilters] = useState({
    product: 'All Products',
    grade: 'All',
  });
  const [selectedCards, setSelectedCards] = useState<IOrderCard[]>([]);
  const [isAlertVisible, setAlertVisibility] = useState(false);

  const navigate = useNavigate();

  const totalCrewQuantityRef = useRef<number>(0);

  const { data: skus, isSuccess } = useGetSkuQuery({});

  useEffect(() => {
    if (totalCrewQuantityRef.current > 0) setAlertVisibility(false);
  }, [totalCrewQuantityRef.current]);

  useEffect(() => {
    if (currOrderSkuToEdit) {
      setSelectedCards(
        currOrderSkuToEdit.skuRequests.map((data: IOrderWithSKUResponse) => {
          return {
            id: data.sku.id,
            quantity: data.crewsNumber,
            price: data.sku.pricePerUnit * data.crewsNumber,
            product: data.sku.productName,
            tags: data.sku.tags,
          };
        }),
      );
    } else if (orderProductCards) {
      setSelectedCards(orderProductCards);
    }
  }, [currOrderSkuToEdit, orderProductCards]);

  useEffect(() => {
    setStagesData((prevStagesData) => {
      totalCrewQuantityRef.current = selectedCards.reduce(
        (sum, card) => sum + card.quantity,
        0,
      );

      const newStagesData = [...prevStagesData];

      if (totalCrewQuantityRef.current === 0) {
        newStagesData[0] = {
          label: 'Crew',
          active: true,
          bold: false,
          secondLine: '',
          state: { id },
        };
      } else {
        let secondLine = selectedCards
          .map((el) => el.product)
          .slice(0, 3)
          .join(', ');
        if (selectedCards.length > 3)
          secondLine = `${secondLine} and ${selectedCards.length - 3} more`;
        newStagesData[0] = {
          label: `${totalCrewQuantityRef.current} Products Selected`,
          secondLine,
          active: true,
          bold: true,
          state: { id },
        };
      }

      return newStagesData;
    });
  }, [selectedCards]);

  const handleFiltersChange = (filters: IFiltersTypes) => {
    setSelectedFilters(filters);
  };

  const handleSelectCard = (data: {
    quantity: number;
    id: number;
    tags: ITag[];
    sku: ISkuResponse;
  }) => {
    setSelectedCards((prevSelectedCards) => {
      const existingCardIndex = prevSelectedCards.findIndex(
        (card) => card.id === data.id,
      );

      const cardData: IOrderCard = {
        tags: data.tags,
        id: data.id,
        quantity: data.quantity,
        product: data.sku.productName,
        price: data.sku.pricePerUnit,
      };

      if (existingCardIndex !== -1) {
        const updatedSelectedCards = [...prevSelectedCards];
        updatedSelectedCards[existingCardIndex].quantity = cardData.quantity;
        updatedSelectedCards[existingCardIndex].tags = cardData.tags;
        return updatedSelectedCards;
      } else {
        return [...prevSelectedCards, cardData];
      }
    });
  };

  const handleDeleteCard = (id: number) => {
    setSelectedCards((prevSelectedCards) =>
      prevSelectedCards.filter((card) => card.id !== id),
    );
  };

  const handleNavigateToNextPage = () => {
    if (totalCrewQuantityRef.current > 0) {
      navigate('/contract-schedule', {
        state: {
          contactData,
          selectedCards,
          totalCrewQuantityRef,
          currOrderSkuToEdit,
          id,
        },
      });
    } else {
      setAlertVisibility(true);
    }
  };
  return (
    <div className="select-product-container">
      <TemplatePage>
        <div className="select-product">
          <div className="select-product-header">
            <OrderTitle title="Select Product" icon={order} />
            <div className="select-product-btns">
              <Link to={'/orders'}>
                <BasicOrderButton label="Cancel" />
              </Link>
              <div
                onClick={handleNavigateToNextPage}
                style={{ cursor: 'pointer' }}>
                <NextOrderButton label="Next" />
              </div>
            </div>
          </div>
          <OrderStagesPanel data={stagesData} />
          <div className="hr"></div>
          <div className="select-product-filters-validation">
            <SelectProductFilters
              selectedFilters={selectedFilters}
              onFiltersChange={handleFiltersChange}
            />
            {isAlertVisible && (
              <div className="validation-message">
                Please select at least one crew member before proceeding.
              </div>
            )}
          </div>
          <div className="select-product-cards">
            {isSuccess &&
              skus.map((sku) => {
                const addedCard = selectedCards.find((el) => el.id === sku.id);
                return (
                  <ProductCard
                    key={sku.id}
                    qualityName={sku.qualityName}
                    grade={sku.qualityId}
                    id={sku.id}
                    pricePerUnit={sku.pricePerUnit}
                    productName={sku.productName}
                    onSelectCard={handleSelectCard}
                    onDeleteCard={handleDeleteCard}
                    orderableInventory={sku.orderableInventory}
                    tags={sku.tags}
                    isAdded={!!addedCard}
                    quantity={addedCard?.quantity}
                    sku={sku}
                  />
                );
              })}
          </div>
        </div>
      </TemplatePage>
    </div>
  );
};

export default SelectProduct;
