import React from 'react';
import './OrderInformation.scss';
import OrderSubtitle from '../../../shared/components/Title/OrderSubtitle/OrderSubtitle';
import order from '../../../assets/icons/orderPage/order.svg';

interface PropTypes {
  date: string;
  number: string;
}

const OrderInformation = (props: PropTypes) => {
  const { date, number } = props;

  return (
    <div className="order-info">
      <OrderSubtitle icon={order} title="Order Information" />
      <div className="order-item">
        <div className="order-label">Order Date</div>
        <div className="order-data">{date}</div>
      </div>
      <div className="order-item">
        <div className="order-label">Order Number</div>
        <div className="order-data">{number}</div>
      </div>
    </div>
  );
};

export default OrderInformation;
