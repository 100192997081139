import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '../../../shared/api/api';
import { getToken } from '../../Admin/admin.models';
import { ISkuResponse } from '../SKUModel.models';
import { ORDER_DIVIDER } from '../../../pages/CreateOrder/components/SelectProduct/components/ProductCard/ProductCard';

const SKUConfig = {
  ...baseQueryConfig,
  baseUrl: baseQueryConfig.baseUrl + '/skus',
  prepareHeaders: (headers: Headers) => {
    const session = getToken();
    headers.set('Authorization', `${session.token}`);
    return headers;
  },
};

export const SKUApi = createApi({
  reducerPath: 'SKUApi',
  tagTypes: ['SKU'],
  baseQuery: fetchBaseQuery(SKUConfig),
  endpoints: (builder) => ({
    getSku: builder.query<ISkuResponse[], { relations?: string[] }>({
      query: ({ relations = [] }) => {
        let url = '';
        if (relations.length > 0) {
          url = `?relations=${relations.join(',')}`;
        }
        return { url };
      },
      providesTags: ['SKU'],
      transformResponse(baseQueryReturnValue: any) {
        const filtered = baseQueryReturnValue
          .filter(
            (el: any) =>
              el.orderableInventory > ORDER_DIVIDER && el.pricePerUnit,
          )
          .map((el: any) => ({ ...el, tags: el.skuTags }));

        return filtered;
      },
    }),
    updateSKU: builder.mutation<any, any>({
      query: (data) => ({
        url: `/${data.id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['SKU'],
    }),
  }),
});

export const { useGetSkuQuery, useUpdateSKUMutation } = SKUApi;
