import React, { useEffect, useState } from 'react';
import './OrderTags.scss';
import { IOrderTag } from '../../entities/Order/order.models';
import CheckboxInput from '../../shared/components/Input/CheckboxInput/CheckboxInput';

interface PropTypes {
  tagList: IOrderTag[];
  selectedTags?: IOrderTag[];
  handleTagData?: (tagIds: IOrderTag[]) => void;
}

const OrderTags = (props: PropTypes) => {
  const { tagList, selectedTags: selectedTagsInit, handleTagData } = props;
  const [selectedTags, setSelectedTags] = useState<IOrderTag[]>(
    selectedTagsInit || [],
  );

  useEffect(() => {
    handleTagData && handleTagData(selectedTags);
  }, [selectedTags]);

  const handleCheckboxChange = (isChecked: boolean, tagItem: IOrderTag) => {
    setSelectedTags((prevTags) => {
      if (isChecked) {
        if (!prevTags.find((item) => item.id === tagItem.id)) {
          return [...prevTags, tagItem];
        }
      } else {
        return prevTags.filter((item) => item.id !== tagItem.id);
      }
      return prevTags;
    });
  };

  return (
    <div className="order-tags">
      <div className="tag-list">
        {tagList.map((tagItem) => (
          <div className="order-tag-item" key={tagItem.id}>
            <CheckboxInput
              tag={tagItem}
              onCheckboxChange={handleCheckboxChange}
              selectedTags={selectedTags}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderTags;
