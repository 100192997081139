import React, { useEffect, useMemo, useState } from 'react';
import './SelectProductCard.scss';
import plus from '../../../assets/icons/orderPage/plus.svg';
import minus from '../../../assets/icons/orderPage/minus.svg';
import check from '../../../assets/icons/orderPage/check.svg';
import clear from '../../../assets/icons/orderPage/clear.svg';
import star from '../../../assets/icons/orderPage/star.svg';
import OrderSubtitle from '../../../shared/components/Title/OrderSubtitle/OrderSubtitle';
import { IOrderCard } from '../order.models';

interface PropTypes {
  icon?: string;
  position: string;
  grade?: number;
  price: number;
  priceSingle?: number;
  id?: number;
  femalesPerc?: number;
  localsPerc?: number;
  ordInv?: number;
  finalQuantity?: number;
  cardQuantity?: number;
  isSummary?: boolean;
  isSubmitted?: boolean;
  isAdded?: boolean;
  onSelectCard?: (cardsData: IOrderCard) => void;
  onDeleteCard?: (cardsData: IOrderCard) => void;
  onDeleteProductCard?: (id: number) => void;
  onQuantityChange?: (id: number, quantity: number) => void;
}

const SelectProductCard = (props: PropTypes) => {
  const {
    id,
    icon,
    position,
    grade,
    price,
    priceSingle,
    finalQuantity,
    cardQuantity,
    isSummary,
    isSubmitted,
    isAdded = false,
    femalesPerc,
    localsPerc,
    ordInv,
    onSelectCard,
    onDeleteCard,
    onDeleteProductCard,
    onQuantityChange,
  } = props;
  const [quantity, setQuantity] = useState(cardQuantity || 1);
  const [hovered, setHovered] = useState(false);
  const [added, setAdded] = useState(isAdded);

  useEffect(() => {
    if (added === true && onSelectCard) {
      const selectedCardData = {
        id,
        icon,
        product: position,
        grade,
        quantity,
        price,
        femalesPerc,
        localsPerc,
        ordInv,
      };
      onSelectCard(selectedCardData);
    } else if (added === false && onDeleteCard) {
      const selectedCardData = {
        id,
        icon,
        product: position,
        grade,
        quantity,
        price,
        femalesPerc,
        localsPerc,
        ordInv,
      };
      onDeleteCard(selectedCardData);
    }
  }, [added, quantity]);

  const shouldShowDeleteButton =
    (added || isSummary) && !isSubmitted && onDeleteCard;
  const shouldShowAddToOrder = hovered && !added && !isSummary;
  const shouldShowCardQuantity =
    (hovered || added || isSummary) && !finalQuantity;

  const toggleMouseOver = () => {
    if (!added) {
      setHovered(!hovered);
    }
  };

  const handleButtonClick = () => {
    if (hovered) {
      setAdded(true);
    }
  };

  const handleIncrement = () => {
    if ((icon && ordInv && quantity < ordInv / 15) || (!icon && quantity)) {
      setQuantity((prevQuantity) => {
        const newQuantity = prevQuantity + 1;
        onQuantityChange && onQuantityChange(id ?? 0, newQuantity);
        return newQuantity;
      });
    }
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => {
        const newQuantity = prevQuantity - 1;
        onQuantityChange && onQuantityChange(id ?? 0, newQuantity);
        return newQuantity;
      });
    }
  };

  const onCancel = () => {
    setAdded(false);
    setHovered(false);
    isSummary && onDeleteProductCard && onDeleteProductCard(id || 0);
  };

  const memoizedTotalPrice = useMemo(() => {
    if (isSummary && finalQuantity && priceSingle) {
      return priceSingle * finalQuantity;
    } else {
      return price;
    }
  }, [isSummary, finalQuantity, priceSingle, price]);

  return (
    <div
      className="select-product-card"
      onMouseEnter={toggleMouseOver}
      onMouseLeave={toggleMouseOver}>
      <div className="card-left">
        {icon && <img className="card-icon" src={icon} alt="icon" />}
        <div className="card-info">
          <div className="card-info-person">
            <OrderSubtitle title={position} />
          </div>
          <div className="card-info-details">
            <div className="card-rating">
              <img src={star} alt="*" />
              <div className="card-grade">
                {grade} {grade === 1 ? 'Star' : 'Stars'}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-right">
        {!isSummary && <div className="card-price">{price} SAR</div>}
        {shouldShowCardQuantity && (
          <div className="card-quantity">
            <img src={minus} alt="-" onClick={handleDecrement} />
            {quantity}
            <img src={plus} alt="+" onClick={handleIncrement} />
          </div>
        )}
        {finalQuantity && (
          <div className="card-final-quantity">
            <div className="final-quantity-label">Qty</div>
            <div className="final-quantity-data">{finalQuantity}</div>
          </div>
        )}
        {isSummary && (
          <div className="card-prices">
            <div className="card-price">{memoizedTotalPrice} SAR</div>
            <div className="card-price-extra">@ {priceSingle} SAR</div>
          </div>
        )}

        {shouldShowAddToOrder && (
          <button className="btn-add-to" onClick={handleButtonClick}>
            Add to Order
          </button>
        )}

        {added && (
          <button className="btn-added" onClick={onCancel}>
            Added
            <img src={check} alt="+" />
          </button>
        )}

        {shouldShowDeleteButton && (
          <button className="btn-delete" onClick={onCancel}>
            <img src={clear} alt="х" />
          </button>
        )}
      </div>
    </div>
  );
};

export default SelectProductCard;
