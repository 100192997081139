import React from 'react';
import './TotalQuantity.scss';
import OrderSubtitle from '../../../shared/components/Title/OrderSubtitle/OrderSubtitle';
import order from '../../../assets/icons/orderPage/order.svg';

interface PropTypes {
  quantity: number;
}

const TotalQuantity = (props: PropTypes) => {
  const { quantity } = props;
  return (
    <div className="total-quantity">
      <OrderSubtitle icon={order} title="Total Quantity" />
      <div className="quantity-number">{quantity}</div>
    </div>
  );
};

export default TotalQuantity;
