import React from 'react';
import './SummaryDetailsLine.scss';

interface PropTypes {
  label: string;
  quantity?: number;
  data: string;
  icon: string;
}

const SummaryDetailsLine = (props: PropTypes) => {
  const { label, quantity, data, icon } = props;
  return (
    <div className="summary-details-line">
      <div className="details-left">
        <div className="details-label">{label}</div>
        {quantity && (
          <div className="details-quantity">
            <div className="quantity-label">Qty</div>
            <div className="quantity-number">{quantity}</div>
          </div>
        )}
      </div>
      <div className="details-right">
        <div className="details-data">{data}</div>
        <img src={icon} alt="icon" />
      </div>
    </div>
  );
};

export default SummaryDetailsLine;
