import React, { useState, useCallback, useMemo } from 'react';
import './SummaryOrder.scss';
import money from '../../../../assets/icons/orderPage/money.svg';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  useCreateOrderMutation,
  useDeleteOrderMutation,
  useGetOrdersQuery,
  useUpdateOrderMutation,
} from '../../../../entities/Order/api/orderApi';
import {
  IOrder,
  IOrderCard,
  IOrderSchedule,
  ISkuUpdateRequest,
} from '../../../../entities/Order/order.models';
import { useGetOrganizationsQuery } from '../../../../entities/Organization/api/organizationApi';
import { IOrganization } from '../../../../entities/Organization/org.models';
import { IContact } from '../../../../entities/Contact/contact.models';
import { IBranch } from '../../../../entities/Branch/branch.models';
import TemplatePage from '../../../TemplatePage/TemplatePage';
import OrderTitle from '../../../../shared/components/Title/OrderTitle/OrderTitle';
import BasicOrderButton from '../../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import NextOrderButton from '../../../../shared/components/Button/OrderButtons/NextOrderButton/NextOrderButton';
import OrderStagesPanel from '../../../../widgets/Order/OrderStagesPanel/OrderStagesPanel';
import ClientSummary from '../../../../widgets/Order/ClientSummary/ClientSummary';
import TotalQuantity from '../../../../widgets/Order/TotalQuantity/TotalQuantity';
import SummaryTotal from '../../../../widgets/Order/SummaryTotal/SummaryTotal';
import SummaryOrderCard from '../../../../entities/Order/SummaryOrderCard/SummaryOrderCard';
import { useGetSkuQuery } from '../../../../entities/SKU/api/SKUApi';

const convertToIso = (schedule: IOrderSchedule) => {
  if (!schedule.date && schedule.startTime) {
    return schedule;
  }
  const inputDate = `${schedule.date} ${schedule.startTime}`;
  const dateObject = new Date(inputDate);

  let isoString;
  if (!isNaN(dateObject.getTime())) {
    isoString = dateObject.toISOString();
  }

  return {
    startTime: isoString,
    slotsNumber: schedule.slotsNumber,
  };
};

const SummaryOrder = () => {
  const location = useLocation();
  const {
    contactData,
    orderProductCards: orderProductCardsInit,
    totalQuantity,
    currOrderCardList,
    id: orderListId,
    currOrderSkuToEdit: currOrderSkuToEditInit,
  } = location.state || {};
  const {
    selectedOrg: organizationName,
    selectedBranch: branchName,
    selectedContact: contactName,
  } = contactData || {};
  const {
    price: orderListPrice,
    totalQty: orderListTotalQty,
    orderStatus,
  } = currOrderCardList || currOrderSkuToEditInit || {};

  //initialize the state for product cards (registration flow) and skuToEdit (editing flow)
  const [orderProductCards, setOrderProductCards] = useState<IOrderCard[]>(
    orderProductCardsInit,
  );

  const { isLoading: isSkuLoading } = useGetSkuQuery({});
  const [createOrder, { isLoading: isLoadingCreate }] = useCreateOrderMutation(
    {},
  );
  const [editOrder, { isLoading: isLoadingEdit }] = useUpdateOrderMutation();
  const [deleteOrder] = useDeleteOrderMutation();
  const navigate = useNavigate();
  const { data: ordersDataSkuRaw } = useGetOrdersQuery({
    sortField: 'id',
    sortOrder: 'desc',
    relations: ['skuRequests'],
    take: 100,
    showSkus: true,
  });
  const ordersDataSku = ordersDataSkuRaw?.data;
  const { data: ordersDataOrgRaw } = useGetOrdersQuery({
    sortField: 'id',
    sortOrder: 'desc',
    relations: ['contact.branch.organization'],
    take: 100,
    showSkus: true,
  });

  const ordersDataOrg = ordersDataOrgRaw?.data;
  const currListOrderSku = ordersDataSku?.find(
    (order: IOrder) => order.id === orderListId,
  );
  const currListOrderOrg = ordersDataOrg?.find(
    (order: IOrder) => order.id === orderListId,
  );

  const listContactName = `${currListOrderOrg?.contact?.firstName} ${currListOrderOrg?.contact?.lastName}`;
  const listBranchName = currListOrderOrg?.contact?.branch?.name;
  const listOrgName = currListOrderOrg?.contact?.branch?.organization?.name;
  const listTaxReg = currListOrderOrg?.contact?.branch?.organization?.taxReg;
  const currentListOrder = { ...currListOrderSku, contact: listContactName };

  const [currOrderSkuToEdit, setCurrOrderSkuToEdit] = useState<any>(
    currOrderSkuToEditInit?.skuRequests
      ? currOrderSkuToEditInit
      : currentListOrder,
  );
  const productNames = orderProductCards?.map(
    (card: IOrderCard) => card.product,
  );

  const productNamesString = useMemo(() => {
    return productNames?.join(', ');
  }, [productNames]);

  const { data: orgDataRaw } = useGetOrganizationsQuery({
    relations: ['branches.contacts'],
  });
  const orgData = orgDataRaw?.data;

  const currOrgData = orgData?.find(
    (org: IOrganization) => org.name === organizationName,
  );
  const currBranchData = currOrgData?.branches?.find(
    (branch: IBranch) => branch.name === branchName,
  );
  const branchContactsData =
    currBranchData?.contacts?.map((contact: IContact) => ({
      id: contact.id,
      firstName: contact.firstName,
      lastName: contact.lastName,
    })) || [];

  const contactDataId = useMemo(() => {
    return (
      currBranchData?.contacts?.find((contact: IContact) => {
        return (
          `${contact.firstName} ${contact.lastName}` ===
          contactData.selectedContact
        );
      })?.id || 0
    );
  }, [currBranchData]);

  const currentOrg = orgData?.find(
    (org: IOrganization) => org.name === organizationName,
  );
  const currentTaxReg = currentOrg?.taxReg;

  const priceTotal = orderProductCards?.reduce(
    (sum: number, card: IOrderCard) => sum + card.price,
    0,
  );
  console.log(orderProductCards);
  const handleEditOrder = useCallback(async () => {
    const skuRequestsArr = orderProductCards?.map((card: IOrderCard) => {
      return {
        sku: card.id,
        contract: {
          isHousing: card.contracts?.[0].isHousing,
          contractType: card.contracts?.[0].contractType,
          maxSalary: card.contracts?.[0].maxSalary,
        },
        crewsNumber: card.quantity,
        femalesPercentage: card.contracts?.[0].femalesPercentage,
        localsPercentage: card.contracts?.[0].localsPercentage,
        schedules: card.schedules?.map((schedule) => convertToIso(schedule)),
        contact: card.contactId,
        tags: [],
      };
    }) as unknown as ISkuUpdateRequest[];
    console.log('skusend', skuRequestsArr);
    editOrder({
      id: orderListId,
      skuRequests: skuRequestsArr,
    })
      .unwrap()
      .then(() => {
        navigate('/order-created', {
          state: {
            orderId: orderListId,
          },
        });
      });
  }, [orderListId]);

  const handleCreateOrder = useCallback(async () => {
    const skuRequestsArr = orderProductCards?.map((card: IOrderCard) => {
      const { contracts, quantity, schedules, id: skuId } = card;
      const contractData = contracts?.[0];
      const contractType = contractData?.contractType;
      const contractContact = branchContactsData?.find(
        (brContact: { firstName: string; lastName: string }) =>
          `${brContact.firstName} ${brContact.lastName}` === card.contact,
      );
      const contractContactId = contractContact?.id;
      const maxSalary = Number(contractData?.maxSalary);
      const housing = contractData?.housing;
      const femalesPercentage = contractData?.femalesPercentage;
      const localsPercentage = contractData?.localsPercentage;
      const schedulesData = schedules?.map((schedule) =>
        convertToIso(schedule),
      );

      return {
        contract: {
          contractType,
          maxSalary,
          isHousing: housing,
        },
        crewsNumber: quantity,
        femalesPercentage,
        localsPercentage,
        contact: contractContactId,
        schedules: schedulesData,
        sku: skuId,
        tags: [],
      };
    });
    const newOrder = {
      skuRequests: skuRequestsArr,
      contact: contactDataId,
    };
    try {
      const { id } = await createOrder(newOrder).unwrap();
      navigate('/order-created', {
        state: {
          orderId: id,
          contactDataId,
          contactData,
          orderProductCards,
          totalQuantity,
          currOrderCardList,
          currOrderSkuToEdit,
        },
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  }, [navigate, contactDataId]);

  const handleDeleteOrder = useCallback(
    (orderId: number) => {
      deleteOrder(orderId)
        .unwrap()
        .then(() => navigate('/orders'));
    },
    [deleteOrder],
  );

  const isEdit = orderListId;

  const handleDeleteProductCard = (id: number) => {
    setOrderProductCards((prevCards) => {
      const newProductCards = prevCards?.filter((card) => {
        return card.id !== id;
      });

      return newProductCards;
    });
    setCurrOrderSkuToEdit((prevOrder: IOrder) => {
      const newSkuReqs = prevOrder?.skuRequests?.filter(
        (skuReq) => skuReq.id !== id,
      );
      const updatedOrder = { ...prevOrder, skuRequests: newSkuReqs };
      return updatedOrder;
    });
  };

  const productsSelected = orderProductCards.reduce((sum, el) => {
    return el.quantity + sum;
  }, 0);
  const stagesData = useMemo(
    () => [
      {
        label: `${productsSelected} Products Selected`,
        secondLine: productNamesString,
        active: true,
        bold: true,
        link: '/select-product',
        state: {
          orderProductCards,
          id: orderListId,
        },
      },
      {
        label: 'Contract & Schedule',
        active: true,
        link: '/contract-schedule',
        state: {
          contactData,
          orderProductCards,
          totalQuantity,
          currOrderCardList,
          currOrderSkuToEdit,
          id: orderListId,
        },
      },
      { label: 'Review Summary Order', active: true },
    ],
    [productNamesString],
  );
  const taxReg = currentTaxReg || listTaxReg || 0;
  if (isSkuLoading) return <div></div>;

  return (
    <div className="summary-order-container">
      <TemplatePage>
        <div className="summary-order">
          <div className="summary-order-header">
            <OrderTitle icon={money} title="Summary Order" />
            <div className="summary-btns">
              <Link to={'/orders'}>
                <BasicOrderButton label="Cancel" />
              </Link>
              <div className="summary-btns-next">
                <Link
                  to={'/contract-schedule'}
                  state={{
                    contactData,
                    orderProductCards,
                    totalQuantity,
                    currOrderCardList,
                    currOrderSkuToEdit,
                  }}>
                  <BasicOrderButton label="Edit" />
                </Link>
                <NextOrderButton
                  label={isEdit ? 'Save changes' : 'Place order'}
                  onClick={isEdit ? handleEditOrder : handleCreateOrder}
                  isDisabled={isLoadingCreate || isLoadingEdit}
                />
              </div>
            </div>
          </div>
          <OrderStagesPanel data={stagesData} />
          <div className="hr"></div>
          <div className="summary-order-content">
            <div className="summary-client-status">
              <ClientSummary
                contact={contactName || listContactName}
                org={organizationName || listOrgName}
                branch={branchName || listBranchName}
                taxReg={taxReg}
              />
              {!orderProductCards && (
                <div className="summary-status">
                  Status: <span className="status-data">{orderStatus}</span>
                </div>
              )}
              <BasicOrderButton
                label="Delete Order"
                isFilled
                onClick={() =>
                  handleDeleteOrder(
                    currOrderCardList?.id || currOrderSkuToEdit?.id || 0,
                  )
                }
              />
            </div>
            <div className="summary-total-cards">
              <div className="summary-order-cards">
                {orderProductCards?.map((card) => (
                  <SummaryOrderCard
                    id={card.id || 0}
                    key={card.id}
                    icon={card.icon}
                    position={card.product}
                    grade={card.grade}
                    price={card.price * card.quantity}
                    priceSingle={card.price}
                    finalQuantity={card.quantity}
                    contract={
                      card && card.contracts && card.contracts?.length > 0
                        ? card.contracts![0]
                        : undefined
                    }
                    schedules={card.schedules}
                    contact={card.contact}
                    handleDeleteProductCard={(id) =>
                      handleDeleteProductCard(id)
                    }
                  />
                ))}
              </div>
              <TotalQuantity quantity={totalQuantity || orderListTotalQty} />
            </div>
          </div>
          <div className="summary-order-footer">
            <SummaryTotal subtotal={orderListPrice || priceTotal} />
          </div>
        </div>
      </TemplatePage>
    </div>
  );
};

export default SummaryOrder;
