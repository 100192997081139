import React from 'react';
import './SKU.scss';
import TemplatePage from '../TemplatePage/TemplatePage';
import SKUCard from '../../entities/SKU/SKUCard/SKUCard';
import { useGetSkuQuery } from '../../entities/SKU/api/SKUApi';

const SKU = () => {
  const { data } = useGetSkuQuery({});
  return (
    <TemplatePage title="Product prices">
      <div className={'sku-cards-body'}>
        {data?.map((sku) => <SKUCard key={sku.id} sku={sku} />)}
      </div>
    </TemplatePage>
  );
};

export default SKU;
