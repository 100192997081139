import React from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';

import BranchInput from '../../../../../../shared/components/Input/BranchInput/BranchInput';
import { yupResolver } from '@hookform/resolvers/yup';
import PhoneInput from 'react-phone-input-2';
import SectionButton from '../../../../../../shared/components/Button/SectionButton/SectionButton';
import ContactInput from '../../../../../../shared/components/Input/ContactInput/ContactInput';
import styles from './AddContactForm.module.scss';

const yupSchema = yup.object({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().phone('SA').required(),
});

type YupSchemaType = yup.InferType<typeof yupSchema>;

export type Form = Omit<YupSchemaType, 'name'> & {
  firstName: string;
  lastName: string;
};

type AddContactFormProps = {
  onSubmit: (form: Form) => Promise<boolean>;
};

export const AddContactForm = ({ onSubmit }: AddContactFormProps) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupSchema),
  });
  const onSubmitHandler: SubmitHandler<YupSchemaType> = async (formData) => {
    const isDone = await onSubmit({
      firstName: formData.name.split(' ')[0],
      lastName: formData.name.split(' ')[1],
      email: formData.email,
      phone: formData.phone,
    });
    if (isDone) {
      reset();
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="branch-new-product-inputs">
        <ContactInput
          error={errors.name?.message}
          label="Name"
          {...register('name')}
        />
        <ContactInput
          error={errors.email?.message}
          label="Email"
          {...register('email')}
        />
        <div className="branch-new-product-inputs-phone">
          <Controller
            control={control}
            name="phone"
            rules={{ required: true }}
            render={({
              field: { ref, ...field },
              formState: {
                errors: { phone },
              },
            }) => (
              <>
                <PhoneInput
                  {...field}
                  onChange={(e) => {
                    console.log(e);
                    field.onChange('+' + e);
                  }}
                  inputProps={{
                    ref,
                    required: true,
                    autoFocus: true,
                  }}
                  country={'sa'}
                />
                {phone && <div className={styles.error}>{phone.message}</div>}
              </>
            )}
          />
        </div>
      </div>
      <SectionButton type="submit" label="+ Add Contact" />
    </form>
  );
};
