import React from 'react';
import './OrderCreated.scss';
import order from '../../assets/icons/orderPage/order.svg';
import { Link, useLocation } from 'react-router-dom';

import { format } from 'date-fns';
import { useGetOrderByIdWithRelationsQuery } from '../../entities/Order/api/orderApi';
import TemplatePage from '../TemplatePage/TemplatePage';
import OrderTitle from '../../shared/components/Title/OrderTitle/OrderTitle';
import OrderInformation from '../../widgets/Order/OrderInformation/OrderInformation';
import ClientSummary from '../../widgets/Order/ClientSummary/ClientSummary';
import TotalQuantity from '../../widgets/Order/TotalQuantity/TotalQuantity';
import SummaryTotal from '../../widgets/Order/SummaryTotal/SummaryTotal';
import NextOrderButton from '../../shared/components/Button/OrderButtons/NextOrderButton/NextOrderButton';
import { OrderCards } from './components/OrderCards/OrderCards';

const OrderCreated = () => {
  const location = useLocation();
  const { orderId } = location.state || {};

  const { data: normalOrder, isSuccess } = useGetOrderByIdWithRelationsQuery({
    id: orderId,
  });

  if (!isSuccess) return <div></div>;

  const contactName = `${normalOrder?.contact?.firstName} 
  ${normalOrder?.contact?.lastName}`;
  const branchName = normalOrder?.contact?.branch?.name || '';
  const orgName = normalOrder?.contact?.branch?.organization?.name || '';
  const taxReg =
    Number(normalOrder?.contact?.branch?.organization?.taxReg) || 1;

  const dateObject = new Date(normalOrder.createdAt ?? '');
  const totalPrice = normalOrder.skuRequests?.reduce((sum: number, skuItem) => {
    return sum + skuItem.sku.pricePerUnit * skuItem.crewsNumber;
  }, 0);
  const totalQty = normalOrder.skuRequests?.reduce((sum: number, skuReq) => {
    return sum + skuReq.crewsNumber;
  }, 0);

  let orderCreatedDate = '';
  if (normalOrder) {
    orderCreatedDate = format(dateObject, 'dd.MM.yyyy HH:mm');
  }

  return (
    <div className="order-created-container">
      <TemplatePage>
        <div className="order-created">
          <div className="order-created-header">
            <OrderTitle icon={order} title="Order has been placed" />
            <div className="order-subtitle">
              The confirmation email has been sent. Waiting for the client’s
              response
            </div>
          </div>
          <div className="order-created-content">
            <div className="order-info-client">
              <OrderInformation
                date={orderCreatedDate || 'undefined'}
                number={`ORD-${normalOrder?.id || ''}`}
              />
              <ClientSummary
                contact={contactName}
                org={orgName}
                branch={branchName}
                taxReg={taxReg}
              />
            </div>
            <div className="order-total-cards">
              <OrderCards skuRequests={normalOrder.skuRequests} />
              <TotalQuantity quantity={totalQty!} />
            </div>
          </div>
          <div className="order-created-footer">
            <SummaryTotal subtotal={totalPrice} />
            <Link to={'/create-order'}>
              <NextOrderButton label="Make another order" />
            </Link>
          </div>
        </div>
      </TemplatePage>
    </div>
  );
};

export default OrderCreated;
