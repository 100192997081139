import { Navigate, Outlet } from 'react-router-dom';
import React from 'react';
import { SkuProvider } from '../SkuProvider/SkuProvider';
import { useGetSkuQuery } from '../../../entities/SKU/api/SKUApi';

const ProtectedRoute = ({ children, user }: any) => {
  useGetSkuQuery({});
  if (!user) {
    return <Navigate to="/" replace />;
  }
  return <SkuProvider>{children ?? <Outlet />}</SkuProvider>;
};

export default ProtectedRoute;
