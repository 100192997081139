import React from 'react';
import './OrderCard.scss';
import SectionButton from '../../../shared/components/Button/SectionButton/SectionButton';
import classNames from 'classnames';
import {
  IOrderCard,
  IOrderWithSKUResponse,
  OrderStatuses,
} from '../order.models';
import { Link } from 'react-router-dom';
import { mapProductNameToIcon } from '../../../pages/CreateOrder/components/SelectProduct/components/ProductCard/helper';

interface PropTypes {
  className?: string;
  createdAt?: Date;
  id: number;
  price: number;
  orderStatus: OrderStatuses;
  skuRequests: IOrderWithSKUResponse[];
  children?: React.ReactNode;
  selectedOrg: string;
  selectedContact: string;
  selectedBranch: string;
}

const OrderCard = (props: PropTypes) => {
  const {
    className,
    id,
    createdAt,
    price,
    skuRequests,
    orderStatus,
    children,
    selectedOrg,
    selectedBranch,
    selectedContact,
  } = props;

  let formattedDate;
  if (createdAt) {
    const dateObject = new Date(createdAt);

    formattedDate = createdAt
      ? `${dateObject.toLocaleDateString(
          'en-GB',
        )}\u00a0\u00a0${dateObject.toLocaleTimeString('en-GB')}`
      : '';
  }

  const totalQty = skuRequests?.reduce(
    (sum, skuReq) => sum + skuReq.crewsNumber,
    0,
  );
  const orderProductCards: IOrderCard[] = skuRequests?.map((skuReq) => ({
    id: skuReq.sku.id,
    price: skuReq.crewsNumber * skuReq.sku.pricePerUnit,
    product: skuReq.sku.productName,
    quantity: skuReq.crewsNumber,
    contact: skuReq.contact.firstName + ' ' + skuReq.contact.lastName,
    contactId: skuReq.contact.id,
    contracts: [
      {
        ...skuReq.contract,
        femalesPercentage: skuReq.femalesPercentage,
        localsPercentage: skuReq.localsPercentage,
      },
    ],
    grade: skuReq.sku.qualityId,
    tags: skuReq.sku.tags,
    schedules: skuReq.schedules,
    icon: mapProductNameToIcon[skuReq.sku.productName],
  }));

  return (
    <div className={classNames('order-card', className)}>
      {children}
      <div className="order-content">
        <div className="order-number">#{id}</div>
        <div className="order-date">{formattedDate}</div>
        <div className="order-funds">
          SAR <b>{price}</b>
        </div>
        <div className="order-qty">
          Qty: <b>{totalQty}</b>
        </div>
        <div className="order-status">Status: {orderStatus}</div>
      </div>
      <Link
        to="/summary-order"
        state={{
          id,
          currOrderCardList: {
            price,
            totalQty,
            orderStatus,
          },
          contactData: {
            selectedOrg,
            selectedBranch,
            selectedContact,
          },
          orderProductCards,
        }}>
        <SectionButton label="More details" isFilled={false} />
      </Link>
    </div>
  );
};

export default OrderCard;
