import React from 'react';
import './SummaryBrochure.scss';
import contract from '../../../assets/icons/orderPage/contract.svg';
import money from '../../../assets/icons/orderPage/money-gray.svg';
import house from '../../../assets/icons/orderPage/house.svg';

interface PropTypes {
  contractType: string;
  maxSalary: number;
  housing: boolean;
}

const SummaryBrochure = (props: PropTypes) => {
  const { contractType, maxSalary, housing } = props;

  return (
    <div className="summary-brochure">
      <div className="summary-item">
        <img src={contract} alt="contract" />
        <div className="summary-info">
          <div className="summary-label">Contract type</div>
          <div className="summary-data">{contractType}</div>
        </div>
      </div>
      <div className="summary-item">
        <img src={money} alt="salary" />
        <div className="summary-info">
          <div className="summary-label">Max Salary</div>
          <div className="summary-data">{maxSalary} SAR</div>
        </div>
      </div>
      <div className="summary-item">
        <img src={house} alt="house" />
        <div className="summary-info">
          <div className="summary-label">Housing</div>
          <div className="summary-data">{housing === true ? 'Yes' : 'No'}</div>
        </div>
      </div>
    </div>
  );
};

export default SummaryBrochure;
