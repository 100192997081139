import React from 'react';
import './SummaryTotal.scss';

interface PropTypes {
  subtotal: number;
}

const SummaryTotal = (props: PropTypes) => {
  const { subtotal } = props;

  return (
    <div className="summary-total">
      <div className="summary-item">
        <div className="summary-label">Subtotal</div>
        <div className="summary-data">SAR {subtotal}</div>
      </div>
      <div className="summary-item">
        <div className="summary-label">
          Tax <b>15%</b>
        </div>
        <div className="summary-data">SAR {Math.round(subtotal * 0.15)}</div>
      </div>
      <div className="summary-item">
        <div className="summary-label">Total</div>
        <div className="summary-data data-total">
          SAR {Math.round(subtotal * 1.15)}
        </div>
      </div>
    </div>
  );
};

export default SummaryTotal;
