import React, { useCallback, useEffect } from 'react';
import './OrgEdit.scss';
import TemplateFormPage from '../../TemplateFormPage/TemplateFormPage';
import RegOrgSection from '../../../features/RegOrgSection/RegOrgSection';
import SectionButton from '../../../shared/components/Button/SectionButton/SectionButton';
import * as yup from 'yup';
import 'yup-phone-lite';
import {
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormRegister,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useGetOrganizationByIdQuery,
  useUpdateOrganizationMutation,
} from '../../../entities/Organization/api/organizationApi';
import { regOrgInputsData as inputsData } from '../RegisterOrg/regOrgInputs';

const yupSchema = yup
  .object({
    name: yup.string().required('Required field'),
    employeesAmount: yup
      .number()
      .typeError('Number required')
      .required('Required field'),
    commercialReg: yup
      .number()
      .typeError('Number required')
      .required('Required field')
      .test(
        'is-ten-digits',
        'Number must be 10 digits',
        (value) => value.toString().length === 10,
      ),
    commercialRegExpiryDate: yup
      .date()
      .typeError('Invalid date format')
      .required('Required field'),
    taxReg: yup
      .number()
      .typeError('Number required')
      .required('Required field')
      .test(
        'is-fifteen-digits',
        'Number must be 15 digits',
        (value) => value.toString().length === 15,
      ),
  })
  .required();

type YupSchemaType = yup.InferType<typeof yupSchema>;

const OrgEdit = () => {
  const { id } = useParams();
  if (!id) {
    return null;
  }
  const navigate = useNavigate();
  const { orgInputs } = inputsData;

  const [updateOrganization] = useUpdateOrganizationMutation();
  const { data: orgData } = useGetOrganizationByIdQuery(id);
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupSchema),
    defaultValues: {
      commercialReg: Number(orgData?.commercialReg) || 0,
      commercialRegExpiryDate: undefined,
      employeesAmount: Number(orgData?.commercialReg) || 0,
      name: orgData?.name || '',
      taxReg: Number(orgData?.taxReg) || 0,
    },
  });
  useEffect(() => {
    if (orgData) {
      reset({
        name: orgData.name || '',
        employeesAmount: Number(orgData.employeesAmount) || 0,
        commercialReg: Number(orgData.commercialReg) || 0,
        commercialRegExpiryDate: orgData.commercialRegExpiryDate || new Date(),
        taxReg: Number(orgData.taxReg) || 0,
      });
    }
  }, [orgData, reset]);

  const formatData = (formData: any) => {
    const orgDataFormatted = {
      commercialReg: formData.commercialReg,
      commercialRegExpiryDate: formData.commercialRegExpiryDate,
      employeesAmount: Number(formData.employeesAmount),
      name: formData.name,
      taxReg: formData.taxReg,
    };
    return { orgDataFormatted };
  };
  const onSubmitHandler: SubmitHandler<YupSchemaType> =
    useCallback(async () => {
      const orgInputs = getValues();
      const { orgDataFormatted } = formatData(orgInputs);
      try {
        await updateOrganization({ id, ...orgDataFormatted });
        navigate(-1);
      } catch (e) {
        console.log(e);
      }
    }, [getValues, formatData, id, navigate, updateOrganization]);

  const onError: SubmitErrorHandler<any> = useCallback((value: any) => {
    console.log('Error', value);
  }, []);

  return (
    <TemplateFormPage>
      <form
        className="register-org"
        onSubmit={handleSubmit(onSubmitHandler, onError)}>
        <div className="centre-form">
          <RegOrgSection
            category="Organization"
            inputEntries={orgInputs}
            register={register as unknown as UseFormRegister<FieldValues>}
            errors={errors}
          />
        </div>
        <div className="register-org-button">
          <SectionButton label="Save" type="submit" isFilled={true} />
        </div>
      </form>
    </TemplateFormPage>
  );
};

export default OrgEdit;
