import React from 'react';
import './OrderSubtitle.scss';

interface PropTypes {
  title: string;
  icon?: string;
}

const OrderSubtitle = (props: PropTypes) => {
  const { title, icon } = props;
  return (
    <div className="order-subtitle">
      {icon && <img src={icon} alt="icon" />}
      {title}
    </div>
  );
};

export default OrderSubtitle;
