import React from 'react';
import './OrderStagesPanel.scss';
import Stage from '../../../shared/components/Stage/Stage';
import { Link } from 'react-router-dom';

interface StageData {
  label: string;
  active: boolean;
  link?: string;
  state?: any;
  bold?: boolean;
  secondLine?: string;
}

interface PropTypes {
  data: StageData[];
}

const OrderStagesPanel = (props: PropTypes) => {
  const { data } = props;
  return (
    <div className="order-stages-panel">
      {data.map((stage, index) => (
        <Link key={index} to={stage.link || ''} state={stage.state || {}}>
          <Stage
            number={index + 1}
            label={stage.label}
            active={stage.active}
            key={index}
            isBold={stage.bold}
            secondLine={stage.secondLine}
          />
        </Link>
      ))}
    </div>
  );
};

export default OrderStagesPanel;
