import React, { useEffect, useState } from 'react';
import './CheckboxInput.scss';
import { IOrderTag } from '../../../../entities/Order/order.models';

interface PropTypes {
  tag: IOrderTag;
  selectedTags?: IOrderTag[];
  onCheckboxChange: (isChecked: boolean, tagItem: IOrderTag) => void;
}
const CheckboxInput = (props: PropTypes) => {
  const { tag, onCheckboxChange, selectedTags } = props;
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    setIsChecked(
      selectedTags?.some((selectedTag) => selectedTag.id === tag.id) || false,
    );
  }, [selectedTags, tag]);

  const handleCheckboxChange = () => {
    setIsChecked((isChecked) => {
      const newCheckedState = !isChecked;
      onCheckboxChange(newCheckedState, tag);
      return newCheckedState;
    });
  };

  return (
    <div className="checkbox-input-container">
      <label className="checkbox-label">
        <input
          className="checkbox-input"
          type="checkbox"
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
        {tag.name}
      </label>
    </div>
  );
};

export default CheckboxInput;
