import React, { useMemo } from 'react';
import classNames from 'classnames';
import './ContactCard.scss';
import SectionButton from '../../../shared/components/Button/SectionButton/SectionButton';
import EmailLink from '../../../shared/components/Link/EmailLink/EmailLink';
import WhatsAppLink from '../../../shared/components/Link/WhatsAppLink/WhatsAppLink';
import { Link } from 'react-router-dom';
import { IContact } from '../../Contact/contact.models';

type PropTypes = IContact & {
  className: string;
};

const ContactCard = (props: PropTypes) => {
  const { className, firstName, lastName, email, phone, branch } = props;
  const branchLabel = useMemo(() => {
    return branch?.name || 'Not available';
  }, []);

  const company = useMemo(
    () => props?.branch?.organization?.name || 'Organization',
    [props],
  );

  return (
    <div className={classNames('contact-card', className)}>
      <div className="contact-content">
        <div className="contact-name">
          {firstName} {lastName}
        </div>
        <div className="contact-company">{company ?? 'Organization'}</div>
        <WhatsAppLink phone={phone} className="contact-phone" />
        <EmailLink email={email} className="contact-email" />
      </div>
      <Link to={`/contact-branch/${branch?.id}`}>
        <SectionButton
          className="branch-button"
          label={branchLabel}
          isFilled={false}
        />
      </Link>
    </div>
  );
};

export default ContactCard;
