import React from 'react';
import './ActionOrderButton.scss';

interface PropTypes {
  label: string;
  icon: string;
  handleOpenTab?: () => void;
}

const ActionOrderButton = (props: PropTypes) => {
  const { label, icon, handleOpenTab } = props;

  return (
    <button className="action-order-button" onClick={handleOpenTab}>
      {label}
      <img src={icon} alt="icon" />
    </button>
  );
};

export default ActionOrderButton;
