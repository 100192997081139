import React from 'react';
import './ContractSummary.scss';
import edit from '../../../assets/icons/orderPage/edit.svg';
import SummaryBrochure from '../../../entities/Order/SummaryBrochure/SummaryBrochure';

interface PropTypes {
  contractType: string;
  maxSalary: number;
  housing: boolean;
  onEditClick: () => void;
}

const ContractSummary = (props: PropTypes) => {
  const { contractType, maxSalary, housing, onEditClick } = props;
  return (
    <div className="contract-summary">
      <SummaryBrochure
        contractType={contractType}
        maxSalary={maxSalary}
        housing={housing}
      />
      <div className="summary-edit" onClick={() => onEditClick()}>
        <img src={edit} alt="edit" />
      </div>
    </div>
  );
};

export default ContractSummary;
