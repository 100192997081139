import React, { useCallback, useState } from 'react';
import {
  useCreateContactMutation,
  useDeleteContactMutation,
  useGetContactsByBranchIdQuery,
  useEditContactMutation,
} from '../../../../../entities/Contact/api/contactApi';
import { AddContactForm, Form } from './AddContactForm/AddContactForm';
import ContactCard from '../../../ContactCard/ContactCard';
import { Pagination } from '@mui/material';
import { IContactUpdate } from '../../../../../shared/components/Modal/ContactModalUpdate/ContactModalUpdate';

export type ContactsProps = {
  branchId: number;
};

const TAKE_CONTACTS = 5;

export const Contacts = ({ branchId }: ContactsProps) => {
  const [page, setPage] = useState(1);
  const {
    data: contacts,
    refetch,
    isLoading,
    isSuccess,
  } = useGetContactsByBranchIdQuery({
    id: branchId,
    take: TAKE_CONTACTS,
    page,
  });

  const [deleteContactById] = useDeleteContactMutation();
  const [editContact] = useEditContactMutation();
  const [createContact] = useCreateContactMutation();

  const deleteContact = useCallback(
    (id: number) => async () => {
      await deleteContactById(id);
      refetch();
    },
    [deleteContactById],
  );
  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) =>
    setPage(page);
  const handleAddContact = useCallback(
    async (data: Form) => {
      try {
        await createContact({
          ...data,
          branch: branchId,
        });
        return true;
      } catch (err) {
        return false;
      }
    },
    [createContact],
  );

  const handleEditContact = useCallback(
    async (contact: IContactUpdate) => {
      await editContact(contact);
    },
    [createContact],
  );

  return (
    <div className="branch-contact">
      <div className="branch-contact-title">Contacts</div>
      {isLoading && <div>Loading</div>}
      {isSuccess && (
        <>
          {contacts.data.map((contact) => (
            <ContactCard
              key={contact.id}
              contact={contact}
              onEditContact={handleEditContact}
              deleteContact={deleteContact(contact.id)}
            />
          ))}
          <Pagination
            page={page}
            onChange={handlePageChange}
            count={contacts.meta.pageCount}
            hidden={contacts.meta.pageCount <= 1}
          />
        </>
      )}

      <AddContactForm onSubmit={handleAddContact} />
    </div>
  );
};
