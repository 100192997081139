import React from 'react';
import FilterTabButton from '../../../shared/components/Button/FilterTabButton/FilterTabButton';
import './GraphFilters.scss';

interface PropTypes {
  filters: string[];
  onSetSelected: (f: string) => void;
  selected: string;
}

const GraphFilters = ({ filters, onSetSelected, selected }: PropTypes) => {
  return (
    <div className="graph-filters">
      {filters.map((filter) => (
        <FilterTabButton
          key={filter}
          title={filter}
          onClick={() => onSetSelected(filter)}
          isActive={filter === selected}
        />
      ))}
    </div>
  );
};

export default GraphFilters;
