import React from 'react';
import './OrderRadioInput.scss';

interface PropTypes {
  value: string;
  name: string;
  handleCollectValue: (value: string) => void;
  isChecked?: boolean;
}

const OrderRadioInput = (props: PropTypes) => {
  const { value, name, handleCollectValue, isChecked } = props;

  const handleRadioChange = () => {
    handleCollectValue && handleCollectValue(value);
  };

  return (
    <div className="order-radio-input">
      <label>
        <input
          type="radio"
          value={value}
          name={name}
          onChange={handleRadioChange}
          defaultChecked={isChecked}
        />
        {value}
      </label>
    </div>
  );
};

export default OrderRadioInput;
