import React from 'react';
import './OrdersFilterBlock.scss';
import OrdersFilter from './OrdersFilter/OrdersFilter';
import { IOrderFilters } from '../../../entities/Order/order.models';
import SectionButton from '../../../shared/components/Button/SectionButton/SectionButton';

interface PropTypes {
  searchQuery: IOrderFilters;
  handleInputChange: (value: string, category: string) => void;
  onCloseFilters: () => void;
}

const OrdersFilterBlock = (props: PropTypes) => {
  const { searchQuery, handleInputChange, onCloseFilters } = props;
  return (
    <div className="orders-filter-block">
      <OrdersFilter
        label="Status"
        placeholder="Search status"
        searchQuery={searchQuery}
        handleInputChange={handleInputChange}
      />
      <OrdersFilter
        label="Date"
        searchQuery={searchQuery}
        handleInputChange={handleInputChange}
      />
      <OrdersFilter
        label="Organization"
        placeholder="Search organization"
        searchQuery={searchQuery}
        handleInputChange={handleInputChange}
      />
      <SectionButton label="Apply filters" onClick={onCloseFilters} />
    </div>
  );
};

export default OrdersFilterBlock;
