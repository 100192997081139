import React, { useState } from 'react';
import './OrgCards.scss';
import OrgCard from '../../entities/Organization/OrgCard/OrgCard';
import SectionButton from '../../shared/components/Button/SectionButton/SectionButton';
import OrgFilters from '../../features/Filters/OrgFilters/OrgFilters';
import { useGetOrganizationsQuery } from '../../entities/Organization/api/organizationApi';
import { IOrganization } from '../../entities/Organization/org.models';
import { Pagination } from '@mui/material';
import _ from 'lodash';
const TAKE_ORGANIZATIONS = 10;

const OrgCards = () => {
  const [page, setPage] = useState(1);
  const [skip, setSkip] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const { data: orgData, isLoading } = useGetOrganizationsQuery(
    {
      relations: ['branches.contacts.orders'],
      page,
      take: TAKE_ORGANIZATIONS,
      searchByName: searchQuery,
    },
    {
      skip,
    },
  );

  const handleInputChange = (value: string): void => {
    // Skip for debouncing endpoint call
    setSkip(true);
    setSearchQuery(value);
    _.debounce(() => {
      setSkip(false);
    }, 500)();
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  return (
    <div className="org-cards-container">
      <div className="org-cards-header">
        <OrgFilters
          searchQuery={searchQuery}
          handleInputChange={handleInputChange}
          placeholder="Search organization"
        />
        <SectionButton
          label="Add Organization"
          isFilled={true}
          path="/register-org"
        />
      </div>
      <div className="org-cards-body">
        {!isLoading &&
          orgData?.data?.map((card: IOrganization, index: number) => (
            <OrgCard
              key={index}
              className={index % 2 === 0 ? 'blue-card' : 'white-card'}
              {...card}
            />
          ))}

        <Pagination
          hidden={orgData?.meta.pageCount === 1 || isLoading}
          count={orgData?.meta.pageCount}
          page={page}
          onChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default OrgCards;
