import React from 'react';
import './BranchInput.scss';

interface PropTypes {
  label?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
}

const StatusInput = (props: PropTypes) => {
  const { label, placeholder, onChange, value } = props;

  return (
    <div className="status_enum">
      <select onChange={(e) => onChange && onChange(e)} value={value || ''}>
        <option value="" disabled hidden>
          {placeholder}
        </option>
        <option value="super">super</option>
        <option value="ordinary">ordinary</option>
        <option value="accountant">accountant</option>
      </select>
      <label>{label}</label>
    </div>
  );
};

export default StatusInput;
