import React from 'react';
import order from '../../assets/icons/orderPage/order.svg';
import OrderTitle from '../../shared/components/Title/OrderTitle/OrderTitle';
import './CreateOrder.scss';
import CreateOrderForm from '../../widgets/CreateOrderForm/CreateOrderForm';
import TemplatePage from '../TemplatePage/TemplatePage';

const CreateOrder = () => {
  return (
    <div className="create-order">
      <TemplatePage title="Orders">
        <OrderTitle icon={order} title="Create Order" />
        <CreateOrderForm />
      </TemplatePage>
    </div>
  );
};

export default CreateOrder;
