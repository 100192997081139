import React from 'react';
import './OrderInput.scss';
import classNames from 'classnames';

interface PropTypes {
  label?: string;
  icon?: string;
  innerIcon?: string;
  placeholder?: string;
  value?: string;
  error?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleCollectValue?: (value: string) => void;
  name?: string;
}

const OrderInput = React.forwardRef<HTMLInputElement, PropTypes>(
  (props, ref) => {
    const {
      label,
      icon,
      innerIcon,
      placeholder,
      value,
      error,
      onChange,
      handleCollectValue,
    } = props;

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(e);
      handleCollectValue && handleCollectValue(e.target.value);
    };

    return (
      <div className="order-input-container">
        <div className="order-control">
          {label && <div className="order-input-label">{label}</div>}
          {icon && <img className="order-icon" src={icon} alt="icon" />}
          {innerIcon && (
            <img
              className="order-inner-icon"
              src={innerIcon}
              alt="inner icon"
            />
          )}
          <input
            className={classNames('order-input', { 'inner-input': innerIcon })}
            placeholder={placeholder}
            onChange={handleInputChange}
            value={value}
            ref={ref}
          />
        </div>
        {error && <div className="error-message">{error}</div>}
      </div>
    );
  },
);

OrderInput.displayName = 'order-input';

export default OrderInput;
