import React, { useCallback } from 'react';
import './NavLink.scss';
import { NavLink } from 'react-router-dom';

interface PropTypes {
  icon: string;
  label: string;
  path: string;
  hoverIcon?: string;
  onClick?: () => void;
}

const NavLinkCustom = ({
  icon,
  hoverIcon,
  label,
  path,
  onClick,
}: PropTypes) => {
  const clickHandler = useCallback(() => {
    onClick?.();
  }, [onClick]);

  return (
    <NavLink onClick={clickHandler} to={path} className={`nav-link`}>
      <div className="nav-icon">
        <img className="normal-icon" src={icon} alt="nav-icon" />
        {hoverIcon && (
          <img className="hover-icon" src={hoverIcon} alt="hover-icon" />
        )}
      </div>
      <div className="nav-label">{label}</div>
    </NavLink>
  );
};

export default NavLinkCustom;
