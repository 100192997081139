import React from 'react';
import NavLinkCustom from '../../../../shared/components/Link/NavLink/NavLink';
import dashboardIcon from '../../../../assets/icons/navLinks/dashboard.svg';
import ordersIcon from '../../../../assets/icons/navLinks/orders.svg';
import orgsIcon from '../../../../assets/icons/navLinks/organizations.svg';
import customerCareIcon from '../../../../assets/icons/navLinks/customer-care.svg';
import contactsIcon from '../../../../assets/icons/navLinks/contacts.svg';
import orgsHoverIcon from '../../../../assets/icons/navLinks/org-solid.svg';
import ordersHoverIcon from '../../../../assets/icons/navLinks/order-solid.svg';
import contactsHoverIcon from '../../../../assets/icons/navLinks/contact-solid.svg';
import './NavMenu.scss';

const BodyNavigation = () => {
  return (
    <nav className="nav-menu">
      <NavLinkCustom
        icon={dashboardIcon}
        label="My Dashboard"
        path="/dashboard"
      />
      <NavLinkCustom
        icon={ordersIcon}
        hoverIcon={ordersHoverIcon}
        label="Orders"
        path="/orders"
      />
      <NavLinkCustom
        icon={contactsIcon}
        hoverIcon={contactsHoverIcon}
        label="Contacts"
        path="/contacts"
      />
      <NavLinkCustom
        icon={orgsIcon}
        hoverIcon={orgsHoverIcon}
        label="Organizations"
        path="/organizations"
      />
      <NavLinkCustom
        icon={customerCareIcon}
        label="Customer Care"
        path="/customer-care"
      />
      <NavLinkCustom icon={orgsIcon} label="Products prices" path="/sku" />
    </nav>
  );
};

export default BodyNavigation;
