import React from 'react';
import './OrderProdList.scss';
import OrderProductCard from '../../../entities/Order/OrderProductCard/OrderProductCard';
import {
  IOrderCard,
  IOrderContract,
} from '../../../entities/Order/order.models';
import { mapProductNameToIcon } from '../../../pages/CreateOrder/components/SelectProduct/components/ProductCard/helper';

interface PropTypes {
  orderProductCards: IOrderCard[];
  contactName?: string;
  handleOpenScheduleModal: (id: number, index: number) => void;
  handleOpenContactModal: (id: number) => void;
  handleContractData: (id: number, data: IOrderContract) => void;
  handleQuantityChange?: (id: number, quantity: number) => void;
  handleDeleteProductCard?: (id: number) => void;
}

const OrderProdList = (props: PropTypes) => {
  const {
    orderProductCards,
    handleOpenScheduleModal,
    handleOpenContactModal,
    handleContractData,
    handleQuantityChange,
    handleDeleteProductCard,
  } = props;

  return (
    <div className="order-prod-list">
      {orderProductCards?.map((card) => {
        const productCard = orderProductCards?.find(
          (productCard) => productCard.id === card.id,
        );
        const prodContract =
          productCard?.contracts &&
          productCard.contracts!.length > 0 &&
          (productCard.contracts![0] as IOrderContract);
        return (
          <OrderProductCard
            key={card.id}
            id={card.id}
            icon={mapProductNameToIcon[card.product]}
            position={card.product}
            grade={card.grade}
            price={card.price * card.quantity}
            priceSingle={card.price}
            quantity={card.quantity}
            contactName={productCard?.contact}
            schedules={productCard?.schedules}
            contract={prodContract || undefined}
            ordInv={card.ordInv}
            femalesPerc={card.femalesPerc}
            localsPerc={card.localsPerc}
            handleContractData={handleContractData}
            handleOpenScheduleModal={(id: number, index: number) =>
              handleOpenScheduleModal(card.id || 0, index)
            }
            handleOpenContactModal={() => handleOpenContactModal(card.id || 0)}
            handleQuantityChange={handleQuantityChange}
            handleDeleteProductCard={handleDeleteProductCard}
          />
        );
      })}
    </div>
  );
};

export default OrderProdList;
