import React, { useCallback } from 'react';
import './NavFooter.scss';
import NavLink from '../../../../shared/components/Link/NavLink/NavLink';
import Logout from '../../../../assets/icons/logout.svg';
import { signOut } from 'firebase/auth';
import { auth } from '../../../../services/firebase';

const FooterNavigation = () => {
  const onClickLogout = useCallback(() => {
    signOut(auth).then(() => {
      // Sign-out successful.
      localStorage.removeItem('token');
    });
  }, []);
  return (
    <div className="nav-footer">
      <NavLink onClick={onClickLogout} icon={Logout} label="Log out" path="/" />
    </div>
  );
};

export default FooterNavigation;
