import React, { useCallback, useRef, useState } from 'react';
import './OrderCards.scss';
import OrderCard from '../../../entities/Order/OrderCard/OrderCard';
import redDot from '../../../assets/icons/red-dot.png';
import FiltersBlockButton from '../../../shared/components/Button/FiltersBlockButton/FiltersBlockButton';
import OrdersFilterBlock from '../../../features/Filters/OrdersFilterBlock/OrdersFilterBlock';
import { useClickOutside } from '../../../services/customHooks';
import { useGetOrdersWithSkuRequestsContactBranchOrganizationPaginationQuery } from '../../../entities/Order/api/orderApi';
import { IOrderFilters } from '../../../entities/Order/order.models';
import BasicOrderButton from '../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import { Link } from 'react-router-dom';
import { Pagination } from '@mui/material';

const OrderCards = () => {
  const [page, setPage] = useState(1);
  const {
    data: ordersData,
    isLoading,
    isSuccess,
  } = useGetOrdersWithSkuRequestsContactBranchOrganizationPaginationQuery({
    take: 10,
    page,
    sort: 'id:desc',
    organizationName: undefined,
  });

  const [isFilterBlockVisible, setFilterBlockVisibility] = useState(false);
  const [searchQuery, setSearchQuery] = useState<IOrderFilters>({
    status: '',
    date: '',
    organization: '',
  });

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  const handleInputChange = (value: string, category: string) => {
    setSearchQuery((prevSearchQuery: any) => {
      const updatedSearchQuery = {
        ...prevSearchQuery,
        [category]: value,
      };
      return updatedSearchQuery;
    });
  };

  const filterBlockRef = useRef(null);

  useClickOutside(filterBlockRef, () => {
    setFilterBlockVisibility(false);
  });

  const handleToggleFilters = useCallback(() => {
    setFilterBlockVisibility(!isFilterBlockVisible);
  }, [isFilterBlockVisible]);

  return (
    <div className="order-cards-container">
      <div className="order-cards-header" ref={filterBlockRef}>
        <div className="header-filters">
          <FiltersBlockButton onClick={handleToggleFilters} />
          {isFilterBlockVisible && (
            <OrdersFilterBlock
              handleInputChange={handleInputChange}
              searchQuery={searchQuery}
              onCloseFilters={handleToggleFilters}
            />
          )}
        </div>
        <Link to="/create-order">
          <BasicOrderButton label="Create Order" />
        </Link>
      </div>
      <div className="order-cards-body">
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <>
            {isSuccess && (
              <>
                {ordersData.data.map((card, index: number) => (
                  <OrderCard
                    key={card.id}
                    className={index % 2 === 0 ? 'blue-card' : 'white-card'}
                    id={card.id}
                    createdAt={card.createdAt}
                    orderStatus={card.orderStatus}
                    price={card.price}
                    skuRequests={card.skuRequests}
                    selectedContact={
                      card.contact.firstName + ' ' + card.contact.lastName
                    }
                    selectedBranch={card.contact?.branch?.name || ''}
                    selectedOrg={
                      card.contact?.branch?.organization?.name || ''
                    }>
                    {card.needsInteraction && !isFilterBlockVisible && (
                      <img src={redDot} className="red-dot" alt="highlight" />
                    )}
                  </OrderCard>
                ))}
                <Pagination
                  hidden={ordersData.meta.pageCount <= 1}
                  page={page}
                  onChange={handlePageChange}
                  count={ordersData.meta.pageCount}
                />
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default OrderCards;
