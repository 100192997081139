import React from 'react';
import './ModalNewBranch.scss';
import * as yup from 'yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CitiesTypes,
  CountryTypes,
  DistrictTypes,
  IBranch,
  LocationTypes,
  WorkplaceTypes,
} from '../../entities/Branch/branch.models';
import Modal from '../../shared/components/Modal/Modal';
import FormInput from '../../shared/components/Input/FormInput/FormInput';
import SectionButton from '../../shared/components/Button/SectionButton/SectionButton';
import { useUpdateBranchMutation } from '../../entities/Branch/api/branchApi';
interface PropTypes {
  onClose: () => void;
  onSubmit: () => void;
  branchData: IBranch;
}

const yupSchema = yup.object({
  name: yup.string().required('Branch name is required'),
  city: yup.mixed<CitiesTypes>().required('Required field'),
  country: yup.mixed<CountryTypes>().required('Required field'),
  district: yup.mixed<DistrictTypes>().required('Required field'),
  locationType: yup.mixed<LocationTypes>().required('Required field'),
  workplaceType: yup.mixed<WorkplaceTypes>().required('Required field'),
  isPrimary: yup.mixed<boolean>().required('Required field'),
});

type YupSchemaType = yup.InferType<typeof yupSchema>;

const ModalUpdateBranch = (props: PropTypes) => {
  const { onClose, branchData, onSubmit } = props;
  const { name, city, isPrimary, workplaceType, locationType, id } = branchData;
  const [updateBranch] = useUpdateBranchMutation();

  const citiesArray = Object.values(CitiesTypes);
  const countriesArray = Object.values(CountryTypes);
  const districtsArray = Object.values(DistrictTypes);
  const locationsArray = Object.values(LocationTypes);
  const workplaceArray = Object.values(WorkplaceTypes);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<YupSchemaType>({
    resolver: yupResolver(yupSchema),
    defaultValues: {
      name,
      city,
      // TODO
      // country,
      // district,
      locationType,
      workplaceType,
      isPrimary,
    },
  });

  const onSubmitHandler: SubmitHandler<YupSchemaType> = async (data) => {
    updateBranch({
      id,
      name: data.name,
      country: data.country,
      district: data.district,
      locationType: data.locationType,
      city: data.city,
      workplaceType: data.workplaceType,
      isPrimary: data.isPrimary,
    })
      .unwrap()
      .then(() => {
        onSubmit();
        onClose();
      });
  };

  return (
    <Modal onClose={onClose}>
      <form
        className="modal-new-branch"
        onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="modal-inputs">
          <div className="modal-branch-inputs">
            <div className="modal-branch-label">Branch data</div>
            <FormInput
              label="Branch name"
              placeholder="Branch name"
              error={errors.name?.message}
              {...register('name')}
            />
            <div className="sidebar-box">
              <label className="form-label">Country</label>
              <select className="styled-select" {...register('country')}>
                {countriesArray.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="sidebar-box">
              <label className="form-label">City</label>
              <select className="styled-select" {...register('city')}>
                {citiesArray.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="sidebar-box">
              <label className="form-label">District</label>
              <select className="styled-select" {...register('district')}>
                {districtsArray.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="sidebar-box">
              <label className="form-label">Location Type</label>
              <select className="styled-select" {...register('locationType')}>
                {locationsArray.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="sidebar-box">
              <label className="form-label">Workplace Type</label>
              <select className="styled-select" {...register('workplaceType')}>
                {workplaceArray.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            <div className="sidebar-box">
              <label className="form-label">Status</label>
              <Controller
                control={control}
                name={'isPrimary'}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <select
                    onChange={(e) =>
                      onChange(e.target.value === 'true' ? true : false)
                    }
                    value={String(value)}
                    onBlur={onBlur}
                    className="styled-select"
                    ref={ref}>
                    <option value={'true'}>Primary</option>
                    <option value={'false'}>Not primary</option>
                  </select>
                )}></Controller>
            </div>
          </div>
        </div>
        <SectionButton label="Save" isFilled={true} />
      </form>
    </Modal>
  );
};

export default ModalUpdateBranch;
