import React, { useState } from 'react';
import CustomerCard from '../../entities/Customer/CustomerCard/CustomerCard';
import './CustomerCards.scss';
import FilterTabButton from '../../shared/components/Button/FilterTabButton/FilterTabButton';
import CustomerFilters from '../../features/Filters/CustomerFilters/CustomerFilters';
import { useGetOrdersQuery } from '../../entities/Order/api/orderApi';
import { IOrder } from '../../entities/Order/order.models';

const filters = {
  needsHandling: 'Needs Handling',
  handled: 'Handled',
  all: 'All',
};

const CustomerCards = () => {
  const [statusFilter, setStatusFilter] = useState('Needs Handling');
  const [externalFilter, setExternalFilter] = useState({
    organization: '',
    location: '',
  });
  const { organization: organizationFilter, location: locationFilter } =
    externalFilter;
  const { data: ordersDataRaw } = useGetOrdersQuery({
    relations: ['contact'],
    sortField: 'createdAt',
    sortOrder: 'desc',
    take: 25,
  });
  const ordersData = ordersDataRaw?.data;

  const sortedCardsData = [...(ordersData || [])].sort(
    (a: IOrder, b: IOrder) => {
      const statusA = a.needsInteraction ? 1 : 0;
      const statusB = b.needsInteraction ? 1 : 0;

      return statusB - statusA;
    },
  );

  const handleFilterChange = (newFilter: string) => {
    setStatusFilter(newFilter);
  };

  const handleExternalFiltersChange = (
    organization: string,
    location: string,
  ) => {
    setExternalFilter({ organization, location });
  };

  const filterCards = (needsInteraction: boolean) => {
    if (statusFilter === filters.all) {
      return true;
    }
    const status = needsInteraction ? 'Needs Handling' : 'Handled';
    return status === statusFilter;
  };

  const filterByExternal = (card: any) => {
    const organizationMatches =
      !organizationFilter || card.organization === organizationFilter;
    const locationMatches = !locationFilter || card.location === locationFilter;
    return organizationMatches && locationMatches;
  };

  return (
    <div className="customer-cards-container">
      <div className="customer-filters-section">
        <div className="customer-filters-status">
          {Object.values(filters).map((filter) => (
            <FilterTabButton
              key={filter}
              title={filter}
              onClick={() => handleFilterChange(filter)}
              isActive={statusFilter === filter}
            />
          ))}
        </div>
        <CustomerFilters onFilterChange={handleExternalFiltersChange} />
      </div>
      <div className="customer-cards">
        {sortedCardsData
          .filter(
            (card) =>
              filterCards(card.needsInteraction) && filterByExternal(card),
          )
          .map((card, index) => (
            <CustomerCard
              key={card.id}
              className={index % 2 === 0 ? 'blue-card' : 'white-card'}
              {...card}
              contact={card.contact!}
              needsInteraction={card.needsInteraction}
              rating={card.rating}
            />
          ))}
      </div>
    </div>
  );
};

export default CustomerCards;
