import React from 'react';
import './Transactions.scss';
import { useGetPaymentsQuery } from '../api/paymentApi';
import TransactionCard, {
  IPropsPayment,
} from '../TransactionCard/TransactionCard';
import { useLocation } from 'react-router-dom';
const TransactionCards = () => {
  const location = useLocation();
  const { orgId } = location.state || {};
  const { data } = useGetPaymentsQuery({ organizationId: orgId, take: 20 });
  const payments = data?.data || [];
  return (
    <div>
      {payments.map((card: IPropsPayment) => (
        <TransactionCard key={card.id} {...card} />
      ))}
    </div>
  );
};

export default TransactionCards;
