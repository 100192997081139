import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import IconButton from '../../../shared/components/Button/IconButton/IconButton';
import googleIcon from '../../../assets/icons/google-login.svg';
import './LoginForm.scss';
import FormWrapper from '../FormWrapper/FormWrapper';
import { provider } from '../../../services/firebase';
import {
  getAuth,
  signInWithPopup,
  GoogleAuthProvider,
  User,
} from 'firebase/auth';
import { useTriggerAdminMutation } from '../../../entities/Admin/api/adminApi';

const auth = getAuth();

type LoginFormProps = {
  user: User | null;
};

const LoginForm = ({ user }: LoginFormProps) => {
  const navigate = useNavigate();
  const [triggerAdmin] = useTriggerAdminMutation();

  if (user) {
    return <Navigate to="/dashboard" />;
  }

  const navigateToDashboard = () => navigate('/dashboard');

  const handleSubmitForm = async () => {
    signInWithPopup(auth, provider)
      .then(async (result) => {
        const { uid, email } = result.user as any;
        await triggerAdmin({ email: email!, uid }).unwrap();
        navigateToDashboard();
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const credential = GoogleAuthProvider.credentialFromError(error);
        console.log(errorCode, errorMessage, credential);
      });
  };

  return (
    <FormWrapper pageTitle="Sign In">
      <IconButton
        icon={googleIcon}
        label="Sign in with Google"
        onClick={handleSubmitForm}
      />
    </FormWrapper>
  );
};

export default LoginForm;
