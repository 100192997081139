import { OrderStatuses } from '../../../entities/Order/order.models';

export function mapOrderStatusToTitle(status: string): string {
  let title = '';

  switch (status) {
    case OrderStatuses.PENDING:
      title = 'Order has been placed';
      break;
    case OrderStatuses.CONFIRMED:
      title = 'Order has been confirmed';
      break;
    case OrderStatuses.FULFILLED:
      title = 'Order has been delivered';
      break;
    case OrderStatuses.CANCELED:
      title = 'Order has been canceled';
      break;
    case OrderStatuses.LOW_RATING_UNHANDLED:
      title = 'Low rating, actions required';
      break;
    case OrderStatuses.LOW_RATING_HANDLED_REPEATED:
      title = 'Low rating, order repeated';
      break;
    case OrderStatuses.LOW_RATING_HANDLED_CANCELED:
      title = 'Low rating, order canceled';
      break;
    case OrderStatuses.EXPIRED_CONFIRMATION:
      title = 'Confirmation expired';
      break;
    default:
      title = 'STATUS...';
  }

  return title;
}
