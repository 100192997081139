import { FetchBaseQueryArgs } from '@reduxjs/toolkit/dist/query/fetchBaseQuery';

export const baseQueryConfig: FetchBaseQueryArgs = {
  baseUrl: 'https://crew-back-end-908a7a2b95cb.herokuapp.com',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  },
};

export interface IMetadata {
  page: number;
  pageCount: number;
  take: number;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}
