import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { User } from 'firebase/auth';

type UserSliceState = {
  user: User | null;
};

export const userAuthSlice = createSlice({
  name: 'user',
  initialState: { user: null } as UserSliceState,
  reducers: {
    setCurrentUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
  },
});

export const { setCurrentUser } = userAuthSlice.actions;

export default userAuthSlice.reducer;
