import React from 'react';
import PageTitle from '../../shared/components/Title/PageTitle/PageTitle';
import './PageHeader.scss';
import UserAvatar from '../../entities/Customer/components/UserAvatar/UserAvatar';
import { roleApi } from '../../entities/Role/api/roleApi';
import { AdminTypes } from '../../entities/Admin/admin.models';

interface PropTypes {
  title?: string;
}
const PageHeader = (props: PropTypes) => {
  const { title } = props;
  const { data } = roleApi.endpoints.getRole.useQueryState('');
  return (
    <div className="page-header">
      <PageTitle title={title} />
      {data?.role === AdminTypes.super && <UserAvatar />}
    </div>
  );
};

export default PageHeader;
