import React, { useCallback, useMemo, useState } from 'react';
import './ContactBranch.scss';
import TemplateFormPage from '../../TemplateFormPage/TemplateFormPage';
import SectionButton from '../../../shared/components/Button/SectionButton/SectionButton';
import WhatsAppLink from '../../../shared/components/Link/WhatsAppLink/WhatsAppLink';
import EmailLink from '../../../shared/components/Link/EmailLink/EmailLink';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { IContact } from '../../../entities/Contact/contact.models';
import {
  useDeleteBranchMutation,
  useGetBranchByIdQuery,
} from '../../../entities/Branch/api/branchApi';
import ModalUpdateBranch from '../../../widgets/ModalNewBranch/ModalUpdateBranch';
import { Contacts } from './components/Contacts/Contacts';

const ContactBranch = () => {
  const { branchId } = useParams<{ branchId: string }>();
  if (!branchId) {
    return null;
  }
  const {
    data: branchData,
    isSuccess,
    refetch,
  } = useGetBranchByIdQuery(branchId!);

  const [deleteBranchById] = useDeleteBranchMutation();
  const navigate = useNavigate();
  const [isModalVisible, setModalVisible] = useState(false);

  const deleteBranch = useCallback(
    (id: number, isPrimaryBranch: boolean) => async () => {
      if (isPrimaryBranch) {
        setErrorBranch(true);
        return;
      }
      try {
        await deleteBranchById(id.toString());
        navigate(-1);
      } catch (error) {
        console.error('Error deleting branch:', error);
      }
    },
    [deleteBranchById, navigate],
  );

  const primaryContact = useMemo(
    () => branchData?.contacts?.find((contact: IContact) => contact.isPrimary),
    [branchData],
  );
  const creationDate = useMemo(
    () => branchData && new Date(branchData.createdAt).toLocaleString(),
    [branchData],
  );

  const [errorBranch, setErrorBranch] = useState(false);

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const handleEditBranch = () => {
    refetch();
  };

  if (!isSuccess) return null;
  const { city, district, name, organization, workplaceType, id } = branchData;
  return (
    <TemplateFormPage>
      <div className="contact-branch">
        <div className="branch-name">{name}</div>
        <div className="contact-branch-body">
          <div className="branch-org-info">
            <div className="branch-org-name">{organization?.name}</div>
            <Link to={'/org-screen/'} state={{ orgId: organization?.id }}>
              <SectionButton label="Organization page" />
            </Link>
            <div className="branch-supervisor">
              <div className="supervisor-title">Supervisor</div>
              <div className="supervisor-name">
                {primaryContact?.firstName} {primaryContact?.lastName}
              </div>
              {primaryContact?.phone && (
                <WhatsAppLink
                  phone={primaryContact?.phone}
                  className="supervisor-phone"
                />
              )}
              {primaryContact?.email && (
                <EmailLink
                  email={primaryContact?.email}
                  className="supervisor-email"
                />
              )}
            </div>
            <div className="branch-city">
              <b>City:</b> {city}
            </div>
            <div className="branch-district">
              <b>District:</b> {district}
            </div>
            <div className="branch-workplace-type">
              <b>Workplace type:</b> {workplaceType}
            </div>
            <div className="branch-created-at">
              <b>Registered:</b> {creationDate}
            </div>
            <div className="container-button">
              <div className="button-margin">
                <SectionButton label="Update branch" onClick={toggleModal} />
              </div>
              <SectionButton
                className="delete-button"
                label="Delete branch"
                onClick={deleteBranch(
                  branchData.id,
                  branchData?.isPrimary ?? false,
                )}
              />
            </div>
            {errorBranch && (
              <div className={'err-isPrimary'}>
                Cannot delete primary <b>branch</b> ⚠️
              </div>
            )}
          </div>
          <Contacts branchId={id} />
          {isModalVisible && (
            <ModalUpdateBranch
              branchData={branchData}
              onClose={toggleModal}
              onSubmit={handleEditBranch}
            />
          )}
        </div>
      </div>
    </TemplateFormPage>
  );
};

export default ContactBranch;
