import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '../../../shared/api/api';
import { AdminTypes } from '../../Admin/admin.models';
import { RootState } from '../../../store/store';

export const roleApi = createApi({
  reducerPath: 'roleApi',
  tagTypes: ['Role'],
  baseQuery: fetchBaseQuery({
    ...baseQueryConfig,
    prepareHeaders: async (headers: Headers, { getState }) => {
      const token = await (getState() as RootState).user.user!.getIdToken();
      headers.set('Authorization', token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getRole: builder.query<{ role: AdminTypes }, string>({
      query: () => ({
        url: '/admin/type',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetRoleQuery } = roleApi;
