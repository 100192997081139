import React from 'react';
import SectionButton from '../../../shared/components/Button/SectionButton/SectionButton';
import './OrgCard.scss';
import classNames from 'classnames';
import EmailLink from '../../../shared/components/Link/EmailLink/EmailLink';
import WhatsAppLink from '../../../shared/components/Link/WhatsAppLink/WhatsAppLink';
import { Link } from 'react-router-dom';
import { IBranch } from '../../Branch/branch.models';

interface PropTypes {
  className: string;
  branches: IBranch[];
  name: string;
  balance: number;
  id?: number;
}

const OrgCard = (props: PropTypes) => {
  const { className, name, balance, branches, id } = props;

  const ordersAmount = branches
    .map((branch) => branch.contacts)
    .flat()
    .reduce((totalOrders, contact) => {
      if (contact && contact.orders) {
        totalOrders += contact.orders.length;
      }
      return totalOrders;
    }, 0);

  const primaryBranch = branches.find((branch) => branch.isPrimary);
  const primaryContact = primaryBranch?.contacts?.find(
    (contact) => contact.isPrimary,
  );

  return (
    <div className={classNames('org-card', className)}>
      <div className="org-content">
        <div className="org-name">{name}</div>
        <div className="org-stats">
          <div className="org-orders">
            <div className="org-orders-quantity">{ordersAmount}</div>
            <div className="org-orders-label">Orders Made</div>
          </div>
          <div className="org-funds">
            <div className="org-funds-quantity">SAR {balance}</div>
            <div className="org-funds-label">Available Funds</div>
          </div>
          <div className="org-contact">
            <WhatsAppLink
              phone={primaryContact?.phone ?? ''}
              className="org-phone"
            />
            <EmailLink
              email={primaryContact?.email ?? ''}
              className="org-email"
            />
          </div>
        </div>
      </div>
      <Link to={'/org-screen'} state={{ orgId: id }}>
        <SectionButton label="Show organization" isFilled={false} />
      </Link>
    </div>
  );
};

export default OrgCard;
