import { IContact, IContactCreateRequest } from '../Contact/contact.models';
import { IOrganization } from '../Organization/org.models';

export interface IBranch {
  city: CitiesTypes;
  contacts?: IContact[];
  country?: string;
  district: string;
  isPrimary?: boolean;
  locationType: LocationTypes;
  name: string;
  organization?: IOrganization;
  workplaceType: WorkplaceTypes;
  id: number;
  createdAt: string;
}

export interface IBranchCreateRequest {
  name: string;
  city: CitiesTypes;
  contacts: IContactCreateRequest[];
  country: string;
  district: string;
  locationType: LocationTypes;
  organization?: number;
  workplaceType: WorkplaceTypes;
}

export type IBranchUpdateRequest = Omit<IBranchCreateRequest, 'contacts'> & {
  id: number;
  isPrimary: boolean;
};

export type CreateBranchForm = Omit<IBranch, 'createdAt' | 'id'>;

export enum CitiesTypes {
  Jeddah = 'Jeddah',
  Makkah = 'Makkah',
  Madinah = 'Madinah',
  Yanbu = 'Yanbu',
  Umluj = 'Umluj',
  Alwajh = 'Alwajh',
  Neom = 'Neom',
  RedSeaProject = 'Red Sea Project',
  Amala = 'Amala',
  AlUla = 'Al-Ula',
  Riyadh = 'Riyadh',
  Buraidah = 'Buraidah',
}

export enum LocationTypes {
  Mall = 'Mall',
  StripMall = 'Strip Mall',
  Airport = 'Airport',
  TrainStation = 'Train Station',
  Independant = 'Independant',
  Hospital = 'Hospital',
  RemoteSite = 'Remote Site',
}

export enum WorkplaceTypes {
  Hotel = 'Hotel',
  Restaurant = 'Restaurant',
  Airport = 'Airport',
  Cafe = 'Cafe',
  Airplane = 'Airplane',
  Office = 'Office',
  Lounge = 'Lounge',
  CateringKitchen = 'Catering Kitchen',
  BallroomConvention = 'Ballroom & Convention',
}

export enum DistrictTypes {
  Makkah = 'Makkah',
  Madinah = 'Madinah',
  Tabuk = 'Tabuk',
  Riyadh = 'Riyadh',
}

export enum CountryTypes {
  SaudiArabia = 'Saudi Arabia',
}
