import {
  CountryTypes,
  CitiesTypes,
  DistrictTypes,
  LocationTypes,
  WorkplaceTypes,
} from '../../../entities/Branch/branch.models';

export const regOrgInputsData = {
  contactInputs: [
    {
      label: 'First name',
      name: 'firstName',
    },
    {
      label: 'Last name',
      name: 'lastName',
    },
    {
      label: 'Phone',
      name: 'phone',
    },
    {
      label: 'Email',
      name: 'email',
    },
  ],
  orgInputs: [
    {
      label: 'Organization name',
      name: 'name',
    },
    {
      label: 'Employees amount',
      name: 'employeesAmount',
    },
    {
      label: 'Commercial Registration Number',
      name: 'commercialReg',
    },
    {
      label: 'Commercial Registration Expiry Date',
      name: 'commercialRegExpiryDate',
    },
    {
      label: 'Tax Registration',
      name: 'taxReg',
    },
  ],
  branchInputs: [
    {
      label: 'Branch',
      name: 'branchName',
    },
    {
      label: 'Country',
      name: 'country',
      selectOptions: Object.values(CountryTypes),
    },
    {
      label: 'City',
      name: 'city',
      selectOptions: Object.values(CitiesTypes),
    },
    {
      label: 'District',
      name: 'district',
      selectOptions: Object.values(DistrictTypes),
    },
    {
      label: 'Location type',
      name: 'locationType',
      selectOptions: Object.values(LocationTypes),
    },
    {
      label: 'Workplace type',
      name: 'workplaceType',
      selectOptions: Object.values(WorkplaceTypes),
    },
  ],
};
