import React from 'react';
import './AdminSelect.scss';

interface PropTypes {
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const AdminSelect = (props: PropTypes) => {
  const { value, handleChange } = props;
  return (
    <select className="admin-select" value={value} onChange={handleChange}>
      <option value="super">super</option>
      <option value="ordinary">ordinary</option>
      <option value="accountant">accountant</option>
    </select>
  );
};

export default AdminSelect;
