import React from 'react';
import './ClientSummary.scss';
import user from '../../../assets/icons/orderPage/user.svg';
import OrderSubtitle from '../../../shared/components/Title/OrderSubtitle/OrderSubtitle';

interface PropTypes {
  contact: string;
  org: string;
  branch: string;
  taxReg: number;
}

const ClientSummary = (props: PropTypes) => {
  const { contact, org, branch, taxReg } = props;

  return (
    <div className="client-summary">
      <OrderSubtitle icon={user} title="Client" />
      <div className="client-item">
        <div className="client-label">Contact name</div>
        <div className="client-data">{contact}</div>
      </div>
      <div className="client-item">
        <div className="client-label">Organization</div>
        <div className="client-data">{org}</div>
      </div>
      <div className="client-item">
        <div className="client-label">Branch</div>
        <div className="client-data">{branch}</div>
      </div>
      <div className="client-item">
        <div className="client-label">Tax Registration Number</div>
        <div className="client-data">{taxReg}</div>
      </div>
    </div>
  );
};

export default ClientSummary;
