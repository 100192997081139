import React from 'react';
import './OrderTitle.scss';

interface PropTypes {
  title: string;
  icon: string;
}

const OrderTitle = (props: PropTypes) => {
  const { icon, title } = props;
  return (
    <div className="order-title-container">
      <img className="order-icon" src={icon} alt="icon" />
      <div className="order-title">{title}</div>
    </div>
  );
};

export default OrderTitle;
