import React, { useEffect, useMemo, useState } from 'react';
import './OrderProductCard.scss';
import SelectProductCard from '../SelectProductCard/SelectProductCard';
import ActionOrderButton from '../../../shared/components/Button/OrderButtons/ActionOrderButton/ActionOrderButton';
import money from '../../../assets/icons/orderPage/money-gray.svg';
import user from '../../../assets/icons/orderPage/user-gray.svg';
import calendar from '../../../assets/icons/orderPage/calendar-gray.svg';
import ContractDetails from '../../../widgets/Order/ContractDetails/ContractDetails';
import ContractSummary from '../../../widgets/Order/ContractSummary/ContractSummary';
import { IOrderContract, IOrderSchedule } from '../order.models';

interface PropTypes {
  id?: number;
  icon?: string;
  position: string;
  grade?: number;
  price: number;
  priceSingle?: number;
  quantity?: number;
  ordInv?: number;
  femalesPerc?: number;
  localsPerc?: number;
  contract?: IOrderContract;
  schedules?: IOrderSchedule[];
  contactName?: string;
  handleOpenScheduleModal?: (modalId: number, index: number) => void;
  handleOpenContactModal?: () => void;
  handleContractData?: (id: number, data: IOrderContract) => void;
  handleQuantityChange?: (id: number, quantity: number) => void;
  handleDeleteProductCard?: (id: number) => void;
}

const OrderProductCard = (props: PropTypes) => {
  const {
    id,
    icon,
    position,
    grade,
    price,
    priceSingle,
    quantity,
    contract,
    schedules,
    contactName,
    ordInv,
    femalesPerc,
    localsPerc,
    handleOpenScheduleModal,
    handleOpenContactModal,
    handleContractData,
    handleQuantityChange,
    handleDeleteProductCard,
  } = props;
  const [isContractTabOpen, setContractTabOpen] = useState(false);
  const [contractData, setContractData] = useState<IOrderContract | undefined>(
    contract,
  );

  useEffect(() => {
    handleContractData &&
      contractData &&
      handleContractData(id ?? 0, contractData);
  }, [contractData]);

  const handleData = (
    contractType: string,
    maxSalary: number,
    isHousing: boolean,
    femalesPercentage: number,
    localsPercentage: number,
  ) => {
    const newContractData = {
      contractType,
      maxSalary,
      isHousing,
      femalesPercentage,
      localsPercentage,
    };
    setContractData(newContractData);
  };

  const toggleContractButtonClick = () => {
    setContractTabOpen(!isContractTabOpen);
  };

  const handleEditContract = () => {
    toggleContractButtonClick();
  };

  const splitSchedules = (quantity: number) => {
    const result = [];
    while (quantity > 0) {
      if (quantity >= 12) {
        result.push(12);
        quantity -= 12;
      } else {
        result.push(quantity);
        quantity = 0;
      }
    }
    return result;
  };
  const scheduleItems = splitSchedules(quantity || 0);

  const scheduleSingleLabel = useMemo(() => {
    if (schedules?.length === 1) {
      const singleSchedule = schedules[0];

      if (singleSchedule.endTime) {
        return `${singleSchedule.date} - ${singleSchedule.startTime} - ${singleSchedule.endTime}`;
      } else {
        return singleSchedule.startTime.toLocaleString();
      }
    }
    return 'Add Schedule';
  }, [schedules]);

  const scheduleLabels = useMemo(() => {
    if (!schedules || schedules.length === 0) {
      return Array.from({ length: scheduleItems.length }, () => 'Add Schedule');
    }
    return schedules.map((schedule, index) => {
      if (schedule.date !== '') {
        const schedule = schedules[index];
        if (!schedule.endTime) {
          return `${schedule.startTime.toLocaleString()}`;
        }
        return `${schedule.date} - ${schedule.startTime}-${schedule.endTime}`;
      } else {
        return 'Add Schedule';
      }
    });
  }, [schedules]);

  const contactLabel = contactName ? contactName : 'Add Contact';
  return (
    <div className="order-product-card">
      <div className="order-card-summary">
        <SelectProductCard
          id={id}
          icon={icon}
          position={position}
          grade={grade}
          price={price}
          priceSingle={priceSingle}
          cardQuantity={quantity}
          onQuantityChange={handleQuantityChange}
          onDeleteProductCard={handleDeleteProductCard}
          isSummary={true}
          ordInv={ordInv}
          femalesPerc={femalesPerc}
          localsPerc={localsPerc}
        />
      </div>
      <div className="hr"></div>
      <div className="order-card-actions">
        <div className="action-contract">
          {isContractTabOpen && (
            <ContractDetails
              handleCloseTab={toggleContractButtonClick}
              handleData={handleData}
              orderListContract={contract}
              femalesPerc={femalesPerc}
              localsPerc={localsPerc}
              ordInv={ordInv}
              prevContractData={contractData}
            />
          )}
          {!isContractTabOpen &&
            contractData?.contractType &&
            contractData?.maxSalary && (
              <ContractSummary
                contractType={contractData.contractType}
                maxSalary={contractData.maxSalary}
                housing={contractData.isHousing || false}
                onEditClick={handleEditContract}
              />
            )}
          {!isContractTabOpen && !contractData && (
            <div className="action">
              <div className="action-label">Setup the contract</div>
              <ActionOrderButton
                label="Contract"
                icon={money}
                handleOpenTab={toggleContractButtonClick}
              />
            </div>
          )}
        </div>
        {quantity && quantity > 12 ? (
          <div className="actions-schedule">
            <div className="hr"></div>
            <div className="action-schedule-alert">
              Quantity reach the limit a day, schedule will be separated
            </div>
            {scheduleItems.map((scheduleItem, index) => (
              <div className="action-schedule action" key={index}>
                <div className="action-label">Schedule #{index + 1}</div>
                <div className="action-right">
                  <div className="action-qty">
                    <div className="action-qty-label">Qty</div>
                    <div className="action-qty-value">{scheduleItem}</div>
                  </div>
                  <ActionOrderButton
                    label={
                      scheduleLabels && scheduleLabels.length > 0
                        ? scheduleLabels[index]
                        : 'Add Schedule'
                    }
                    icon={calendar}
                    handleOpenTab={() =>
                      handleOpenScheduleModal &&
                      handleOpenScheduleModal(id || 0, index)
                    }
                  />
                </div>
              </div>
            ))}
            <div className="hr"></div>
          </div>
        ) : (
          <div className="action-schedule action">
            <div className="action-label">Schedule delivery</div>
            <ActionOrderButton
              label={scheduleSingleLabel || 'Add Schedule'}
              icon={calendar}
              handleOpenTab={() =>
                handleOpenScheduleModal && handleOpenScheduleModal(id || 0, 0)
              }
            />
          </div>
        )}
        <div className="action-add-contact action">
          <div className="action-label">Contact</div>
          <ActionOrderButton
            label={contactLabel}
            icon={user}
            handleOpenTab={handleOpenContactModal}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderProductCard;
