export const sharedStyles = {
  '.MuiLineElement-root': {
    stroke: '#141A7F',
  },
  '.MuiAreaElement-root': {
    fill: '#F5F2FC',
    stroke: 'none',
    marginTop: '25px',
  },
  '.MuiChartsAxis-tickLabel': {
    fontFamily: 'IBM Plex Sans',
    fontSize: '12px',
    fill: '#475467 !important',
  },
  '.MuiChartsAxis-line': {
    stroke: 'none !important',
  },
  '.MuiChartsAxis-tick': {
    stroke: 'none !important',
  },
  '.MuiHighlightElement-root': {
    fill: '#141A7F',
  },
  '.MuiChartsAxis-bottom': {
    transform: 'translateY(206px)',
  },
  '.MuiChartsXAxis-bottom .MuiChartsXAxis-label': {
    marginLeft: '24px !important',
  },
};
