import React, { useCallback, useMemo, useState } from 'react';
import './ProductCard.scss';
// import plus from '../../../../assets/icons/orderPage/plus.svg';
// import minus from '../../../../assets/icons/orderPage/minus.svg';
import check from '../../../../../../assets/icons/orderPage/check.svg';
import clear from '../../../../../../assets/icons/orderPage/clear.svg';
import star from '../../../../../../assets/icons/orderPage/star.svg';
import OrderSubtitle from '../../../../../../shared/components/Title/OrderSubtitle/OrderSubtitle';
import { defaultIcon, mapProductNameToIcon } from './helper';
import Modal from '../../../../../../shared/components/Modal/Modal';
import { useForm } from 'react-hook-form';
import {
  ISkuResponse,
  ITag,
} from '../../../../../../entities/SKU/SKUModel.models';
import SectionButton from '../../../../../../shared/components/Button/SectionButton/SectionButton';
import OrderTags from '../../../../../../features/OrderTags/OrderTags';
import Input from '../../../../../../shared/components/Input/Input/Input';

interface PropTypes {
  id: number;
  grade: number;
  productName: string;
  pricePerUnit: number;
  qualityName: string;
  quantity?: number;
  isAdded: boolean;
  tags: ITag[];
  orderableInventory: number;
  sku: ISkuResponse;
  onSelectCard?: (cardsData: {
    id: number;
    tags: ITag[];
    quantity: number;
    sku: ISkuResponse;
  }) => void;
  onDeleteCard?: (id: number) => void;
}

type FormData = {
  quantity: number;
};
export const ORDER_DIVIDER = 15;

const ProductCard = (props: PropTypes) => {
  const {
    id,
    pricePerUnit,
    productName,
    qualityName,
    quantity = 1,
    isAdded = false,
    onDeleteCard,
    orderableInventory,
    tags,
    onSelectCard,
    sku,
  } = props;
  const [hovered, setHovered] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      quantity,
    },
  });
  const shouldShowDeleteButton = isAdded && onDeleteCard;
  const shouldShowAddToOrder = hovered && !isAdded;

  const handleButtonClick = () => {
    setIsModalOpened(true);
  };

  const onCancel = () => {
    onDeleteCard?.(id);
    reset();
  };

  const handleCloseModal = () => {
    reset();
    setIsModalOpened(false);
  };

  const icon = useMemo(
    () => mapProductNameToIcon[productName] || defaultIcon,
    [productName],
  );

  const toggleMouseOver = (hover: boolean) => () => {
    if (!isAdded) {
      setHovered(hover);
    }
  };

  const onSubmit = useCallback((data: FormData) => {
    // TODO: check thisd
    onSelectCard?.({
      quantity: data.quantity,
      // TODO: fix empty for now
      tags: [],
      id,
      sku,
    });
    handleCloseModal();
  }, []);

  return (
    <div
      className="select-product-card"
      onMouseEnter={toggleMouseOver(true)}
      onMouseLeave={toggleMouseOver(false)}>
      <div className="card-left">
        {isModalOpened && (
          <Modal onClose={handleCloseModal}>
            <div className="modal-branch-label productName">{productName}</div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-control">
                {tags.length > 0 && (
                  <>
                    <label className="form-label">Choose tag</label>
                    <OrderTags tagList={tags} handleTagData={() => {}} />
                  </>
                )}
              </div>
              <Input
                label={
                  'Quantity. Orderable inventory: ' +
                  Math.floor(orderableInventory / ORDER_DIVIDER)
                }
                type="number"
                error={errors.quantity?.message}
                {...register('quantity', {
                  required: { message: 'This field is required', value: true },
                  valueAsNumber: true,
                  max: {
                    message:
                      'Max allowed: ' +
                      Math.floor(orderableInventory / ORDER_DIVIDER),
                    value: orderableInventory / ORDER_DIVIDER,
                  },
                  min: { message: 'Must be more than 1', value: 1 },
                })}
              />
              <SectionButton
                type="submit"
                className="add-product-submit"
                label="Add Product"
              />
            </form>
          </Modal>
        )}
        {icon && <img className="card-icon" src={icon} alt="icon" />}
        <div className="card-info">
          <div className="card-info-person">
            <OrderSubtitle title={productName} />
          </div>
          <div className="card-info-details">
            <div className="card-rating">
              <img src={star} alt="*" />
              <div className="card-grade">{qualityName}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-right">
        {isAdded && <div className="card-quantity">{quantity}</div>}
        {<div className="card-price">{pricePerUnit} SAR</div>}

        {shouldShowAddToOrder && (
          <button className="btn-add-to" onClick={handleButtonClick}>
            Add to Order
          </button>
        )}

        {isAdded && (
          <button className="btn-added" onClick={onCancel}>
            Added
            <img src={check} alt="+" />
          </button>
        )}

        {shouldShowDeleteButton && (
          <button className="btn-delete" onClick={onCancel}>
            <img src={clear} alt="U+0078" />
          </button>
        )}
      </div>
    </div>
  );
};

export default ProductCard;
