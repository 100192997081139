import React from 'react';
import './ContactUpdateModal.scss';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Modal from '../Modal';
import FormInput from '../../Input/FormInput/FormInput';
import PhoneNumberInput from '../../../../widgets/PhoneNumberValidator/PhoneNumberValidator';
import SectionButton from '../../Button/SectionButton/SectionButton';
import { IContact } from '../../../../entities/Contact/contact.models';
export interface IContactUpdate {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  isPrimary: boolean;
}
interface PropTypes {
  onClose: () => void;
  onUpdateContact: (contact: IContactUpdate) => void;
  selectedContact: IContact;
}

const yupSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().phone('SA').required(),
  isPrimary: yup.string().required(),
  id: yup.number().required(),
});

type YupSchemaType = yup.InferType<typeof yupSchema>;

const ContactModalUpdate = (props: PropTypes) => {
  const { onClose, onUpdateContact, selectedContact } = props;
  const { firstName, lastName, email, phone, isPrimary, id } = selectedContact;
  const onSubmitHandler: SubmitHandler<YupSchemaType> = async (
    data,
  ): Promise<void> => {
    // data.isPrimary = data.isPrimary === 'true';
    console.log('hello');
    onUpdateContact({
      ...data,
      isPrimary: data.isPrimary === 'true' ? true : false,
    });
    onClose();
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<YupSchemaType>({
    resolver: yupResolver(yupSchema),
    defaultValues: {
      firstName,
      lastName,
      email,
      phone,
      isPrimary: String(isPrimary),
      id,
    },
  });

  return (
    <Modal onClose={onClose}>
      <form
        className="modal-new-branch"
        onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="modal-inputs">
          <div className="modal-supervisor-inputs">
            <div className="modal-supervisor-label">Update contact data</div>
            <FormInput
              label={'First name'}
              placeholder="First name"
              error={errors.firstName?.message}
              {...register('firstName')}
            />
            <FormInput
              label={'Last name'}
              placeholder="Last name"
              error={errors.lastName?.message}
              {...register('lastName')}
            />
            <FormInput
              label={'Email'}
              placeholder="email"
              error={errors.email?.message}
              {...register('email')}
            />
            <input type="hidden" {...register('id')} />
            <label className="form-label">Status</label>
            <div className="sidebar-box">
              <select className="styled-select" {...register('isPrimary')}>
                <option value={'true'}>Primary</option>
                <option value={'false'}>Not primary</option>
              </select>
            </div>
            <label className="form-label">Phone</label>
            <PhoneNumberInput
              register={register('phone')}
              defaultValue={phone}
            />
          </div>
        </div>
        <SectionButton type={'submit'} label="Save" isFilled={true} />
      </form>
    </Modal>
  );
};

export default ContactModalUpdate;
