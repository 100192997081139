import React, { useState, useCallback } from 'react';
import './OrderCalendar.scss';
import calendar from '../../../../assets/icons/orderPage/calendar.svg';
import TimeSlotPicker from '../../../../features/OrderSection/TimeSlotPicker/TimeSlotPicker';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { timeSlotsStart } from './calendarData';
import { formatDate } from './helper';

interface PropTypes {
  onSelectDate: (date: string) => void;
  onSelectStartTime: (time: string) => void;
  onSelectEndTime: (time: string) => void;
  quantity?: number;
  currSchIndex?: number;
}

const now = new Date();
const oneWeekLater = new Date();
oneWeekLater.setDate(now.getDate() + 7);

const OrderCalendar = (props: PropTypes) => {
  const {
    onSelectDate,
    onSelectStartTime,
    onSelectEndTime,
    quantity,
    currSchIndex,
  } = props;

  const [date, setDate] = useState(new Date());
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');

  const handleDateChange = useCallback((value: any) => {
    if (value instanceof Date) {
      setDate(value);

      onSelectDate(formatDate(value));
    }
  }, []);

  const handleStartTimeSelection = useCallback(
    (time: string) => {
      setStartTime(time);
      onSelectStartTime(time);
      calculateEndTime(time);
    },
    [onSelectStartTime],
  );

  const calculateEndTime = useCallback(
    (startTime: string) => {
      if (quantity) {
        const start = new Date(`2000-01-01T${startTime}`);

        const schLines = Math.ceil(quantity / 12);
        const residQty =
          currSchIndex && currSchIndex + 1 === schLines ? quantity % 12 : 12;
        const meetingDuration =
          quantity > 12 ? residQty * 60000 * 30 : quantity * 30 * 60000;

        const endTime = new Date(start.getTime() + meetingDuration);

        const hours = endTime.getHours();
        const minutes = endTime.getMinutes();

        const formattedEndTime = `${hours > 9 ? hours : '0' + hours}:${
          minutes > 9 ? minutes : '0' + minutes
        }`;

        setEndTime(formattedEndTime);
        onSelectEndTime(formattedEndTime);
      }
    },
    [onSelectEndTime],
  );

  return (
    <div className="order-calendar">
      <div className="order-calendar-date">
        <div className="calendar-subheader">
          <div className="calendar-label">
            <img src={calendar} alt="icon" />
            Select date
          </div>
        </div>
        <Calendar
          locale="en-GB"
          value={date}
          onChange={handleDateChange}
          minDate={now}
          maxDate={oneWeekLater}
        />
      </div>
      <div className="order-calendar-time">
        <div className="calendar-start-time">
          <div className="calendar-subheader">
            <div className="calendar-label">
              <img src={calendar} alt="icon" />
              Start Time
            </div>
          </div>
          <div className="calendar-timeslots">
            <TimeSlotPicker
              timeSlots={timeSlotsStart || []}
              onSelectTime={handleStartTimeSelection}
            />
          </div>
        </div>
        <div className="calendar-end-time">
          <div className="calendar-subheader">
            <div className="calendar-label">End Time</div>
            <div className="calendar-note">
              {startTime && `${startTime} - ${endTime}`}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderCalendar;
