import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '../../../shared/api/api';
import { getToken } from '../../Admin/admin.models';

const skuConfig = {
  ...baseQueryConfig,
  baseUrl: baseQueryConfig.baseUrl + '/sku-requests',
  prepareHeaders: (headers: Headers) => {
    const session = getToken();
    headers.set('Authorization', `${session.token}`);
    return headers;
  },
};

export const skuRequestsApi = createApi({
  reducerPath: 'SkuRequestsApi',
  tagTypes: ['Sku'],
  baseQuery: fetchBaseQuery(skuConfig),
  endpoints: (builder) => ({
    createSkuRequest: builder.mutation<any, any>({
      query: (data) => ({
        url: '',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Sku'],
    }),
    updateSkuRequest: builder.mutation<any, { id: number; data: any }>({
      query: ({ id, data }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Sku', id }],
    }),
    deleteSkuRequest: builder.mutation<any, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Sku'],
    }),
    getSkuRequests: builder.query<
      any,
      {
        relations?: string[];
        sku?: string;
        sortField?: string;
        sortOrder?: string;
        take?: number;
      }
    >({
      query: ({ relations = [], sku, sortField, sortOrder, take }) => {
        let url = '';
        if (relations.length > 0) {
          url += `?relations=${relations.join(',')}`;
        }
        if (sku) {
          url += `${relations.length > 0 ? '&' : '?'}sku=${sku}`;
        }
        if (sortField && sortOrder) {
          url += `${
            relations.length > 0 ? '&' : '?'
          }sort=${sortField}:${sortOrder}`;
        }
        if (take) {
          url += `${
            relations.length > 0 || (sortField && sortOrder) ? '&' : '?'
          }take=${take}`;
        }
        return { url };
      },
    }),
    getSkuRequestsById: builder.query<any, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ['Sku'],
    }),

    getSkuRequestsByIdWithRelations: builder.query<any, string>({
      query: (id) => ({
        url: `/${id}?relations=contact`,
      }),
      providesTags: ['Sku'],
    }),
  }),
});

export const {
  useCreateSkuRequestMutation,
  useUpdateSkuRequestMutation,
  useDeleteSkuRequestMutation,
  useGetSkuRequestsQuery,
  useGetSkuRequestsByIdQuery,
} = skuRequestsApi;
