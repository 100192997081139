import React from 'react';
import plus from '../../../../assets/icons/orderPage/plus.svg';
import minus from '../../../../assets/icons/orderPage/minus.svg';
import './PercentageControl.scss';

interface PropTypes {
  label: string;
  value?: number;
  onIncrement?: () => void;
  onDecrement?: () => void;
}

const PercentageControl = (props: PropTypes) => {
  const { label, value, onIncrement, onDecrement } = props;

  return (
    <div className="percentage-control">
      <span className="control-label">{label}</span>
      <div className="control-quantity">
        <img src={minus} alt="-" onClick={onDecrement} />
        {value}%
        <img src={plus} alt="+" onClick={onIncrement} />
      </div>
    </div>
  );
};

export default PercentageControl;
