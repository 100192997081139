import React, { useMemo } from 'react';
import Text from '../../../../shared/components/Text/Text';
import NumberLabel from '../../../../shared/components/Label/NumberLabel/NumberLabel';
import './ContractDynamics.scss';
import { useGetOrderChartAmountQuery } from '../../../Order/api/orderApi';
import moment from 'moment';

const endDate = moment().toISOString();
const startDate = moment().subtract(1, 'year').toISOString();

const ContractDynamics = () => {
  const { data } = useGetOrderChartAmountQuery({
    startDate,
    endDate,
    timeUnit: 'month',
  });

  const contractsLastYear = useMemo(() => {
    return data?.reduce((acc, month) => {
      return acc + month.value;
    }, 0);
  }, [data]);

  return (
    <div className="contract-dynamics">
      <Text label="Contracts last 1 year" />
      <div className="contract-dynamics_numbers">
        {contractsLastYear && <NumberLabel number={contractsLastYear} />}
      </div>
    </div>
  );
};

export default ContractDynamics;
