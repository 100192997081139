import React from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
import Dashboard from './pages/Dashboard/Dashboard';
import CustomerCare from './pages/CustomerCare/CustomerCare';
import OrgContainer from './pages/Organizations/OrgContainer';
import ContactsContainer from './pages/Contacts/ContactsContainer';
import OrdersContainer from './pages/Orders/OrdersContainer';
import LoginForm from './pages/Authorization/LoginForm/LoginForm';
import RegisterOrg from './pages/Organizations/RegisterOrg/RegisterOrg';
import ContactBranch from './pages/Contacts/ContactBranch/ContactBranch';
import OrgScreen from './pages/Organizations/OrgScreen/OrgScreen';
import Settings from './pages/UserProfile/Settings/Settings';
import OrgBalance from './pages/Organizations/OrgBalance/OrgBalance';
import SKU from './pages/SKU/SKU';
import AddTransaction from './pages/Organizations/AddTransaction/AddTransaction';
import CreateOrder from './pages/CreateOrder/CreateOrder';
import OrgEdit from './pages/Organizations/OrgEdit/OrgEdit';
import { RootState } from './store/store';
import { roleApi } from './entities/Role/api/roleApi';
import { AdminTypes } from './entities/Admin/admin.models';
import ProtectedRoute from './shared/components/ProtectedRoute/ProtectedRoute';
import SelectProduct from './pages/CreateOrder/components/SelectProduct/SelectProduct';
import ContractSchedule from './pages/CreateOrder/components/ContractSchedule/ContractSchedule';
import SummaryOrder from './pages/CreateOrder/components/SummaryOrder/SummaryOrder';
import OrderCreated from './pages/OrderCreated/OrderCreated';
import SummaryOrderClient from './pages/SummaryOrderClient/SummaryOrderClient';
import './App.scss';

function App() {
  const user = useSelector((state: RootState) => state.user.user);
  const { data: userRole } = roleApi.endpoints.getRole.useQueryState('');

  return (
    <Routes>
      <Route element={<ProtectedRoute user={user} />}>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/create-order" element={<CreateOrder />} />
        <Route path="/customer-care" element={<CustomerCare />} />
        <Route path="/organizations" element={<OrgContainer />} />
        <Route path="/contacts" element={<ContactsContainer />} />
        <Route path="/orders" element={<OrdersContainer />} />
        <Route path="/register-org" element={<RegisterOrg />} />
        <Route path="/contact-branch/:branchId" element={<ContactBranch />} />
        <Route path="/org-screen" element={<OrgScreen />} />
        {userRole?.role === AdminTypes.super && (
          <Route path="/settings" element={<Settings />} />
        )}
        {userRole &&
          [AdminTypes.super, AdminTypes.accountant].includes(
            userRole?.role,
          ) && <Route path="/org-balance" element={<OrgBalance />} />}
        <Route path="/select-product" element={<SelectProduct />} />
        <Route path="/contract-schedule" element={<ContractSchedule />} />
        <Route path="/summary-order" element={<SummaryOrder />} />
        <Route path="/order-created" element={<OrderCreated />} />
        <Route path="/sku" element={<SKU />} />
        <Route path="/add_transaction" element={<AddTransaction />} />
        <Route path="/org-screen/edit/:id" element={<OrgEdit />} />
      </Route>

      <Route path={'/orders-client/:id'} element={<SummaryOrderClient />} />
      <Route path="/" element={<LoginForm user={user} />} />
    </Routes>
  );
}

export default App;
