import React, { useCallback, useState } from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

interface PhoneNumberInputProps {
  register: UseFormRegisterReturn<any>;
  defaultValue?: string;
}

const PhoneNumberInput: React.FC<PhoneNumberInputProps> = ({
  register,
  defaultValue,
}) => {
  const [value, setValue] = useState('');
  const handleChange = useCallback((phoneNumber: string) => {
    setValue(phoneNumber);
    register.onChange({
      target: {
        value: '+' + phoneNumber,
        name: `${register.name}`,
      },
      type: 'change',
    });
  }, []);

  return (
    <>
      <PhoneInput
        country={'ua'}
        value={value || defaultValue}
        onChange={handleChange}
        inputProps={{
          required: true,
        }}
        inputStyle={{ width: '100%' }}
      />
    </>
  );
};

export default PhoneNumberInput;
