import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '../../../shared/api/api';
import {
  IDataWithPagination,
  IOrder,
  IOrderUpdateRequest,
  IOrderWithRelations,
  IOrderWithSkuRequestContactBranchOrganization,
  IOrdersWithPagination,
} from '../order.models';
import { RootState } from '../../../store/store';

export const orderApi = createApi({
  reducerPath: 'OrderApi',
  tagTypes: ['Order'],
  baseQuery: fetchBaseQuery({
    ...baseQueryConfig,
    baseUrl: baseQueryConfig.baseUrl + '/orders',
    prepareHeaders: async (headers: Headers, { getState }) => {
      const token = await (getState() as RootState).user.user!.getIdToken();
      headers.set('Authorization', token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getOrdersWithSkuRequestsContactBranchOrganizationPagination: builder.query<
      IOrdersWithPagination,
      { sort?: string; take?: number; page?: number; organizationName?: string }
    >({
      providesTags: ['Order'],
      query: ({ sort, take, page, organizationName }) =>
        `?relations=contact.branch.organization&relations=skuRequests&relations=skuRequests.contact&showSkus=true${
          (sort ? '&sort=' + sort : '') +
          (take ? '&take=' + take : '') +
          (page ? '&page=' + page : '') +
          (organizationName
            ? '&contact.branch.organization.name=' + organizationName
            : '')
        }`,
    }),
    getOrders: builder.query<
      IDataWithPagination<IOrder>,
      {
        relations?: string[];
        sortField?: string;
        sortOrder?: string;
        take?: number;
        organizationId?: number;
        showSkus?: boolean;
      }
    >({
      query: ({
        relations = [],
        sortField,
        sortOrder,
        take,
        organizationId,
        showSkus,
      }) => {
        let url = '';

        if (relations.length > 0) {
          url += `?relations=${relations.join(',')}`;
        }

        if (sortField && sortOrder) {
          url += `${
            relations.length > 0 ? '&' : '?'
          }sort=${sortField}:${sortOrder}`;
        }

        if (take) {
          url += `${
            relations.length > 0 || (sortField && sortOrder) ? '&' : '?'
          }take=${take}`;
        }

        if (organizationId) {
          url += `${
            url.includes('?') ? '&' : '?'
          }contact.branch.organization.id=${organizationId}`;
        }

        if (showSkus !== undefined) {
          url += `${url.includes('?') ? '&' : '?'}showSkus=${showSkus}`;
        }

        return { url };
      },
    }),

    getOrderByIdWithSkuRequestsContactBranchOrganization: builder.query<
      IOrderWithSkuRequestContactBranchOrganization,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/${id}?relations=skuRequests&relations=contact.branch.organization`,
      }),
      providesTags: ['Order'],
    }),

    getOrderById: builder.query<any, { id: string; relations?: string[] }>({
      query: ({ id, relations }) => ({
        url: `/${id}`,
        params: {
          relations: [relations],
        },
      }),
      providesTags: ['Order'],
    }),
    getOrderByIdWithRelations: builder.query<
      IOrderWithRelations,
      { id: string }
    >({
      query: ({ id }) => ({
        url: `/${id}?relations=contact.branch.organization&relations=skuRequests.contact`,
      }),
      providesTags: ['Order'],
    }),
    createOrder: builder.mutation<{ id: number }, any>({
      query: (data) => ({
        url: '',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Order'],
    }),
    updateOrder: builder.mutation<any, IOrderUpdateRequest>({
      query: ({ id, skuRequests }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body: { skuRequests },
      }),
      invalidatesTags: ['Order'],
    }),
    deleteOrder: builder.mutation<void, number>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Order'],
    }),
    confirmOrder: builder.mutation<void, { id?: string }>({
      query: ({ id }) => ({
        url: `/${id}/confirm`,
        method: 'GET',
      }),
    }),
    cancelOrder: builder.mutation<void, { id?: string }>({
      query: ({ id }) => ({
        url: `/${id}/cancel`,
        method: 'GET',
      }),
    }),
    getOrderChartAmount: builder.query<
      Array<{ key: string; value: number }>,
      { startDate: string; endDate: string; timeUnit: string }
    >({
      query: ({ startDate, endDate, timeUnit }) => ({
        url: '/chart/amount-from-time',
        params: {
          startDate,
          endDate,
          timeUnit,
        },
      }),
    }),
    getOrderChartRating: builder.query<
      Array<{ key: string; value: number }>,
      { startDate: string; endDate: string; timeUnit: string }
    >({
      query: ({ startDate, endDate, timeUnit }) => ({
        url: '/chart/rating-from-time',
        params: {
          startDate,
          endDate,
          timeUnit,
        },
      }),
    }),
  }),
});

export const {
  useGetOrdersQuery,
  useCreateOrderMutation,
  useDeleteOrderMutation,
  useGetOrderByIdQuery,
  useUpdateOrderMutation,
  useConfirmOrderMutation,
  useCancelOrderMutation,
  useGetOrderChartAmountQuery,
  useGetOrderChartRatingQuery,
  useGetOrderByIdWithRelationsQuery,
  useGetOrderByIdWithSkuRequestsContactBranchOrganizationQuery,
  useGetOrdersWithSkuRequestsContactBranchOrganizationPaginationQuery,
} = orderApi;
