import React from 'react';
import './RegisterOrg.scss';
import TemplateFormPage from '../../TemplateFormPage/TemplateFormPage';
import RegOrgSection from '../../../features/RegOrgSection/RegOrgSection';
import SectionButton from '../../../shared/components/Button/SectionButton/SectionButton';
import { regOrgInputsData as inputsData } from './regOrgInputs';
import * as yup from 'yup';
import 'yup-phone-lite';
import {
  FieldValues,
  SubmitErrorHandler,
  SubmitHandler,
  UseFormRegister,
  useForm,
} from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useCreateOrganizationMutation } from '../../../entities/Organization/api/organizationApi';
import {
  CitiesTypes,
  LocationTypes,
  WorkplaceTypes,
} from '../../../entities/Branch/branch.models';

const yupSchema = yup
  .object({
    firstName: yup.string().required('Required field'),
    lastName: yup.string().required('Required field'),
    phone: yup.string().required('Required field'),
    email: yup
      .string()
      .email('Invalid email format')
      .required('Required field'),
    name: yup.string().required('Required field'),
    employeesAmount: yup
      .number()
      .typeError('Number required')
      .required('Required field'),
    commercialReg: yup
      .number()
      .typeError('Number required')
      .required('Required field')
      .test(
        'is-ten-digits',
        'Number must be 10 digits',
        (value) => value.toString().length === 10,
      ),
    commercialRegExpiryDate: yup
      .date()
      .typeError('Invalid date format')
      .required('Required field'),
    taxReg: yup
      .number()
      .typeError('Number required')
      .required('Required field')
      .test(
        'is-fifteen-digits',
        'Number must be 15 digits',
        (value) => value.toString().length === 15,
      ),
    branchName: yup.string().required('Required field'),
    country: yup.string().required('Required field'),
    city: yup.string().required('Required field'),
    district: yup.string().required('Required field'),
    locationType: yup.string().required('Required field'),
    workplaceType: yup.string().required('Required field'),
  })
  .required();

type YupSchemaType = yup.InferType<typeof yupSchema>;

const RegisterOrg = () => {
  const navigate = useNavigate();
  const { contactInputs, branchInputs, orgInputs } = inputsData;
  const [createOrg] = useCreateOrganizationMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupSchema),
  });

  const onSubmitHandler: SubmitHandler<YupSchemaType> = async (orgInputs) => {
    createOrg({
      name: orgInputs.name,
      taxReg: orgInputs.taxReg,
      commercialReg: orgInputs.commercialReg,
      commercialRegExpiryDate: orgInputs.commercialRegExpiryDate,
      employeesAmount: orgInputs.employeesAmount,
      creditLimit: 0,
      branches: [
        {
          name: orgInputs.branchName,
          country: orgInputs.country,
          city: orgInputs.city as CitiesTypes,
          locationType: orgInputs.locationType as LocationTypes,
          workplaceType: orgInputs.workplaceType as WorkplaceTypes,
          district: orgInputs.district,
          contacts: [
            {
              email: orgInputs.email,
              firstName: orgInputs.firstName,
              lastName: orgInputs.lastName,
              phone: orgInputs.phone,
            },
          ],
        },
      ],
    })
      .unwrap()
      .then(() => {
        navigate('/organizations');
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onError: SubmitErrorHandler<any> = (value: any) => {
    console.log('Error', value);
  };

  return (
    <TemplateFormPage>
      <form
        className="register-org"
        onSubmit={handleSubmit(onSubmitHandler, onError)}>
        <div className="register-org-title">Register Organization</div>
        <div className="register-org-sections">
          <RegOrgSection
            category="Contact"
            inputEntries={contactInputs}
            register={register as unknown as UseFormRegister<FieldValues>}
            errors={errors}
          />
          <RegOrgSection
            category="Primary Branch"
            inputEntries={branchInputs}
            register={register as unknown as UseFormRegister<FieldValues>}
            errors={errors}
          />
          <RegOrgSection
            category="Organization"
            inputEntries={orgInputs}
            register={register as unknown as UseFormRegister<FieldValues>}
            errors={errors}
          />
        </div>
        <div className="register-org-button">
          <SectionButton label="Submit" type="submit" isFilled={true} />
        </div>
      </form>
    </TemplateFormPage>
  );
};

export default RegisterOrg;
