import React, { useCallback } from 'react';
import './SummaryOrderClient.scss';
import { useParams } from 'react-router-dom';
import { mapOrderStatusToTitle } from './utils/map.status';
import {
  useCancelOrderMutation,
  useConfirmOrderMutation,
  useGetOrderByIdWithRelationsQuery,
} from '../../entities/Order/api/orderApi';
import money from '../../assets/icons/orderPage/money.svg';
import ClientSummary from '../../widgets/Order/ClientSummary/ClientSummary';
import OrderTitle from '../../shared/components/Title/OrderTitle/OrderTitle';
import TotalQuantity from '../../widgets/Order/TotalQuantity/TotalQuantity';
import SectionButton from '../../shared/components/Button/SectionButton/SectionButton';
import OrderInformation from '../../widgets/Order/OrderInformation/OrderInformation';
import { format } from 'date-fns';
import SummaryOrderList from './components/SummaryOrderList/SummaryOrderList';
import SummaryTotal from '../../widgets/Order/SummaryTotal/SummaryTotal';

const SummaryOrderClient = () => {
  const { id } = useParams();
  if (!id) {
    return null;
  }
  const encodedId = encodeURIComponent(id);
  const { data: order } = useGetOrderByIdWithRelationsQuery({
    id: encodedId,
  });
  const [cancelService] = useCancelOrderMutation();
  const [submitService] = useConfirmOrderMutation();

  const handleConfirm = useCallback(async () => {
    submitService({ id });
  }, [submitService]);

  const handleCancel = useCallback(async () => {
    cancelService({ id });
  }, [cancelService]);
  if (!order) return <div></div>;
  const title = mapOrderStatusToTitle(order.orderStatus);

  const totalQty = order.skuRequests.reduce((sum: number, skuReq) => {
    return sum + skuReq.crewsNumber;
  }, 0);
  const createdAt = format(new Date(order!.createdAt!), 'dd.MM.yyyy HH:mm');
  return (
    <div>
      <div>
        <div className="title">
          <OrderTitle icon={money} title={title} />
        </div>
        <div className="info-block">
          <div className="info-block-left">
            <OrderInformation
              date={createdAt}
              number={`ORD-${order?.id || ''}`}
            />
            <ClientSummary
              contact={`${order.contact.firstName} ${order.contact.lastName}`}
              org={order.contact.branch?.organization?.name ?? ''}
              branch={order.contact.branch?.name ?? ''}
              taxReg={Number(order?.contact?.branch?.organization?.taxReg) ?? 0}
            />
          </div>
          <div className="summary-total-cards">
            <SummaryOrderList key={id} skuRequests={order.skuRequests} />
            <div className="total-quantity-bar">
              <TotalQuantity quantity={totalQty || 0} />
            </div>
            <div className="summary-total-container">
              <SummaryTotal subtotal={order.total} />
            </div>
            <div className="client-button">
              {order?.orderStatus === 'PENDING' && (
                <SectionButton
                  label="Confirm"
                  isFilled={false}
                  onClick={handleConfirm}
                />
              )}
              {(order?.orderStatus === 'CONFIRMED' ||
                order?.orderStatus === 'PENDING') && (
                <SectionButton
                  className="cancel-button"
                  label="Cancel"
                  isFilled={false}
                  onClick={handleCancel}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SummaryOrderClient;
