import React, { forwardRef } from 'react';
import './FormSelect.scss';

interface PropTypes {
  options: string[];
  handleChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const FormSelect = forwardRef<HTMLButtonElement, PropTypes>(
  (props: PropTypes, ref: any) => {
    const { options, handleChange } = props;
    return (
      <select className="form-select" onChange={handleChange} ref={ref}>
        {options.map((option) => (
          <option value={option} key={option}>
            {option}
          </option>
        ))}
      </select>
    );
  },
);

FormSelect.displayName = 'form-select';

export default FormSelect;
