import React, { useState } from 'react';
import './SelectProductFilters.scss';
import OrderSelect from '../../../shared/components/Select/OrderSelect/OrderSelect';
import star from '../../../assets/icons/orderPage/star.svg';
import { IFiltersTypes } from '../../../entities/Order/order.models';
import { productOptions } from './productFiltersData';

interface PropTypes {
  selectedFilters: IFiltersTypes;
  onFiltersChange: (filters: IFiltersTypes) => void;
}

const SelectProductFilters = (props: PropTypes) => {
  const { selectedFilters, onFiltersChange } = props;
  const [selectedProduct, setSelectedProduct] = useState(
    selectedFilters.product,
  );
  const [selectedGrade, setSelectedGrade] = useState(selectedFilters.grade);

  const handleProductChange = (selectedProduct: string) => {
    setSelectedProduct(selectedProduct);
    onFiltersChange({ ...selectedFilters, product: selectedProduct });
  };

  const handleGradeChange = (selectedGrade: string) => {
    setSelectedGrade(selectedGrade);
    onFiltersChange({ ...selectedFilters, grade: selectedGrade });
  };

  return (
    <div className="select-product-filters">
      <OrderSelect
        label="Product"
        placeholder="Select Product"
        options={productOptions || []}
        value={selectedProduct}
        handleChange={(e) => handleProductChange(e.target.value)}
      />
      <OrderSelect
        label="Quality Grade"
        options={['All', '5 Stars', '4 Stars', '3 Stars', '2 Stars']}
        icon={star}
        value={selectedGrade}
        handleChange={(e) => handleGradeChange(e.target.value)}
      />
    </div>
  );
};

export default SelectProductFilters;
