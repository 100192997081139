import React, { useCallback, useState } from 'react';
import './OrgBalance.scss';
import TemplateFormPage from '../../TemplateFormPage/TemplateFormPage';
import SectionButton from '../../../shared/components/Button/SectionButton/SectionButton';
import {
  useGetOrganizationByIdQuery,
  useUpdateOrganizationMutation,
} from '../../../entities/Organization/api/organizationApi';
import { Link, useLocation } from 'react-router-dom';
import { useGetInvoicesQuery } from '../../../entities/transactEntities/Invoice/api/invoiceApi';
import { IInvoice } from '../../../entities/transactEntities/Invoice/invoice.models';
import { useGetPaymentsQuery } from '../../../entities/transactEntities/Payment/api/paymentApi';
import { IPayment } from '../../../entities/transactEntities/Payment/payment.models';
import TransactionCards from '../../../entities/transactEntities/Payment/TransactionCards/TransactionsCards';

const OrgBalance = () => {
  const [activeTab, setActiveTab] = useState('invoices');
  const location = useLocation();
  const { orgId } = location.state || {};
  const [editOrganization] = useUpdateOrganizationMutation();

  const { data: orgData } = useGetOrganizationByIdQuery(orgId);
  const { data: invoiceData } = useGetInvoicesQuery(orgId);
  const { data: paymentData } = useGetPaymentsQuery(orgId);

  const creditLimit = orgData?.creditLimit;
  const handleTabClick = (tab: string) => () => {
    setActiveTab(tab);
  };
  const formatDate = (date: Date) => {
    return new Date(date).toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    });
  };

  const [isEditing, setIsEditing] = useState(false);
  const [newPrice, setNewPrice] = useState(creditLimit);

  const handleEditClick = useCallback(() => {
    setIsEditing(true);
  }, [setIsEditing]);
  const handleSaveClick = useCallback(async () => {
    await editOrganization({ id: orgId, creditLimit: newPrice });
    setIsEditing(false);
  }, [editOrganization, orgId, newPrice, setIsEditing]);

  return (
    <TemplateFormPage>
      <div className="org-balance-page">
        <div className="org-balance-header">
          <div className="org-name">{orgData?.name}</div>
          <div className="org-balance-container">
            <div className="org-balance">Balance: ${orgData?.balance} SAR</div>
            <div className="org-balance">
              Balance on hold: ${orgData?.balanceOnHold} SAR
            </div>
          </div>
        </div>
        <div className="org-balance-tabs">
          <button
            className={`org-balance-tab ${
              activeTab === 'invoices' ? 'active' : ''
            }`}
            onClick={handleTabClick('invoices')}>
            Invoices
          </button>
          <button
            className={`org-balance-tab ${
              activeTab === 'payments' ? 'active' : ''
            }`}
            onClick={handleTabClick('payments')}>
            Other payments
          </button>
          <button
            className={`org-balance-tab ${
              activeTab === 'credit-line' ? 'active' : ''
            }`}
            onClick={handleTabClick('credit-line')}>
            Credit line
          </button>
        </div>
        <div className="org-balance-content">
          {activeTab === 'invoices' && (
            <div className="org-content-invoices">
              <div className="org-trans-history">
                <div className="trans-history-title">Invoice history</div>
                <div className="trans-items">
                  {invoiceData?.length > 0 ??
                    invoiceData.map((item: IInvoice, index: number) => (
                      <div className="trans-item" key={item.id}>
                        <div className="trans-item-data">
                          <div className="trans-item-details">
                            <div className="trans-item-number">
                              Invoice #{index + 1}
                            </div>
                            <div className="trans-item-date">
                              {formatDate(item.date)}
                            </div>
                          </div>
                          <div className="trans-item-sum">${item.amount}</div>
                        </div>
                        <SectionButton label="More details ->" />
                      </div>
                    ))}
                </div>
              </div>
              <div className="org-receipt">Receipt here</div>
            </div>
          )}
          {activeTab === 'payments' && (
            <div className="org-content-payments">
              <div className="org-trans-history">
                <div className="trans-history-title">Transaction history</div>
                <div className="trans-items">
                  {paymentData?.length > 0 ??
                    paymentData.map((item: IPayment, index: number) => (
                      <div className="trans-item" key={item.id}>
                        <div className="trans-item-data">
                          <div className="trans-item-details">
                            <div className="trans-item-number">
                              {item.paymentMethod} #{index + 1}
                            </div>
                            <div className="trans-item-date">
                              {formatDate(item.date)}
                            </div>
                          </div>
                          <div className="trans-item-sum">${item.amount}</div>
                        </div>
                        <SectionButton label="More details ->" />
                      </div>
                    ))}
                  <div>
                    <Link to={'/add_transaction'} state={{ orgId }}>
                      <SectionButton label="+ Add transaction" />
                    </Link>
                  </div>
                </div>
              </div>
              <div>
                <TransactionCards />
              </div>
              <div></div>
            </div>
          )}
          {activeTab === 'credit-line' && (
            <div className="org-content-credit">
              {isEditing ? (
                <div>
                  <div className="org-credit-line">
                    Credit line:{' '}
                    <input
                      className="credit-input"
                      type="number"
                      value={newPrice}
                      onKeyPress={(e) => {
                        const isDigit = /^\d$/;
                        if (!isDigit.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      placeholder={'New price'}
                      onChange={(e) => setNewPrice(e.target.valueAsNumber)}
                    />
                  </div>
                </div>
              ) : (
                <div className="org-credit-line">
                  Credit line: <b>SAR {orgData?.creditLimit}</b>
                </div>
              )}
              {isEditing ? (
                <SectionButton
                  label="Save"
                  isFilled={true}
                  onClick={handleSaveClick}
                />
              ) : (
                <SectionButton
                  label="Change price"
                  isFilled={false}
                  onClick={handleEditClick}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </TemplateFormPage>
  );
};

export default OrgBalance;
