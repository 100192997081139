import React from 'react';
import settings from '../../../assets/icons/settings.png';
import './Avatar.scss';

const Avatar = () => {
  return (
    <div className="avatar">
      <img className="avatar-img" src={settings} alt="" />
    </div>
  );
};

export default Avatar;
