import React, { useState } from 'react';
import './OrgScreen.scss';
import TemplateFormPage from '../../TemplateFormPage/TemplateFormPage';
import SectionButton from '../../../shared/components/Button/SectionButton/SectionButton';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  useDeleteOrganizationMutation,
  useGetOrganizationByIdQuery,
} from '../../../entities/Organization/api/organizationApi';
import { IBranch } from '../../../entities/Branch/branch.models';
import ModalNewBranch from '../../../widgets/ModalNewBranch/ModalNewBranch';
import { useGetOrdersQuery } from '../../../entities/Order/api/orderApi';
import { IOrder, ISKURequest } from '../../../entities/Order/order.models';
import { roleApi } from '../../../entities/Role/api/roleApi';
import { AdminTypes } from '../../../entities/Admin/admin.models';

const OrgScreen = () => {
  const [isModalVisible, setModalVisible] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { orgId } = location.state || {};
  const { data: userRole } = roleApi.endpoints.getRole.useQueryState('');
  const [deleteOrg] = useDeleteOrganizationMutation();

  const { data: orgData, refetch } = useGetOrganizationByIdQuery(orgId);
  const { data: ordersData } = useGetOrdersQuery({
    organizationId: orgId,
    relations: ['skuRequests'],
    sortField: 'id',
    sortOrder: 'desc',
  });

  const formatDate = (date: Date): string => {
    if (date) {
      const dateObject = new Date(date);
      const year = dateObject.getFullYear();
      const month = dateObject.getMonth() + 1;
      const day = dateObject.getDay();
      return `${year}-${month < 10 ? '0' + month : month}-${
        day < 10 ? '0' + day : day
      }`;
    }
    return '';
  };

  const toggleModal = () => {
    setModalVisible(!isModalVisible);
  };

  const deleteOrganization = async () => {
    try {
      await deleteOrg(orgId);
      navigate(-1);
    } catch (error) {
      console.error('Error deleting organization:', error);
    }
  };

  const handleAddBranch = () => {
    refetch();
  };

  return (
    <TemplateFormPage>
      <div className="org-screen">
        <div className="org-screen-header">
          <div className="org-header-name">{orgData?.name}</div>
        </div>
        <div className="org-screen-body">
          <div className="org-key-data">
            <div className="org-balance">
              <div className="org-balance-label">Balance</div>
              <div className="org-balance-sum">SAR {orgData?.balance}</div>
            </div>
            <div className="org-balance">
              <div className="org-balance-label">Balance on hold</div>
              <div className="org-balance-sum">
                SAR {orgData?.balanceOnHold}
              </div>
            </div>
            <div className="org-branches">
              <div className="org-branches-label">Branches</div>
              {orgData?.branches.map((branch: IBranch) => (
                <Link key={branch.id} to={`/contact-branch/${branch?.id}`}>
                  <div className="org-branch">· {branch.name}</div>
                </Link>
              ))}
            </div>
            <div className="button-container">
              <div className="button-margin">
                <SectionButton label="+ Add branch" onClick={toggleModal} />
              </div>
              <div className="button-margin">
                <Link key={orgId} to={`edit/${orgId}`}>
                  <SectionButton
                    label="Edit organization"
                    onClick={toggleModal}
                  />
                </Link>
              </div>
              <div className="button-margin">
                <SectionButton
                  className="delete-button"
                  label="Delete organization"
                  onClick={deleteOrganization}
                />
              </div>
            </div>
          </div>
          <div className="ord-history">
            <div className="ord-history-title">Last orders</div>
            <div className="order-items">
              {ordersData?.data?.map((item: IOrder) => {
                const totalSkuReqQty = item?.skuRequests?.reduce(
                  (sum: number, skuReq: ISKURequest) =>
                    sum + skuReq?.crewsNumber,
                  0,
                );
                return (
                  <div className="order-item" key={item.id}>
                    <div className="order-item-data">
                      <div className="order-item-number">{item.price} SAR </div>
                      <div className="order-item-sum">{item.orderStatus}</div>
                    </div>
                    <div className={'data-container'}>
                      {item.createdAt && (
                        <div className="data">{formatDate(item.createdAt)}</div>
                      )}
                      <Link
                        to="/summary-order"
                        state={{
                          currOrderCardList: {
                            price: item.price,
                            orderStatus: item.orderStatus,
                            totalQty: totalSkuReqQty,
                            id: item.id,
                          },
                        }}>
                        <SectionButton label="More details ->" />{' '}
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="org-screen-footer">
          {[AdminTypes.super, AdminTypes.accountant].includes(
            userRole!.role,
          ) && (
            <Link to={'/org-balance'} state={{ orgId }}>
              <SectionButton
                label="Record payment / Change credit line"
                isFilled={false}
              />
            </Link>
          )}
        </div>
        {isModalVisible && (
          <ModalNewBranch
            onSubmit={handleAddBranch}
            organizationId={orgId}
            onClose={toggleModal}
          />
        )}
      </div>
    </TemplateFormPage>
  );
};

export default OrgScreen;
