import React from 'react';
import './ContactInput.scss';
import {
  FieldValues,
  UseFormRegister,
  UseFormRegisterReturn,
} from 'react-hook-form';

type PropTypes = UseFormRegisterReturn & {
  label?: string;
  placeholder?: string;
  error?: string;
};

const ContactInput = React.forwardRef<HTMLInputElement, PropTypes>(
  (props: PropTypes, ref) => {
    const { label, placeholder, error, ...rest } = props;
    return (
      <div className="branch-control">
        <input
          className="branch-input"
          id="branch-input"
          placeholder={placeholder}
          {...rest}
          ref={ref}
        />
        <label className="branch-label" htmlFor="branch-input">
          {label}
        </label>
        <div>{error}</div>
      </div>
    );
  },
);

ContactInput.displayName = 'Contact-Input';

export default ContactInput;
