import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { baseQueryConfig } from '../../../shared/api/api';
import { getToken } from '../../Admin/admin.models';
import { IDataWithPagination } from '../../Order/order.models';
import { IContact } from '../contact.models';
import { IContactUpdate } from '../../../shared/components/Modal/ContactModalUpdate/ContactModalUpdate';

const contactConfig = {
  ...baseQueryConfig,
  baseUrl: baseQueryConfig.baseUrl + '/contacts',
  prepareHeaders: (headers: Headers) => {
    const session = getToken();
    headers.set('Authorization', `${session.token}`);
    return headers;
  },
};

export type ICreateContact = {
  branch: number;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
};

export const contactApi = createApi({
  reducerPath: 'contactApi',
  tagTypes: ['Contact'],
  baseQuery: fetchBaseQuery(contactConfig),
  endpoints: (builder) => ({
    getContacts: builder.query<
      IDataWithPagination<IContact>,
      { relations?: string[]; page: number; take: number }
    >({
      query: ({ relations = [], page, take }) => ({
        url: '',
        params: {
          ...(relations.length > 1 ? { relations } : {}),
          page,
          take,
        },
      }),
      providesTags: ['Contact'],
    }),

    getContactsByBranchId: builder.query<
      IDataWithPagination<IContact>,
      { id: number; take: number; page: number }
    >({
      query: ({ id, page, take }) => ({
        url: `/`,
        params: {
          'branch.id': id,
          page,
          take,
        },
      }),
      providesTags: ['Contact'],
    }),
    getContactById: builder.query<any, string>({
      query: (id) => ({
        url: `/${id}`,
      }),
      providesTags: ['Contact'],
    }),
    createContact: builder.mutation<any, ICreateContact>({
      query: (data) => ({
        url: '',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Contact'],
    }),
    editContact: builder.mutation<any, IContactUpdate>({
      query: (data) => ({
        url: `/${data.id}`,
        method: 'PATCH',
        body: data,
        id: undefined,
      }),
      invalidatesTags: ['Contact'],
    }),
    deleteContact: builder.mutation<any, number>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Contact'],
    }),
  }),
});

export const {
  useGetContactsQuery,
  useCreateContactMutation,
  useDeleteContactMutation,
  useGetContactByIdQuery,
  useEditContactMutation,
  useGetContactsByBranchIdQuery,
} = contactApi;
