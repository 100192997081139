import React, { PropsWithChildren, useEffect, useState } from 'react';
import { auth } from '../../../services/firebase';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store/store';
import { setCurrentUser } from '../../../entities/Admin/admin.slice';

export const AuthProvider = ({ children }: PropsWithChildren) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      dispatch(setCurrentUser(user));
      if (user) {
        const token = await user.getIdToken();
        localStorage.setItem('token', token);
      }
      setLoading(false);
    });
  }, []);
  if (loading) return <div></div>;

  return <div>{children}</div>;
};
