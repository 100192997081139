import React from 'react';
import './ModalNewBranch.scss';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  CitiesTypes,
  CountryTypes,
  DistrictTypes,
  LocationTypes,
  WorkplaceTypes,
} from '../../entities/Branch/branch.models';
import Modal from '../../shared/components/Modal/Modal';
import FormInput from '../../shared/components/Input/FormInput/FormInput';
import SectionButton from '../../shared/components/Button/SectionButton/SectionButton';
import FormSelect from '../../shared/components/Select/FormSelect/FormSelect';
import PhoneNumberInput from '../PhoneNumberValidator/PhoneNumberValidator';
import { useCreateBranchMutation } from '../../entities/Branch/api/branchApi';

interface PropTypes {
  onClose: () => void;
  organizationId: number;
  onSubmit: () => void;
}

const yupSchema = yup.object({
  name: yup.string().required('Branch name is required'),
  city: yup.mixed<CitiesTypes>().required(),
  country: yup.mixed<CountryTypes>().required(),
  district: yup.mixed<DistrictTypes>().required(),
  locationType: yup.mixed<LocationTypes>().required(),
  workplaceType: yup.mixed<WorkplaceTypes>().required(),
  firstName: yup.string().required('Supervisor first name is required'),
  lastName: yup.string().required('Supervisor last name is required'),
  email: yup.string().email().required('Supervisor email is required'),
  phone: yup.string().required('Supervisor phone is required'),
});

type YupSchemaType = yup.InferType<typeof yupSchema>;

const ModalNewBranch = (props: PropTypes) => {
  const { onClose, organizationId, onSubmit } = props;

  const [createBranch] = useCreateBranchMutation();

  const citiesArray = Object.values(CitiesTypes);
  const countriesArray = Object.values(CountryTypes);
  const districtsArray = Object.values(DistrictTypes);
  const locationsArray = Object.values(LocationTypes);
  const workplaceArray = Object.values(WorkplaceTypes);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<YupSchemaType>({
    resolver: yupResolver(yupSchema),
    defaultValues: {
      name: '',
      city: undefined,
      country: undefined,
      district: undefined,
      locationType: undefined,
      workplaceType: undefined,
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
  });

  const onSubmitHandler: SubmitHandler<YupSchemaType> = async (data) => {
    createBranch({
      name: data.name,
      city: data.city,
      country: data.country,
      district: data.district,
      locationType: data.locationType,
      workplaceType: data.workplaceType,
      organization: organizationId,
      contacts: [
        {
          email: data.email,
          firstName: data.firstName,
          lastName: data.lastName,
          phone: data.phone,
        },
      ],
    })
      .unwrap()
      .then(() => {
        onSubmit();
        onClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Modal onClose={onClose}>
      <form
        className="modal-new-branch"
        onSubmit={handleSubmit(onSubmitHandler)}>
        <div className="modal-inputs">
          <div className="modal-branch-inputs">
            <div className="modal-branch-label">Branch data</div>
            <FormInput
              placeholder="Branch name"
              error={errors.name?.message}
              {...register('name')}
            />
            <FormSelect options={countriesArray} {...register('country')} />
            <FormSelect options={citiesArray} {...register('city')} />
            <FormSelect options={districtsArray} {...register('district')} />
            <FormSelect
              options={locationsArray}
              {...register('locationType')}
            />
            <FormSelect
              options={workplaceArray}
              {...register('workplaceType')}
            />
          </div>
          <div className="modal-supervisor-inputs">
            <div className="modal-supervisor-label">
              Supervisor contact data
            </div>
            <FormInput
              placeholder="First name"
              error={errors.firstName?.message}
              {...register('firstName')}
            />
            <FormInput
              placeholder="Last name"
              error={errors.lastName?.message}
              {...register('lastName')}
            />
            <FormInput
              placeholder="Email"
              error={errors.email?.message}
              {...register('email')}
            />
            <PhoneNumberInput register={register('phone')} />
          </div>
        </div>
        <SectionButton label="Register branch" isFilled={true} />
      </form>
    </Modal>
  );
};

export default ModalNewBranch;
