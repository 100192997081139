import React, { useEffect, useState } from 'react';
import './OrderModal.scss';
import BasicOrderButton from '../../Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import OrderCalendar from '../../Calendar/OrderCalendar/OrderCalendar';
import close from '../../../../assets/icons/orderPage/close.svg';
import {
  IOrderCard,
  IOrderWithRelations,
  ISKURequest,
} from '../../../../entities/Order/order.models';
import { useGetOrganizationsQuery } from '../../../../entities/Organization/api/organizationApi';
import { IOrganization } from '../../../../entities/Organization/org.models';
import { IBranch } from '../../../../entities/Branch/branch.models';
import { IContact } from '../../../../entities/Contact/contact.models';
import OrderSelect from '../../Select/OrderSelect/OrderSelect';
import { formatDate } from '../../Calendar/OrderCalendar/helper';

interface PropTypes {
  onClose: () => void;
  handleScheduleData?: (
    id: number,
    date: string,
    startTime: string,
    endTime: string,
    slotsNumber: number,
  ) => void;
  handleContactData?: (
    id: number,
    contact: { id: number; label: string },
  ) => void;
  modalId?: number;
  productCards?: IOrderCard[];
  orgName?: string;
  branchName?: string;
  currListOrder?: any;
  currOrderOrg?: IOrderWithRelations;
  currSchIndex?: number;
}

const OrderModal = (props: PropTypes) => {
  const {
    onClose,
    handleScheduleData,
    handleContactData,
    modalId,
    productCards,
    orgName,
    branchName,
    currListOrder,
    currOrderOrg,
    currSchIndex,
  } = props;

  const { data: orgDataRaw } = useGetOrganizationsQuery({
    relations: ['branches.contacts'],
  });
  const orgData = orgDataRaw?.data;
  const orderToEditBranchData = currOrderOrg?.contact?.branch;
  const orderToEditOrgName = orderToEditBranchData?.organization?.name;

  const currOrgData = orgData?.find(
    (org: IOrganization) =>
      org.name === orgName || org.name === orderToEditOrgName,
  );
  const currBranchData = currOrgData?.branches?.find(
    (branch: IBranch) =>
      branch.name === branchName || branch.name === orderToEditBranchData?.name,
  );
  const contactsNamesData =
    currBranchData?.contacts?.map(
      (contact: IContact) => `${contact.firstName} ${contact.lastName}`,
    ) || [];

  const [selectedDate, setSelectedDate] = useState(formatDate(new Date()));
  const [selectedStartTime, setSelectedStartTime] = useState('');
  const [selectedEndTime, setSelectedEndTime] = useState('');
  const [slotsNumber, setSlotsNumber] = useState(0);
  const [contactName, setContactName] = useState('');

  const onCollectData = () => {
    handleScheduleData?.(
      modalId || 0,
      selectedDate,
      selectedStartTime,
      selectedEndTime,
      slotsNumber,
    );
    const id = currBranchData?.contacts?.find(
      (contact) => `${contact.firstName} ${contact.lastName}` === contactName,
    )?.id;
    handleContactData?.(modalId || 0, { label: contactName, id: id || -1 });
    onClose();
  };

  const currentProductCard = productCards?.find((card) => card.id === modalId);
  const productsQty = currentProductCard?.quantity;

  const currentSkuReq = currListOrder?.skuRequests?.find(
    (skuReq: ISKURequest) => skuReq.id === modalId,
  );
  const skuReqQty = currentSkuReq?.crewsNumber;

  useEffect(() => {
    setSlotsNumber(productsQty || 0);
  }, [productsQty]);

  return (
    <div className="order-modal-container">
      <div className="order-modal">
        <button className="order-modal-close-icon" onClick={() => onClose()}>
          <img src={close} alt="x" />
        </button>
        <div className="order-modal-content">
          {handleScheduleData && (
            <OrderCalendar
              onSelectDate={(date) => setSelectedDate(date)}
              onSelectStartTime={(time) => setSelectedStartTime(time)}
              onSelectEndTime={(time) => setSelectedEndTime(time)}
              quantity={productsQty || skuReqQty}
              currSchIndex={currSchIndex}
            />
          )}
          {handleContactData && (
            <OrderSelect
              options={contactsNamesData}
              placeholder="Select contact"
              value={contactName}
              handleChange={(e) => {
                setContactName(e.target.value);
              }}
            />
          )}
        </div>
        <div className="hr"></div>
        <div className="order-modal-btns">
          <BasicOrderButton label="Cancel" onClick={onClose} />
          <BasicOrderButton
            isPink={true}
            label="Confirm"
            onClick={onCollectData}
          />
        </div>
      </div>
    </div>
  );
};

export default OrderModal;
