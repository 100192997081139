import React, { useEffect, useState } from 'react';
import './CreateOrderForm.scss';
import org from '../../assets/icons/orderPage/org.svg';
import branch from '../../assets/icons/orderPage/branch.svg';
import user from '../../assets/icons/orderPage/user-gray.svg';
import OrderSelect from '../../shared/components/Select/OrderSelect/OrderSelect';
import { useGetOrganizationsQuery } from '../../entities/Organization/api/organizationApi';
import { IOrganization } from '../../entities/Organization/org.models';
import { IBranch } from '../../entities/Branch/branch.models';
import BasicOrderButton from '../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import NextOrderButton from '../../shared/components/Button/OrderButtons/NextOrderButton/NextOrderButton';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { IContact } from '../../entities/Contact/contact.models';

const yupSchema = yup.object({
  organizationName: yup.string().notOneOf(['Select organization']).required(),
  branchName: yup
    .string()
    .notOneOf([
      'Select branch',
      'No branches found',
      'Select your organization first',
    ])
    .required(),
  contactName: yup
    .string()
    .notOneOf([
      'Select contact',
      'No contacts found',
      'Select your branch first',
    ])
    .required(),
});

type YupSchemaType = yup.InferType<typeof yupSchema>;

const CreateOrderForm = () => {
  const navigate = useNavigate();

  const { data: orgDataRaw } = useGetOrganizationsQuery({
    relations: ['branches.contacts'],
  });
  const [orgData, setOrgData] = useState<IOrganization[] | undefined>(
    undefined,
  );

  useEffect(() => {
    if (orgDataRaw) {
      setOrgData(orgDataRaw.data);
    }
  }, [orgDataRaw]);

  const orgOptions = orgData
    ? orgData.map((org: IOrganization) => org.name)
    : [];
  const [branchOptions, setBranchOptions] = useState<string[]>([
    'Select your organization first',
  ]);
  const [contactOptions, setContactOptions] = useState<string[]>([
    'Select your branch first',
  ]);

  const [selectedOrg, setSelectedOrg] = useState<string | undefined>(undefined);
  const [selectedBranch, setSelectedBranch] = useState<string | undefined>(
    undefined,
  );
  const [selectedContact, setSelectedContact] = useState<string | undefined>(
    undefined,
  );

  const handleOrgChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedOrg = e.target.value;
    setSelectedOrg(() => {
      if (orgData) {
        const selectedOrgData = orgData.find(
          (org: IOrganization) => org.name === newSelectedOrg,
        );

        if (selectedOrgData) {
          const branchOptionsRetrieved = selectedOrgData.branches?.map(
            (branch: IBranch) => branch.name,
          ) || ['No branches found'];
          setBranchOptions(branchOptionsRetrieved);
        } else {
          setBranchOptions(['Select your organization first']);
        }
      }
      setValue('organizationName', newSelectedOrg);

      return newSelectedOrg;
    });
  };

  const handleBranchChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedBranch = e.target.value;
    setSelectedBranch(() => {
      if (orgData) {
        const selectedOrgData = orgData.find(
          (org: IOrganization) => org.name === selectedOrg,
        );
        const selectedBranchData = selectedOrgData?.branches?.find(
          (branch: IBranch) => branch.name === newSelectedBranch,
        );

        if (selectedBranchData) {
          const contactOptionsRetrieved =
            selectedBranchData.contacts?.map(
              (contact: IContact) => `${contact.firstName} ${contact.lastName}`,
            ) || [];
          setContactOptions(contactOptionsRetrieved);
          contactOptionsRetrieved.length === 0 &&
            setContactOptions(['No contacts found']);
        } else {
          setContactOptions(['Select your branch first']);
        }
      }
      setValue('branchName', newSelectedBranch);

      return newSelectedBranch;
    });
  };

  const handleContactChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newSelectedContact = e.target.value;
    setValue('contactName', newSelectedContact);
    setSelectedContact(newSelectedContact);
  };

  const onSubmitHandler: SubmitHandler<YupSchemaType> = async (data) => {
    try {
      navigate('/select-product', {
        state: {
          contactData: {
            selectedOrg: data.organizationName,
            selectedBranch: data.branchName,
            selectedContact: data.contactName,
          },
        },
      });
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const handleCancel = () => {
    navigate('/orders');
  };

  const { register, handleSubmit, setValue } = useForm<YupSchemaType>({
    resolver: yupResolver(yupSchema),
    defaultValues: {
      organizationName: '',
      branchName: '',
      contactName: '',
    },
  });

  return (
    <form
      className="create-order-form"
      onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="create-order-instr">
        Please select organisation, branch and contact.
      </div>
      <div className="create-order-inputs">
        <OrderSelect
          options={orgOptions}
          icon={org}
          placeholder="Select organization"
          value={selectedOrg}
          handleChange={handleOrgChange}
          {...register('organizationName')}
        />
        <OrderSelect
          options={branchOptions}
          icon={branch}
          placeholder="Select branch"
          value={selectedBranch}
          handleChange={handleBranchChange}
          {...register('branchName')}
        />
        <OrderSelect
          options={contactOptions}
          icon={user}
          placeholder="Select contact"
          value={selectedContact}
          handleChange={handleContactChange}
          {...register('contactName')}
        />
      </div>
      <div className="hr"></div>
      <div className="create-order-btns">
        <BasicOrderButton label="Cancel" onClick={handleCancel} type="button" />
        <NextOrderButton label="Next" type="submit" />
      </div>
    </form>
  );
};

export default CreateOrderForm;
