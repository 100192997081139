import React from 'react';
import Logo from '../../../assets/icons/logo.svg';
import './PageLogo.scss';

const PageLogo = () => {
  return (
    <div className="page-logo">
      <img className="img-logo" src={Logo} alt="header-navigation" />
      <div className="title-logo">Crew</div>
    </div>
  );
};

export default PageLogo;
