import React from 'react';
import './Input.scss';

interface PropTypes {
  label?: string;
  placeholder?: string;
  id?: string;
  type: 'text' | 'number' | 'date';
  error?: string;
}

const Input = React.forwardRef<HTMLInputElement, PropTypes>((props, ref) => {
  const { label, placeholder, type, error, ...rest } = props;
  return (
    <div className="form-control">
      <label className="form-label" htmlFor="form-input">
        {label}
      </label>

      <input
        className="form-input"
        ref={ref}
        type={type}
        placeholder={placeholder}
        {...rest}
      />

      {error && <div className="error-message">{error}</div>}
    </div>
  );
});

Input.displayName = '-input';

export default Input;
