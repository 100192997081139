export const calculateArrLength = (quantity: number) => {
  const baseSlots = Math.floor(quantity / 12);
  const remaining = quantity % 12;
  return baseSlots + (remaining > 0 ? 1 : 0);
};

export const generateEmptySchedules = (
  existingSchedules: any,
  newQuantity: number,
) => {
  const schedules = existingSchedules ? [...existingSchedules] : [];
  const schArrLength = calculateArrLength(newQuantity);

  for (let i = schedules.length; i < schArrLength; i++) {
    schedules.push({
      date: '',
      startTime: '',
      endTime: '',
      slotsNumber: undefined,
    });
  }

  return schedules;
};
