import React from 'react';
import './AddTransaction.scss';
import SectionButton from '../../../shared/components/Button/SectionButton/SectionButton';
import * as yup from 'yup';
import { SubmitErrorHandler, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLocation, useNavigate } from 'react-router-dom';
import TemplateFormPage from '../../TemplateFormPage/TemplateFormPage';
import { useCreatePaymentMutation } from '../../../entities/transactEntities/Payment/api/paymentApi';
import { regexDate } from './regex';

const yupSchema = yup.object({
  amount: yup.number().required('Required field'),
  paymentMethod: yup.string().required('Required field'),
  date: yup
    .string()
    .required('Required field.')
    .matches(regexDate, 'Invalid date format. Please use DD-MM-YYYY'),
});

type YupSchemaType = yup.InferType<typeof yupSchema>;

const AddTransaction = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orgId } = location.state || {};

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(yupSchema),
    defaultValues: {
      paymentMethod: '',
    },
  });

  const [addTransaction] = useCreatePaymentMutation();

  const onSubmitHandler: SubmitHandler<YupSchemaType> = async (formData) => {
    try {
      const transactionDataFormatted = {
        amount: Number(formData.amount),
        organization: orgId,
        date: new Date(formData.date).toISOString(),
        paymentMethod: formData.paymentMethod,
      };

      await addTransaction(transactionDataFormatted);
      navigate('/org-balance', { state: { tab: 'payments', orgId } });
    } catch (e) {
      console.error('Error adding transaction', e);
    }
  };

  const onError: SubmitErrorHandler<any> = (value: any) => {
    console.error('Error', value);
  };

  return (
    <TemplateFormPage>
      <form
        className="register-org"
        onSubmit={handleSubmit(onSubmitHandler, onError)}>
        <div className="register-org-title">Add transaction</div>
        <div className="register-org-sections">
          <div>
            <input
              className={`input-style ${errors.amount ? 'error' : ''}`}
              type="text"
              onKeyPress={(e) => {
                const isDigit = /^\d$/;
                if (!isDigit.test(e.key)) {
                  e.preventDefault();
                }
              }}
              {...register('amount')}
              placeholder={'Amount'}
            />
            {errors.amount && (
              <p className="error-message">{'Amount must be a `number`'}</p>
            )}
          </div>
          <div>
            <input
              className="input-style"
              {...register('paymentMethod')}
              placeholder={'Payment Method'}
            />
            {errors.paymentMethod && (
              <p className="error-message">{errors.paymentMethod.message}</p>
            )}
          </div>
          <div className="input-date">
            <label htmlFor="date"></label>
            <input className="input-style" type="date" {...register('date')} />
            {errors.date && (
              <p className="error-message">{errors.date.message}</p>
            )}
          </div>
        </div>
        <div className="register-org-button">
          <SectionButton label="Submit" type="submit" isFilled={true} />
        </div>
      </form>
    </TemplateFormPage>
  );
};

export default AddTransaction;
