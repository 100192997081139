import React from 'react';
import './SummaryOrderCard.scss';
import SelectProductCard from '../SelectProductCard/SelectProductCard';
import { IOrderSummary } from '../order.models';
import SummaryBrochure from '../SummaryBrochure/SummaryBrochure';
import SummaryDetailsLine from '../SummaryDetailsLine/SummaryDetailsLine';
import calendar from '../../../assets/icons/orderPage/calendar-gray.svg';
import user from '../../../assets/icons/orderPage/user-gray.svg';

const SummaryOrderCard = (props: IOrderSummary) => {
  const {
    id,
    icon,
    position,
    grade,
    price,
    priceSingle,
    finalQuantity,
    schedules,
    contract,
    contact,
    isSubmitted,
    handleDeleteProductCard,
  } = props;

  const { contractType, maxSalary, housing } = contract ?? {};

  const scheduleDates = schedules?.map((schedule) => {
    const isoDateString = schedule.startTime;
    const isoDate = new Date(isoDateString);

    return isoDate.toLocaleString('en-US', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false,
    });
  });
  return (
    <div className="summary-order-card">
      <SelectProductCard
        id={id}
        icon={icon}
        position={position}
        grade={grade}
        price={price}
        priceSingle={priceSingle}
        finalQuantity={finalQuantity}
        isSummary={true}
        isSubmitted={isSubmitted}
        onDeleteProductCard={handleDeleteProductCard}
      />
      <div className="hr"></div>
      <SummaryBrochure
        contractType={contractType || 'No contract type'}
        maxSalary={maxSalary || NaN}
        housing={housing || false}
      />
      <div className="hr"></div>
      {schedules?.[0]?.endTime &&
        schedules?.map((schedule) => (
          <SummaryDetailsLine
            label="Interview schedule"
            data={`${schedule.date} - ${schedule.startTime}-${schedule.endTime}`}
            icon={calendar}
            key={Math.round(Math.random() * 10000)}
          />
        ))}
      {!schedules?.[0]?.endTime &&
        scheduleDates?.map((date) => (
          <SummaryDetailsLine
            label="Interview schedule"
            data={date}
            icon={calendar}
            key={Math.round(Math.random() * 10000)}
          />
        ))}
      <div className="hr"></div>
      <SummaryDetailsLine
        label="Contact"
        data={contact || 'No Contact'}
        icon={user}
      />
    </div>
  );
};

export default SummaryOrderCard;
