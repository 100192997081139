import React from 'react';
import {
  IOrder,
  IOrderWithSKUResponse,
} from '../../../../entities/Order/order.models';
import { IContact } from '../../../../entities/Contact/contact.models';
import SummaryOrderCard from '../../../../entities/Order/SummaryOrderCard/SummaryOrderCard';
import styles from './SummaryOrderList.module.scss';

interface PropTypes {
  currOrderSku?: IOrder;
  isSubmitted?: boolean;
  skuRequests: IOrderWithSKUResponse[];
  handleDeleteProductCard?: (id: number) => void;
}

const SummaryOrderCards = (props: PropTypes) => {
  const { skuRequests, isSubmitted, handleDeleteProductCard } = props;

  return (
    <div className={styles.SummaryOrderList}>
      {skuRequests?.map((skuReq) => {
        const currentSku = skuReq.sku;
        const qualityGrade = parseInt(currentSku?.qualityName, 10);
        const contactName = `${skuReq?.contact?.firstName} ${skuReq?.contact?.lastName}`;
        const skuReqContact = skuReq.contact as unknown as IContact;
        const skuReqContactName = `${skuReqContact.firstName} ${skuReqContact.lastName}`;

        return (
          <SummaryOrderCard
            id={skuReq.id || 0}
            key={skuReq.id}
            position={currentSku?.productName}
            grade={qualityGrade}
            price={currentSku?.pricePerUnit * skuReq?.crewsNumber}
            priceSingle={currentSku?.pricePerUnit}
            finalQuantity={skuReq.crewsNumber}
            contract={skuReq.contract}
            schedules={skuReq.schedules}
            contact={skuReqContactName || contactName}
            isSubmitted={isSubmitted}
            handleDeleteProductCard={handleDeleteProductCard}
          />
        );
      })}
    </div>
  );
};

export default SummaryOrderCards;
