import React, { useState } from 'react';
import EmailLink from '../../../shared/components/Link/EmailLink/EmailLink';
import WhatsAppLink from '../../../shared/components/Link/WhatsAppLink/WhatsAppLink';
import SectionButton from '../../../shared/components/Button/SectionButton/SectionButton';
import './ContactCard.scss';
import { IContact } from '../../../entities/Contact/contact.models';
import ContactModalUpdate, {
  IContactUpdate,
} from '../../../shared/components/Modal/ContactModalUpdate/ContactModalUpdate';

interface typeProps {
  contact: IContact;
  deleteContact: () => void;
  onEditContact: (data: IContactUpdate) => void;
}

const ContactCard = (props: typeProps) => {
  const { email, id, lastName, firstName, phone, isPrimary } = props.contact;
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const handleClickEdit = () => {
    setIsModalEditVisible(true);
  };

  const handleCloseModal = () => setIsModalEditVisible(false);

  return (
    <div key={id}>
      <div className="branch-contact-item" key={email}>
        <div className="branch-contact-name">
          {firstName} {lastName}
        </div>
        <div className={'position-mail'}>
          <EmailLink email={email} className="branch-contact-email" />
        </div>
        <div className="link-delete">
          <WhatsAppLink phone={phone} className="branch-contact-phone" />

          {!isPrimary && (
            <SectionButton
              className="delete-button"
              label="✖"
              onClick={props.deleteContact}
            />
          )}
          <SectionButton
            className="update-button"
            label={'✎'}
            onClick={handleClickEdit}
          />
        </div>
      </div>
      {isModalEditVisible && (
        <ContactModalUpdate
          onClose={handleCloseModal}
          onUpdateContact={props.onEditContact}
          selectedContact={props.contact}
        />
      )}
    </div>
  );
};

export default ContactCard;
