import React from 'react';
import './NavHeader.scss';
import PageLogo from '../../../../shared/components/PageLogo/PageLogo';

const HeaderNavigation = () => {
  return (
    <div className="nav-header">
      <PageLogo />
    </div>
  );
};

export default HeaderNavigation;
