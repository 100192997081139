import { IMetadata } from '../../shared/api/api';
import { IBranch } from '../Branch/branch.models';
import { IContact } from '../Contact/contact.models';
import { IOrganization } from '../Organization/org.models';
import { ISku, ISkuResponse } from '../SKU/SKUModel.models';

export interface IOrder {
  id: number;
  contact?: IContact;
  createdAt?: Date;
  needsInteraction: boolean;
  orderStatus: OrderStatuses;
  price: number;
  rating?: number;
  skuRequests?: ISKURequest[];
  tags?: string[];
  total: number;
  tax: number;
  subtotal: number;
}

export interface IOrdersWithPagination {
  data: IOrderWithSkuRequestContactBranchOrganization[];
  meta: IMetadata;
}

export interface IDataWithPagination<T> {
  data: T[];
  meta: IMetadata;
}

export type IOrderWithRelations = Omit<IOrder, 'skuRequests'> & {
  skuRequests: IOrderWithSKUResponse[];
  contact: IContact;
};

export type IOrderWithSkuRequestContactBranchOrganization = {
  id: number;
  createdAt: Date;
  orderStatus: OrderStatuses;
  price: number;
  skuRequests: IOrderWithSKUResponse[];
  contact: IContact;
  branch: IBranch;
  organization: IOrganization;
  rating?: number;
  needsInteraction?: boolean;
};

export enum OrderStatuses {
  PENDING = 'PENDING',
  FULFILLED = 'FULFILLED',
  CONFIRMED = 'CONFIRMED',
  CANCELED = 'CANCELED',
  LOW_RATING_UNHANDLED = 'LOW_RATING_UNHANDLED',
  LOW_RATING_HANDLED_REPEATED = 'LOW_RATING_HANDLED_REPEATED',
  LOW_RATING_HANDLED_CANCELED = 'LOW_RATING_HANDLED_CANCELED',
  EXPIRED_CONFIRMATION = 'EXPIRED_CONFIRMATION',
}

export interface IOrderSummary {
  id: number;
  icon?: string;
  position: string;
  grade?: number;
  price: number;
  priceSingle?: number;
  finalQuantity?: number;
  contract?: IOrderContract;
  schedules?: IOrderSchedule[];
  contact?: string;
  femalesPerc?: number;
  localsPerc?: number;
  isSubmitted?: boolean;
  handleDeleteProductCard?: (id: number) => void;
}

export interface IFiltersTypes {
  product: string;
  grade: string;
}

export interface IOrderCard {
  id?: number;
  icon?: string;
  product: string;
  grade?: number;
  quantity: number;
  price: number;
  contracts?: IOrderContract[];
  schedules?: IOrderSchedule[];
  contact?: string;
  contactId?: number;
  ordInv?: number;
  femalesPerc?: number;
  localsPerc?: number;
  tags?: IOrderTag[];
}

export interface IOrderContract {
  id?: number;
  contractType: string;
  maxSalary: number;
  housing?: boolean;
  isHousing?: boolean;
  femalesPercentage?: number;
  localsPercentage?: number;
}

export interface IOrderSchedule {
  id?: number;
  date?: string;
  startTime: string | Date;
  endTime?: string;
  slotsNumber?: number;
  index?: number;
}

export interface ISKURequest {
  id: number;
  createdAt: Date;
  sku: number | ISku;
  contract: IOrderContractSKU;
  crewsNumber: number;
  femalesPercentage: number;
  localsPercentage: number;
  schedules: { startTime: Date | string; slotsNumber: number }[];
  contact: string;
  order?: string;
}

export interface IOrderWithSKUResponse {
  id: number;
  createdAt: Date;
  sku: ISkuResponse;
  contract: IOrderContractSKU;
  crewsNumber: number;
  femalesPercentage: number;
  localsPercentage: number;
  schedules: { startTime: Date | string; slotsNumber: number }[];
  contact: IContact;
  order: string;
  subtotal: number;
  tax: number;
  total: number;
}

export interface IOrderContractSKU {
  contractType: string;
  maxSalary: number;
  housing: boolean;
  females?: number;
  locals?: number;
}

export interface IOrderFilters {
  status?: string;
  date?: string;
  organization?: string;
}

export interface IOrderTag {
  id: number;
  name: string;
}

export interface IOrderUpdateRequest {
  id: number;
  skuRequests: ISkuUpdateRequest[];
}

export interface ISkuUpdateRequest {
  sku: number;
  crewsNumber: number;
  femalesPercentage: number;
  localsPercentage: number;
  schedules: { startTime: Date | string; slotsNumber: number }[];
  contact: number;
  tags: { id: number }[];
  contract: {
    contractType: string;
    maxSalary: number;
    isHousing: boolean;
  };
}
