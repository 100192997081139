import chef from '../../../assets/icons/orderPage/chef.svg';
import chefAssist from '../../../assets/icons/orderPage/chef-assist.svg';
import barista from '../../../assets/icons/orderPage/barista.svg';
import waiter from '../../../assets/icons/orderPage/waiter.svg';
import receptionist from '../../../assets/icons/orderPage/receptionist.svg';
import privateFlight from '../../../assets/icons/orderPage/private-flight.svg';

export const qualityIdToQualityMap: Record<number, number> = {
  1: 1,
  2: 5,
  3: 3,
};

export const allCards = [
  {
    icon: chef,
    position: 'Chef',
    grade: 5,
    price: 123,
    id: 8,
    orderableInventory: 30,
    females: 10,
    locals: 28,
  },
  {
    icon: chefAssist,
    position: 'Chef Assistant',
    grade: 5,
    price: 123,
    id: 9,
    orderableInventory: 60,
    females: 23,
    locals: 46,
  },
  {
    icon: waiter,
    position: 'Waiter',
    grade: 5,
    price: 85,
    id: 24,
    orderableInventory: 100,
    females: 70,
    locals: 40,
  },
  {
    icon: waiter,
    position: 'Waiter',
    grade: 4,
    price: 90,
    id: 23,
    orderableInventory: 286,
    females: 156,
    locals: 102,
  },
  {
    icon: barista,
    position: 'Barista',
    grade: 4,
    price: 223,
    id: 1,
    orderableInventory: 370,
    females: 48,
    locals: 23,
  },
  {
    icon: receptionist,
    position: 'Receptionist',
    grade: 5,
    price: 100,
    id: 18,
    orderableInventory: 140,
    females: 64,
    locals: 100,
  },
  {
    icon: receptionist,
    position: 'Receptionist',
    grade: 4,
    price: 123,
    id: 17,
    orderableInventory: 139,
    females: 63,
    locals: 106,
  },
  {
    icon: privateFlight,
    position: 'Private Flight Attendant',
    grade: 5,
    price: 150,
    id: 22,
    orderableInventory: 75,
    females: 70,
    locals: 10,
  },
];
