import React, { useState } from 'react';
import './TimeSlotPicker.scss';
import classNames from 'classnames';

interface PropTypes {
  timeSlots: string[];
  onSelectTime: (time: string) => void;
}

const TimeSlotPicker = (props: PropTypes) => {
  const { timeSlots, onSelectTime } = props;

  const [selectedTime, setSelectedTime] = useState<string | null>(null);

  const handleTimeSlotClick = (time: string) => {
    setSelectedTime(time);
    onSelectTime(time);
  };

  const isSlotSelected = (time: string) => {
    return selectedTime === time;
  };

  return (
    <div className="time-slot-picker">
      <div className="time-slots">
        {timeSlots.map((time) => (
          <button
            className={classNames('time-slot', {
              'time-slot-selected': isSlotSelected(time),
            })}
            key={time}
            onMouseDown={() => handleTimeSlotClick(time)}>
            {time}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TimeSlotPicker;
