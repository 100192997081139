import React, { useState } from 'react';
import './Settings.scss';
import TemplateFormPage from '../../TemplateFormPage/TemplateFormPage';
import SectionButton from '../../../shared/components/Button/SectionButton/SectionButton';
import EmailLink from '../../../shared/components/Link/EmailLink/EmailLink';
import BranchInput from '../../../shared/components/Input/BranchInput/BranchInput';
import {
  useCreateAdminMutation,
  useDeleteAdminMutation,
  useGetAdminsQuery,
  useUpdateAdminMutation,
} from '../../../entities/Admin/api/adminApi';
import { AdminTypes, IAdmin } from '../../../entities/Admin/admin.models';
import AdminSelect from '../../../shared/components/Select/AdminSelect/AdminSelect';
import StatusInput from '../../../shared/components/Input/BranchInput/StatusInput';

const Settings = () => {
  const [newAdmin, setNewAdmin] = useState<IAdmin>({
    name: '',
    adminType: '',
    email: '',
  });
  const [editedAdmin, setEditedAdmin] = useState<string | null>(null);

  const { data: adminsDataRaw } = useGetAdminsQuery();
  const admins = adminsDataRaw?.data;
  const [createAdmin] = useCreateAdminMutation();
  const [deleteAdmin] = useDeleteAdminMutation();
  const [editAdmin] = useUpdateAdminMutation();

  const toggleEditSelect = (adminId: string | null) => {
    setEditedAdmin((prevId) => (prevId === adminId ? null : adminId));
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>,
    field: string,
  ) => {
    setNewAdmin({ ...newAdmin, [field]: e.target.value });
  };

  const handleAddAdmin = async () => {
    if (newAdmin.name && newAdmin.adminType && newAdmin.email) {
      const { name, adminType, email } = newAdmin;
      try {
        await createAdmin({
          name,
          adminType: AdminTypes[adminType as keyof typeof AdminTypes],
          email,
        }).unwrap();
      } catch (e) {
        console.log(e);
      }
      setNewAdmin({ name: '', adminType: '', email: '' });
    }
  };

  const handleDeleteAdmin = async (adminId: string) => {
    try {
      await deleteAdmin(adminId).unwrap();
    } catch (e) {
      console.error('Error deleting admin:', e);
    }
  };

  const handleEditAdminStatus = async (
    e: React.ChangeEvent<HTMLSelectElement>,
    adminId: string,
  ) => {
    try {
      const updatedAdminData = {
        adminType: e.target.value,
      };

      await editAdmin({
        id: adminId,
        data: updatedAdminData,
      }).unwrap();
    } catch (error) {
      console.error('Error updating admin status:', error);
    }
  };

  return (
    <TemplateFormPage>
      <div className="settings">
        <div className="settings-title">Settings</div>
        <div className="settings-admins">
          <div className="settings-admins-title">Admins</div>
          {admins?.map((admin: any) => (
            <div className="settings-admin" key={admin.id}>
              <div className="settings-admin-columns">
                <div className="settings-admin-name">{admin.name}</div>
                {editedAdmin === admin.id ? (
                  <AdminSelect
                    value={admin.adminType}
                    handleChange={(e) => handleEditAdminStatus(e, admin.id)}
                  />
                ) : (
                  <div className="settings-admin-role">{admin.adminType}</div>
                )}
                <EmailLink
                  email={admin.email}
                  className="settings-admin-email"
                />
              </div>
              <div className="settings-admin-btns">
                <div className="btn-edit-admin">
                  {editedAdmin === admin.id ? (
                    <SectionButton
                      label="Save status"
                      onClick={() => toggleEditSelect(null)}
                      isFilled={true}
                    />
                  ) : (
                    <SectionButton
                      label="Edit status"
                      onClick={() => toggleEditSelect(admin.id)}
                    />
                  )}
                </div>
                <SectionButton
                  label="Remove admin"
                  isFilled={true}
                  className="btn-remove-admin"
                  onClick={() => handleDeleteAdmin(admin.id)}
                />
              </div>
            </div>
          ))}
          <div className="settings-new-admin">
            <BranchInput
              placeholder="Admin name"
              onChange={(e) => handleInputChange(e, 'name')}
              value={newAdmin.name}
            />
            <StatusInput
              placeholder="Status"
              onChange={(e) => handleInputChange(e, 'adminType')}
              value={newAdmin.adminType}
            />
            <BranchInput
              placeholder="Email"
              onChange={(e) => handleInputChange(e, 'email')}
              value={newAdmin.email}
            />
          </div>
          <SectionButton
            label="+ Add new admin"
            isFilled={true}
            onClick={handleAddAdmin}
          />
        </div>
      </div>
    </TemplateFormPage>
  );
};

export default Settings;
