import React from 'react';
import TemplatePage from '../TemplatePage/TemplatePage';
import './Dashboard.scss';
import ContractGraphContainer from '../../entities/Contract/components/ContractGraphContainer/ContractGraphContainer';
import CustomerGraphContainer from '../../entities/Customer/components/CustomerGraphContainer/CustomerGraphContainer';

const Dashboard = () => {
  return (
    <div className="dashboard">
      <TemplatePage title="Dashboard">
        <ContractGraphContainer />
        <CustomerGraphContainer />
      </TemplatePage>
    </div>
  );
};

export default Dashboard;
