import React from 'react';
import './MarketSalary.scss';
import OrderSubtitle from '../../../shared/components/Title/OrderSubtitle/OrderSubtitle';
import MarketSalaryItem from '../../../entities/Order/MarketSalaryItem/MarketSalaryItem';
import money from '../../../assets/icons/orderPage/money.svg';
import { IOrderCard } from '../../../entities/Order/order.models';
import { useGetSkuQuery } from '../../../entities/SKU/api/SKUApi';
import { ISkuResponse } from '../../../entities/SKU/SKUModel.models';

interface PropTypes {
  cardsData?: IOrderCard[];
}

const calculateMinimumSalary = (currentSku: ISkuResponse | undefined) => {
  if (currentSku && currentSku.salaryMinimum && currentSku.pricePerUnit) {
    return currentSku.salaryMinimum > currentSku.pricePerUnit
      ? currentSku.pricePerUnit
      : currentSku.salaryMinimum;
  }
  return NaN;
};

const MarketSalary = (props: PropTypes) => {
  const { cardsData } = props;

  const { data: skuData, isSuccess } = useGetSkuQuery({});

  if (!isSuccess) return <div></div>;

  return (
    <div className="market-salary">
      <div className="market-salary-title">
        <img src={money} alt="icon" />
        <OrderSubtitle title="Market Salary" />
      </div>
      <div className="market-salary-items">
        {cardsData?.map((card) => {
          const currentSku = skuData?.find((sku) => sku.id === card.id);
          return (
            <MarketSalaryItem
              key={card.id}
              icon={card.icon}
              position={card.product}
              grade={card.grade}
              minimumAsk={calculateMinimumSalary(currentSku)}
              averageAsk={currentSku?.salaryAverage || undefined}
            />
          );
        })}
      </div>
    </div>
  );
};

export default MarketSalary;
