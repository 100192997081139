import React from 'react';
import './RegOrgSection.scss';
import FormInput from '../../shared/components/Input/FormInput/FormInput';
import { UseFormRegister } from 'react-hook-form';
import FormSelect from '../../shared/components/Select/FormSelect/FormSelect';
import PhoneNumberInput from '../../widgets/PhoneNumberValidator/PhoneNumberValidator';

interface Input {
  label: string;
  name: string;
  selectOptions?: string[];
}

interface PropTypes {
  category: string;
  inputEntries: Input[];
  register: UseFormRegister<any>;
  errors?: any;
}
const maxLenghts: { [key: string]: number } = {
  commercialReg: 10,
  taxReg: 15,
};

const RegOrgSection = (props: PropTypes) => {
  const { category, inputEntries, register, errors } = props;

  return (
    <div className="reg-org-section">
      <div className="reg-org-category">{category}</div>
      {inputEntries.map((entry) => {
        return (
          <div className="reg-org-control" key={entry.name}>
            {entry.selectOptions ? (
              <>
                <label className="reg-org-label">{entry.label}</label>
                <FormSelect
                  options={entry.selectOptions}
                  {...register(entry.name)}
                />
                <div className="reg-org-error">
                  {errors[entry.name]?.message}
                </div>
              </>
            ) : (
              <>
                {entry.name === 'phone' ? (
                  <>
                    <label className="reg-org-label">
                      {entry.label}
                      <PhoneNumberInput
                        key={'phone'}
                        register={register('phone')}
                      />
                    </label>
                    <div className="reg-org-error phone">
                      {errors[entry.name]?.message}
                    </div>
                  </>
                ) : (
                  <>
                    <FormInput
                      label={entry.label}
                      placeholder={`Enter ${entry.label.toLowerCase()}`}
                      isDate={entry.name === 'commercialRegExpiryDate'}
                      maxLength={maxLenghts[entry.name]}
                      {...register(entry.name)}
                    />
                    <div className="reg-org-error">
                      {errors[entry.name]?.message}
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default RegOrgSection;
