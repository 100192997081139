import React from 'react';
import './TransactionCard.scss';

export interface IPropsPayment {
  id?: number;
  amount: number;
  date?: string;
  createdAt?: string;
  updatedAt?: Date;
  paymentMethod: string;
}

const TransactionCard = (props: IPropsPayment) => {
  const { amount, date, paymentMethod } = props;
  const dateObject = new Date(date as string);
  const formattedDate = `${dateObject.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  })}`;
  return (
    <div className={'card_style'}>
      <div className={'info'}>
        <div className={'text_style'}>{paymentMethod}</div>
        <div className={'text_style'}>{formattedDate}</div>
      </div>
      <div className={'text_style'}>{'amount ' + amount}</div>
    </div>
  );
};

export default TransactionCard;
