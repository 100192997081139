import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IMetadata, baseQueryConfig } from '../../../shared/api/api';
import { ICreateOrganizationRequest, IOrganization } from '../org.models';
import { RootState } from '../../../store/store';

export type IOrganizationWithMetadata = {
  data: IOrganization[];
  meta: IMetadata;
};

export const organizationApi = createApi({
  reducerPath: 'OrganizationApi',
  tagTypes: ['Organization', 'Organizations'],
  baseQuery: fetchBaseQuery({
    ...baseQueryConfig,
    baseUrl: baseQueryConfig.baseUrl + '/organizations',
    prepareHeaders: async (headers: Headers, { getState }) => {
      const token = await (getState() as RootState).user.user!.getIdToken();
      headers.set('Authorization', token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getOrganizations: builder.query<
      IOrganizationWithMetadata,
      {
        relations?: string[];
        page?: number;
        take?: number;
        searchByName?: string;
      }
    >({
      query: ({ relations, page, take, searchByName }) => ({
        url: '',
        params: {
          page,
          relations,
          take,
          name: searchByName,
        },
      }),
      providesTags: ['Organizations'],
    }),
    getOrganizationById: builder.query<IOrganization, string>({
      query: (id) => ({
        url: `/${id}?relations=branches`,
      }),
      providesTags: ['Organization'],
    }),
    createOrganization: builder.mutation<any, ICreateOrganizationRequest>({
      query: (data) => ({
        url: '',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Organizations'],
    }),
    updateOrganization: builder.mutation<any, any>({
      query: ({ id, ...body }) => ({
        url: `/${id}`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Organization', 'Organizations'],
    }),
    deleteOrganization: builder.mutation<any, string>({
      query: (id) => ({
        url: `/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Organizations'],
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useCreateOrganizationMutation,
  useDeleteOrganizationMutation,
  useGetOrganizationByIdQuery,
  useUpdateOrganizationMutation,
} = organizationApi;
