import React, { useCallback, useMemo, useState } from 'react';
import './ContractSchedule.scss';
import money from '../../../../assets/icons/orderPage/money.svg';

import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useGetOrderByIdWithRelationsQuery } from '../../../../entities/Order/api/orderApi';
import {
  IOrderCard,
  IOrderContract,
  ISKURequest,
} from '../../../../entities/Order/order.models';
import TemplatePage from '../../../TemplatePage/TemplatePage';
import NextOrderButton from '../../../../shared/components/Button/OrderButtons/NextOrderButton/NextOrderButton';
import BasicOrderButton from '../../../../shared/components/Button/OrderButtons/BasicOrderButton/BasicOrderButton';
import OrderTitle from '../../../../shared/components/Title/OrderTitle/OrderTitle';
import OrderProdList from '../../../../widgets/Order/OrderProdList/OrderProdList';
import OrderStagesPanel from '../../../../widgets/Order/OrderStagesPanel/OrderStagesPanel';
import MarketSalary from '../../../../widgets/Order/MarketSalary/MarketSalary';
import OrderModal from '../../../../shared/components/Modal/OrderModal/OrderModal';
import { calculateArrLength } from './helper';

const ContractSchedule = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    contactData,
    selectedCards,
    totalCrewQuantityRef: crewQuantityRaw,
    currOrderCardList,
    currOrderSkuToEdit: currentEditOrder,
    orderProductCards: orderProductCardsSumm,
    id,
  } = location.state || {};
  const editOrderQty = currentEditOrder?.skuRequests?.reduce(
    (sum: number, skuReq: ISKURequest) => sum + skuReq.crewsNumber,
    0,
  );
  const crewQuantity =
    crewQuantityRaw?.current ||
    currOrderCardList?.totalQty ||
    editOrderQty ||
    0;
  const { selectedOrg: orgName, selectedBranch: branchName } =
    contactData || {};

  const { data: currOrderOrg } = useGetOrderByIdWithRelationsQuery(
    { id },
    { skip: !id },
  );

  const [isScheduleModalOpen, setScheduleModalOpen] = useState(false);
  const [isContactModalOpen, setContactModalOpen] = useState(false);
  const [isAlertVisible, setAlertVisibility] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [orderProductCards, setOrderProductCards] = useState<IOrderCard[]>(
    orderProductCardsSumm || selectedCards,
  );
  const [modalId, setModalId] = useState(0);
  const [currSchIndex, setCurrSchIndex] = useState(0);

  const toggleScheduleModal = (id: number, index: number) => {
    setScheduleModalOpen(!isScheduleModalOpen);
    setModalId(id);
    setCurrSchIndex(index);
  };

  const toggleContactModal = (id: number) => {
    setContactModalOpen(!isContactModalOpen);
    setModalId(id);
  };

  const handleScheduleData = (
    id: number,
    date: string,
    startTime: string,
    endTime: string,
    slotsNumber: number,
  ) => {
    setOrderProductCards((prevCards) => {
      const updatedCards = prevCards?.map((card) => {
        if (card?.schedules !== undefined) {
          return card.id === id
            ? {
                ...card,
                schedules: card.schedules.map((schedule, index) =>
                  index === currSchIndex
                    ? { date, startTime, endTime, slotsNumber }
                    : schedule,
                ),
              }
            : card;
        } else {
          const schArrLength = calculateArrLength(card.quantity);

          return card.id === id
            ? {
                ...card,
                schedules: Array.from({ length: schArrLength }).map(
                  (_, index) =>
                    index === currSchIndex
                      ? { date, startTime, endTime, slotsNumber }
                      : {
                          date: '',
                          startTime: '',
                          endTime: '',
                          slotsNumber: undefined,
                        },
                ),
              }
            : card;
        }
      });
      return updatedCards || prevCards;
    });

    setScheduleModalOpen(false);
    setContactModalOpen(false);
  };
  console.log(orderProductCards);
  const handleContactData = (
    id: number,
    contact: { id: number; label: string },
  ) => {
    setOrderProductCards(
      (prevCards) =>
        prevCards?.map((card) =>
          card.id === id
            ? { ...card, contact: contact.label, contactId: contact.id }
            : card,
        ),
    );
  };

  const handleContractData = (id: number, data: IOrderContract) => {
    setOrderProductCards(
      (prevCards) =>
        prevCards?.map((card) =>
          card.id === id ? { ...card, contracts: [data] } : card,
        ),
    );
  };

  const handleDeleteProductCard = (id: number) => {
    setOrderProductCards((prevCards) => {
      const newProductCards = prevCards?.filter((card) => card.id !== id);
      return newProductCards;
    });
  };

  const generateEmptySchedules = (
    existingSchedules: any,
    newQuantity: number,
  ) => {
    const schedules = existingSchedules ? [...existingSchedules] : [];
    const schArrLength = calculateArrLength(newQuantity);

    for (let i = schedules.length; i < schArrLength; i++) {
      schedules.push({
        date: '',
        startTime: '',
        endTime: '',
        slotsNumber: undefined,
      });
    }

    return schedules;
  };

  const handleQuantityChange = (id: number, quantity: number) => {
    setOrderProductCards(
      (prevCards) =>
        prevCards?.map((card) =>
          card.id === id
            ? {
                ...card,
                quantity,
                schedules: generateEmptySchedules(card?.schedules, quantity),
              }
            : card,
        ),
    );
  };

  const handleNavigateToNextPage = useCallback(() => {
    if (
      orderProductCards?.some(
        (product) => !product.contracts || product.contracts.length === 0,
      )
    ) {
      setAlertVisibility(true);
      setAlertMessage('Please provide the required contract information.');
    } else if (
      orderProductCards?.some(
        (product: IOrderCard) =>
          !product.schedules ||
          product.schedules.some((schedule) => !schedule.startTime),
      )
    ) {
      setAlertVisibility(true);
      setAlertMessage('Please provide the required schedule information.');
    } else if (
      orderProductCards?.some((product: IOrderCard) => !product.contact)
    ) {
      setAlertVisibility(true);
      setAlertMessage('Please provide the required contact information.');
    } else {
      navigate('/summary-order', {
        state: {
          contactData,
          orderProductCards,
          id,
        },
      });
    }
  }, [id, orderProductCards, contactData]);
  const productNames = orderProductCards?.map(
    (card: IOrderCard) => card.product,
  );

  const productNamesString = useMemo(() => {
    return productNames?.join(', ');
  }, [productNames]);

  const [stagesData] = useState([
    {
      label: `${crewQuantity} Products Selected`,
      active: true,
      bold: true,
      link: '/select-product',
      secondLine: productNamesString,
      state: {
        contactData,
        orderProductCards,
        id,
      },
    },
    { label: 'Contract & Schedule', active: true, bold: true, state: { id } },
    { label: 'Review Summary Order', active: false, state: { id } },
  ]);

  return (
    <div className="contract-schedule-container">
      <TemplatePage>
        <div className="contract-schedule">
          <div className="contract-schedule-header">
            <OrderTitle title="Contract & Schedule" icon={money} />
            <div className="contract-btns">
              <Link to={'/orders'}>
                <BasicOrderButton label="Cancel" />
              </Link>
              <div className="contract-btns-next">
                <BasicOrderButton label="Back" onClick={() => navigate(-1)} />
                <div
                  onClick={handleNavigateToNextPage}
                  style={{ cursor: 'pointer' }}>
                  <NextOrderButton label="Submit" />
                </div>
              </div>
            </div>
          </div>
          <OrderStagesPanel data={stagesData} />
          <div className="hr"></div>
          {isAlertVisible && (
            <div className="submit-validation-message">{alertMessage}</div>
          )}
          <div className="contract-schedule-content">
            <OrderProdList
              handleOpenScheduleModal={(id, index) =>
                toggleScheduleModal(id, index)
              }
              handleOpenContactModal={toggleContactModal}
              handleContractData={handleContractData}
              handleQuantityChange={handleQuantityChange}
              handleDeleteProductCard={handleDeleteProductCard}
              orderProductCards={orderProductCards}
            />
            <MarketSalary cardsData={orderProductCards} />
          </div>
          {isScheduleModalOpen && (
            <OrderModal
              onClose={() => toggleScheduleModal(0, 0)}
              handleScheduleData={handleScheduleData}
              modalId={modalId}
              productCards={orderProductCards}
              currSchIndex={currSchIndex}
            />
          )}
          {isContactModalOpen && (
            <OrderModal
              onClose={() => toggleContactModal(0)}
              handleContactData={handleContactData}
              modalId={modalId}
              orgName={orgName}
              branchName={branchName}
              currOrderOrg={currOrderOrg}
            />
          )}
        </div>
      </TemplatePage>
    </div>
  );
};

export default ContractSchedule;
